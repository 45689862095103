// @flow

import Head from 'next/head';
// $FlowFixMe
import React, { useContext } from 'react';

import HostnameContext from 'utils/hostnameUtils';

export default function MainHtmlHead({
	favicons,
	metaDescriptionHome,
	metaTitleHome,
	placeholder,
	router,
}: any): React$Element<any> {
	const { host } = useContext(HostnameContext);

	const keywords = 'Kits de communication - FFR';
	const href = `https://${host}${router.asPath}`;

	return (
		<Head>
			{/* website commun head tags */}
			<meta name="viewport" content="initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width" />
			<meta charSet="UTF-8" />
			<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
			<meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
			<meta name="theme-color" content="#ffffff" />
			<meta name="twitter:site" content="@FFRugby" />
			<meta name="twitter:creator" content="@FFRugby" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="fb:app_id" content="753881258133291" />
			<meta property="fb:pages" content="201838479917171" />
			<meta property="og:locale" content="fr_FR" />
			<meta property="og:type" content="website" />
			<meta name="msapplication-TileColor" content="#da532c" />
			<link rel="manifest" href="/manifest.json" />
			<link rel="shortcut icon" href={favicons['favicon-32']} />
			<link rel="apple-touch-icon" sizes="180x180" href={favicons['favicon-180']} />
			<link rel="icon" type="image/png" sizes="32x32" href={favicons['favicon-32']} />
			<link rel="icon" type="image/png" sizes="16x16" href={favicons['favicon-16']} />

			{/* default head tags */}
			<title>{metaTitleHome}</title>
			<meta name={'description'} content={metaDescriptionHome} key="description" />
			<meta name={'keywords'} content={keywords} key="keywords" />
			<meta property={'og:url'} content={href} key="og:url" />
			<meta property={'og:title'} content={metaTitleHome} key="og:title" />
			<meta property={'og:description'} content={metaDescriptionHome} key="og:description" />
			<meta property={'og:image'} content={placeholder} key="og:image" />
			<meta property={'og:image:url'} content={placeholder} key="og:image:url" />
			<link rel={'canonical'} href={href} key="canonical" />
		</Head>
	);
}
