// @flow
export function getOnboardingBaseUrl(): string {
	const hostdev = 'https://api.kitscom.ffr.nextmap.cloud/';

	/**
	 * En browser on n'a pas de process.env, on ne l'a qu'en server-side et ici on a besoin des deux
	 */
	const hostname = typeof window !== 'undefined' ? window.location.hostname : '';

	let hostapi = (!hostname.includes('localhost') && !hostname.includes('nextmap')) ? 'https://api-kitscom.ffr.fr/' : hostdev;

	return process.env.API_HOST ? `https://${process.env.API_HOST}` : hostapi;
}

export const TIMEOUT = 60000;
export const ON_BOARDING_BASE_URL: string = getOnboardingBaseUrl() + '/wp-json';
export const FORM_CONTACT_TOKEN = 'YXBpLWZmcjowUmZ4TkdCJXl3V0huVTNsWWdENzdjNTI=';
