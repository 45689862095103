// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import KitAdminApi from 'api/kitAdmin/KitAdminApi';
import { FETCH_KIT_ADMIN_START, kitAdminFetched, kitAdminFetchError } from 'actions/kitAdminActions';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (apiExecutor: ApiExecutorType): () => GeneratorType {
	const kitAdminApi = new KitAdminApi(apiExecutor);

	return function* kitsSaga(): GeneratorType {
		yield takeLatest(FETCH_KIT_ADMIN_START, fetchKitAdmin);
	};

	function* fetchKitAdmin(action): Saga<void> {
		try {
			const kitAdmin = yield call(kitAdminApi.fetchKitAdmin, action.payload.structureId, action.payload.magicLink);

			yield put(kitAdminFetched(kitAdmin));
		} catch (e) {
			yield put(kitAdminFetchError());
		}
	}
}
