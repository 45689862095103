/* eslint-disable @next/next/no-img-element */
// @flow
import React, { useEffect } from 'react';

export type DispatchProps = {
	displayModal: (modalObject: string) => void,
};

// ! @todo : le moment venu ajouter le statut nécessaire pour définir la fermeture de la modal ici et dans le container
export type StateProps = {};

type Props = DispatchProps &
	StateProps & {
		content: any,
		hideModal: () => void,
	};

// ! @todo : le moment venu ajouter le statut nécessaire pour définir la fermeture de la modal dans les props
function KitCreationInProgressModalContent({ content, displayModal, hideModal }: Props): React$Element<'div'> {
	// ! @todo : le moment venu ajouter le statut nécessaire pour définir la fermeture de la modal et décommenter
	// useEffect(
	// 	() => {
	// si le statut est STATUS_SUCCESS => on ferme cette modal et on ouvre celle du succès
	// if (le statut qui va bien === STATUS_SUCCESS) {
	// hideModal();
	// displayModal('DOWNLOAD_KIT_SUCCESS')
	// }
	// },
	// [
	// ici ce sera la statut déclenchant la fermeture de la modal
	// 	],
	// );

	return (
		<div className="modal__body modal__body--medium narrow-2">
			{/* // https://stackoverflow.com/questions/22531861/ring-shaped-process-spinner-with-fading-gradient-effect-around-the-ring */}
			<div className="spinner" />

			<p className="mt-3 ft-center">{content.title}</p>
		</div>
	);
}

export default KitCreationInProgressModalContent;
