// @flow

import CookieCutter from 'cookie-cutter';
import React, { PureComponent } from 'react';

import Button from 'components/fragments/button/Button';

export type DispatchProps = {
	askMagicLink: (email: string) => void,
	forgotMagicLink: () => void,
};

export type StateProps = { emailUser: string };

type Props = StateProps & DispatchProps;

export default class ReAskMagicLink extends PureComponent<Props> {
	handleSubmit: () => void = () => {
		const { askMagicLink, emailUser, forgotMagicLink } = this.props;

		//  Deal with cookies on logout
		// remove cookie
		CookieCutter.set('magic', '', { expires: new Date(0) });

		// add cookie for success modal
		const now = new Date();
		const expirationDate = now.setDate(now.getSeconds() + 60 * 5);
		CookieCutter.set('logoutSuccessModalCookie', emailUser, { expires: expirationDate });

		setTimeout(() => {
			forgotMagicLink();
			askMagicLink(emailUser);
		}, 1000);
	};

	render(): React$Element<React$FragmentType> {
		const {
			handleSubmit,
			props: { emailUser },
		} = this;

		if (!emailUser) {
			return (
				<>
					<div className="circle-icon circle-icon--alert"></div>
					<p className="mb-1 ft-center">Vous pouvez dès à présent faire une nouvelle demande par mail !</p>
				</>
			);
		}

		return (
			<>
				<div className="modal__body modal__body--medium mb-3 mb-md-5">
					<p className="mb-1 ft-center">Renvoyer le lien magique à cette adresse : {emailUser}</p>
				</div>

				<Button className="btn--magic-link" label="Envoyer" onClick={handleSubmit} isFull />
			</>
		);
	}
}
