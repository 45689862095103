// @flow
import React, { Component } from 'react';
import Link from 'next/link';

import { baseMenuItemWidth, letterWidth, logoWidth } from 'constants/menuConstants';
import { convertEncodedHTML } from 'utils/convertUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

type DefaultProps = {
	titleAll?: string,
	children?: React$Element<*>,
	isActive?: boolean,
	col?: boolean,
	target?: string,
	activateMenu?: Function,
	onCloseMenu?: Function,
};

type Props = {
	url: string,
	urlType: string,
	title: string,
	isMobile: boolean,
	id: number,
	menuX: number,
	isDisplayed: (id: number) => boolean,
} & DefaultProps;

type ComponentState = {
	isOpen: boolean,
	dropdownTargetX: number,
};

class MenuElement extends Component<Props, ComponentState> {
	_container: ?HTMLElement;

	_dropdownmenu: ?HTMLElement;

	_title: ?HTMLElement;

	static defaultProps: DefaultProps = {
		titleAll: undefined,
		children: undefined,
		isActive: false,
		col: false,
		activateMenu: () => {},
		onCloseMenu: () => {},
	};

	state: ComponentState = {
		isOpen: false,
		dropdownTargetX: 0,
	};

	updateDimensions() {
		const { menuX } = this.props;
		const { dropdownTargetX: dropdownTargetXState } = this.state;

		const dropdownmenu = this._dropdownmenu;
		const title = this._title;

		if (dropdownmenu && title) {
			const titleBounds = title.getBoundingClientRect();

			const titleCenterX = titleBounds.left + title.offsetWidth / 2;
			const dropdownTargetX = Math.round(titleCenterX - dropdownmenu.offsetWidth / 2 + logoWidth - menuX);
			if (dropdownTargetX !== dropdownTargetXState) {
				this.setState({
					dropdownTargetX,
				});
			}
		}
	}

	componentDidMount() {
		const { dropdownTargetX: dropdownTargetXState } = this.state;
		const dropdownmenu = this._dropdownmenu;
		const title = this._title;

		if (dropdownmenu && title) {
			const titleBounds: any = title.getBoundingClientRect();

			const titleCenterX = titleBounds.left + title.offsetWidth / 2;
			const dropdownTargetX = titleCenterX - dropdownmenu.offsetWidth / 2 + 175;
			if (dropdownTargetX !== dropdownTargetXState)
				this.setState({
					dropdownTargetX,
				});
		}
	}

	componentDidUpdate() {
		this.updateDimensions();
	}

	toggleIsOpen: (e: MouseEvent) => void = (e: MouseEvent) => {
		const { children, isMobile, onCloseMenu, activateMenu, id } = this.props;

		if (isMobile && children) {
			e.preventDefault();
			this.setState((prevState) => ({
				isOpen: !prevState.isOpen,
			}));
		} else if (onCloseMenu) {
			onCloseMenu();
		}
		if (activateMenu) {
			activateMenu(id);
		}
	};

	getSize: () => number = () => {
		const { title } = this.props;

		return baseMenuItemWidth + letterWidth * title.length;
	};

	renderDropdown: (
		children: any,
		classNamesChildrenContainer: any,
		classNamesChildrenList: any,
		titleAll?: string,
		url?: string,
	) => null | React$Element<'div'> = (
		children: any,
		classNamesChildrenContainer: any,
		classNamesChildrenList: any,
		titleAll?: string,
		url?: string,
	) => {
		if (children) {
			const { onCloseMenu } = this.props;
			const { dropdownTargetX } = this.state;

			return (
				<div className={classNamesChildrenContainer.build()} ref={(c) => (this._dropdownmenu = c)}>
					<ul style={{ left: dropdownTargetX }} className={classNamesChildrenList.build()}>
						<li className="menu__link-all">
							<Link href={url || '#'}>
								<a onClick={onCloseMenu && onCloseMenu}>{convertEncodedHTML(titleAll)}</a>
							</Link>
						</li>
						{children}
					</ul>
				</div>
			);
		}
		return null;
	};

	render(): null | React$Element<'li'> {
		const { url, urlType, title, titleAll, children, isMobile, col, isDisplayed, id, isActive, target } = this.props;
		const { isOpen } = this.state;
		const classNamesTitle = dynamicClassName('');
		children && classNamesTitle.add('accordion-trigger');
		isMobile && isOpen && classNamesTitle.add('is-selected');
		const classNameLi = dynamicClassName('');
		isActive && classNameLi.add('is-active') && classNamesTitle.add('is-active');

		const classNamesChildrenContainer = dynamicClassName('');
		col && classNamesChildrenContainer.add('menu__sub__selection');

		const classNamesChildrenList = dynamicClassName('menu__sub accordion-panel');
		col && classNamesChildrenList.add('menu__col');
		!isOpen && isMobile && classNamesChildrenList.add('is-hidden');

		if (isDisplayed(id)) {
			return (
				<li className={classNameLi.build()} ref={(c) => (this._container = c)}>
					{urlType !== 'external' && (
						<Link href={url}>
							<a
								ref={(c) => (this._title = c)}
								className={classNamesTitle.build()}
								onClick={this.toggleIsOpen}
								target={target}>
								{convertEncodedHTML(title)}
							</a>
						</Link>
					)}
					{urlType === 'external' && (
						<a href={url} target="_blank" rel="noopener noreferrer" className={classNamesTitle.build()}>
							{convertEncodedHTML(title)}
						</a>
					)}
					{children &&
						this.renderDropdown(children, classNamesChildrenContainer, classNamesChildrenList, titleAll, url)}
				</li>
			);
		}

		return null;
	}
}

export default MenuElement;
