// @flow
import Link from 'next/link';
import React, { PureComponent, useEffect } from 'react';

export type StateProps = {
	cookies: number,
	showCookieInfo: boolean,
	title: string,
};

export type DispatchProps = {
	setShowCookieInfo: (value: boolean, accept?: boolean) => void,
};

type Props = StateProps &
	DispatchProps & {
		acceptCookies: () => void,
	};

const cookieEndContent =
	", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celles" +
	' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
	" de vos centres d'intérêts.";

class Cookie extends PureComponent<Props> {
	acceptCookie: () => void = () => {
		this.props.setShowCookieInfo(false, true);

		if (this.props.acceptCookies) {
			this.props.acceptCookies();
		}
	};

	refuseCookie: () => void = () => {
		this.props.setShowCookieInfo(false, false);
	};

	render(): null | React$Element<'div'> {
		const { title, cookies, showCookieInfo } = this.props;

		if (!showCookieInfo) {
			return null;
		}

		return (
			<div className="cookie-container">
				<div className="box cookie">
					<p>
						En poursuivant votre navigation sur {title} {cookieEndContent}
						<br />
						<Link href="https://www.ffr.fr/politique-de-cookies">
							<a target="_blank" rel="noopener noreferrer">
								En savoir plus sur les cookies
							</a>
						</Link>
						<br />
						<Link href="https://www.ffr.fr/cgu">
							<a target="_blank" rel="noopener noreferrer">
								Afficher les CGU
							</a>
						</Link>
					</p>

					<div className="btn-container">
						<div onClick={this.acceptCookie} className="btn btn--primary btn--full">
							Accepter les cookies
						</div>

						<div onClick={this.refuseCookie} className="btn btn--primary btn--full">
							Refuser
						</div>
					</div>
					<button className="cookie-close btn" onClick={this.refuseCookie}>
						Continuer sans accepter &#10142;
					</button>
				</div>
			</div>
		);
	}
}

export default Cookie;
