// @flow
import React, { Component } from 'react';

import { dynamicClassName } from 'utils/dynamicClassName';

type State = {
	isOpen: boolean,
};

type Props = {
	handleLogout(): void,
	title: string,
};

class MenuDropDown extends Component<Props, State> {
	_DropdownMenu: ?HTMLElement;

	state: State = {
		isOpen: false,
	};

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside: (event: MouseEvent) => void = (event: MouseEvent) => {
		if (this._DropdownMenu && !this._DropdownMenu.contains((event.target: any))) {
			this.toggleIsOpen(event);
		}
	};

	toggleIsOpen: (e: MouseEvent) => void = (e: MouseEvent) => {
		e.preventDefault();
		if (!this.state.isOpen) {
			document.addEventListener('mousedown', this.handleClickOutside, { once: true });
		}
		this.setState({ isOpen: !this.state.isOpen });
	};

	render(): React$Element<'ul'> {
		const { handleLogout, title } = this.props;
		const { isOpen } = this.state;

		const classNamesTrigger = dynamicClassName('accordion-trigger accordion-trigger--kits-comm');
		isOpen && classNamesTrigger.add('is-open');

		const classNamesDropDownWrapper = dynamicClassName('accordion-panel accordion-panel--kits-comm');
		!isOpen && classNamesDropDownWrapper.add('is-hidden');

		return (
			<ul>
				<li ref={(r) => (this._DropdownMenu = r)}>
					<a // eslint-disable-line
						role="button"
						tabIndex={0}
						className={classNamesTrigger.build()}
						style={{ display: 'block' }}
						onClick={this.toggleIsOpen}
						title={title}>
						{title}
					</a>

					<ul className={classNamesDropDownWrapper.build()}>
						<li>
							<a // eslint-disable-line
								role="button"
								tabIndex={0}
								onClick={handleLogout}>
								Continuer plus tard
							</a>
						</li>
					</ul>
				</li>
			</ul>
		);
	}
}

export default MenuDropDown;
