// @flow
import { withRouter } from 'next/router';
import * as React from 'react';
import { useEffect, useState } from 'react';

import Loading from 'components/fragments/Loading';
import withScreenType from 'components/fragments/withScreenType';
import type { HocStateProps } from 'components/fragments/withScreenType';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ClubType } from 'types/Club';
import type { SingleMyKit } from 'types/Kits';
import type { RouterProps } from 'types/Router';
import type { Status } from 'types/Status';
import { isBrowser } from 'utils/nextUtils';
import TagManager from 'utils/tagManagerUtils';
import { numberlyPageView } from 'utils/numberlyUtils';

export type StateProps = {
	club: ClubType,
	clubStatus: Status,
	isAdmin: boolean | null,
	magicLink: string,
	singleMyKit: SingleMyKit,
	structureID: number,
};

type Props = RouterProps & StateProps & HocStateProps & { acceptCookie: boolean, children: any };

const ProtectedRoute = ({
	acceptCookie,
	club: {
		configuration: { step },
	},
	clubStatus,
	isAdmin,
	isSmall,
	magicLink,
	singleMyKit,
	structureID,
	router,
	children,
}: Props): any => {
	const [isFirstConnection, setIsFirstConnection] = useState(true);

	//check if you are on the client (browser) or server
	const isAuthenticated = structureID && magicLink;
	let unprotectedRoutes = ['/'];
	let pathIsProtected = unprotectedRoutes.indexOf(router.pathname) === -1;

	// deal with redirection
	useEffect(() => {
		if (isBrowser()) {
			// si on et pas un utilisateur authentifié, on est redirigé vers la home
			if (pathIsProtected && clubStatus !== STATUS_LOADING && !isAuthenticated) {
				router.push('/');
			}

			// une fois identifiée
			if (isAuthenticated) {
				// redirection si mobile :
				if (isSmall && router.pathname !== '/' && !router.pathname.includes('initialisation')) {
					router.push('/initialisation');
				}

				// redirection si lien magique
				if (!isSmall && router.asPath.includes('magic') && clubStatus !== STATUS_LOADING) {
					if (step === null) {
						router.push('/initialisation');
					} else {
						router.push('/kits/tableau-de-bord');
					}
				}

				// redirection si écran élargi de nouveau
				if (!isSmall && router.asPath.includes('initialisation') && step !== null) {
					router.push('/kits/tableau-de-bord');
				}

				// être redirigé vers le tableau de bord si on n'a pas de variation de kit dans le state
				if (router.asPath.includes('editeur') && singleMyKit.kitorig === '') {
					router.push('/kits/tableau-de-bord');
				}

				// redirection de l'administration vers la tableau de bord si on n'est pas admin
				if (clubStatus !== STATUS_LOADING && !isAdmin && router.asPath.includes('administration')) {
					router.push('/kits/tableau-de-bord');
				}
			}

			// tracking
			if (router.asPath === '/kits/tableau-de-bord' && isFirstConnection) {
				handleTracking();
			}
		}
	}, [clubStatus, pathIsProtected, singleMyKit, step, isAuthenticated, router, isSmall, isFirstConnection, isAdmin]);

	const handleTracking = () => {
		setIsFirstConnection(false);
		TagManager.handleTagEvent({
			event: 'platform_accessed',
		});
	};

	// Numberly
	useEffect(() => {
		handleNumberlyPageView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.asPath]);

	useEffect(() => {
		if (acceptCookie) {
			setTimeout(handleNumberlyPageView, 300);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptCookie]);

	const handleNumberlyPageView = () => {
		const isConnected = router.asPath.includes('/kits') || router.asPath.includes('/editeur');
		numberlyPageView(isConnected, router.asPath);
	};

	// loader tant qu'on n'est pas identifié et qu'on est sur une page protégée
	if (
		(pathIsProtected && (clubStatus === STATUS_LOADING || !isAuthenticated)) ||
		(router.asPath.includes('editeur') && singleMyKit.kitorig === '') ||
		(!isSmall && router.asPath.includes('initialisation') && step !== null) ||
		(isAdmin === null && router.asPath.includes('administration'))
	) {
		return <Loading isDarkBlue />;
	}

	return children;
};

export default (withRouter(withScreenType(ProtectedRoute)): any);
