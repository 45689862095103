// @flow
export function convertEncodedHTML(name?: string): void | string {
	if (typeof document !== 'undefined') {
		let parser = document.createElement('p');
		parser.innerHTML = name || '';
		return parser.innerHTML
			.replace(/&lt;/g, '>')
			.replace(/&gt;/g, '<')
			.replace(/&nbsp;/g, '')
			.replace(/&amp;/g, '&');
	}
}

/**
 * @function parseStyles
 * Parses a string of inline styles into a javascript object with casing for react
 *
 * @param {string} styles
 * @returns {Object}
 */
export const convertCSS = (styles: string): { ... } =>
	styles
		.split(';')
		.filter((style) => style.split(':')[0] && style.split(':')[1])
		.map((style) => [
			style
				.split(':')[0]
				.trim()
				.replace(/-./g, (c) => c.substr(1).toUpperCase()),
			style.split(':').slice(1).join(':').trim(),
		])
		.reduce(
			(styleObj, style) => ({
				...styleObj,
				[style[0]]: style[1],
			}),
			{},
		);
