// @flow

import {
	FETCH_CLUB_START,
	FETCH_CLUB_SUCCESS,
	FETCH_CLUB_FAILURE,
	POST_CONFIG_START,
	POST_CONFIG_SUCCESS,
	POST_CONFIG_FAILURE,
	POST_ACTION_START,
	POST_ACTION_SUCCESS,
	POST_ACTION_FAILURE,
} from 'actions/clubActions';
import { FORGOT_MAGIC_LINK } from 'actions/magicLinkActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import { nullClub } from 'types/Club';
import type { ClubType } from 'types/Club';
import type { Status } from 'types/Status';

export type State = {
	club: ClubType,
	status: Status,
};

const initialState: State = {
	club: nullClub,
	status: STATUS_LOADING,
};

export default function reducer(state: State = initialState, action: ActionType): State {
	switch (action.type) {
		case FETCH_CLUB_START:
			return {
				...state,
				status: STATUS_LOADING,
			};
		case FETCH_CLUB_SUCCESS:
			return {
				...state,
				club: {
					...state.club,
					...action.payload.club,
					configuration: {
						...state.club.configuration,
						...action.payload.club.configuration,
					},
				},
				status: STATUS_SUCCESS,
			};
		case FETCH_CLUB_FAILURE:
			return {
				...state,
				status: STATUS_FAILURE,
			};
		case POST_CONFIG_START:
		case POST_ACTION_START:
			return {
				...state,
				status: STATUS_LOADING,
			};
		case POST_CONFIG_SUCCESS:
		case POST_ACTION_SUCCESS:
			return {
				...state,
				club: {
					...state.club,
					...action.payload.club,
					configuration: {
						...state.club.configuration,
						...action.payload.club.configuration,
					},
				},
				status: STATUS_SUCCESS,
			};
		case POST_CONFIG_FAILURE:
		case POST_ACTION_FAILURE:
			return {
				...state,
				status: STATUS_FAILURE,
			};
		case FORGOT_MAGIC_LINK:
			return {
				...state,
				club: initialState.club,
			};
		default:
			return state;
	}
}
