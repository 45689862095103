// @flow
import { connect } from 'react-redux';

import ProtectedRoute from 'components/app/ProtectedRoute';
import type { StateProps } from 'components/app/ProtectedRoute';
import type { StateType } from 'types/Actions';

export default (connect((state: StateType, ...ownProps: any): $Exact<StateProps> => ({
	club: state.clubState.club,
	clubStatus: state.clubState.status,
	isAdmin: state.clubState.club.is_admin,
	magicLink: state.magicLinkState.magicLink,
	singleMyKit: state.kitsVariationsState.singleMyKit,
	structureID: state.magicLinkState.structureID,
}))(ProtectedRoute): any);
