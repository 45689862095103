// @flow
import { connect } from 'react-redux';

import CreationRequestForm from 'components/kits/initialStep/CreationRequestForm';
import type { DispatchProps } from 'components/kits/initialStep/CreationRequestForm';
import { askMagicLink } from 'actions/magicLinkActions';
import type { DispatchType } from 'types/Actions';

export default (connect(null, (dispatch: DispatchType): $Exact<DispatchProps> => ({
	askMagicLink: (email: string) => dispatch(askMagicLink(email)),
}))(CreationRequestForm): any);
