// @flow
export function validateEmail(email: string): boolean {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePhone(phone: string): boolean {
  const regex = /^[+]?[0-9]([(][0-9][)]|[0-9])+(\d)$/g;

  return regex.test(phone);
}
