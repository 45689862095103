// @flow

export function parseCookies(req?: any): any {
	if (!req || !req.headers) {
		return {};
	}

	return (req.headers.cookie || '').split(/; */).reduce((acc, curr) => {
		const [key, ...v] = curr.split('=');

		return { ...acc, [key]: v.join('=') };
	}, {});
}
