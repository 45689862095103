// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import KitsApi from 'api/kits/KitsApi';
import { FETCH_KITS_START, kitsFetched, kitsFetchError } from 'actions/kitsActions';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (apiExecutor: ApiExecutorType): () => GeneratorType {
	const kitsApi = new KitsApi(apiExecutor);

	return function* kitsSaga(): GeneratorType {
		yield takeLatest(FETCH_KITS_START, fetchKits);
	};

	function* fetchKits(action): Saga<void> {
		try {
			const kitList = yield call(kitsApi.fetchKits, action.payload.structureOvaleCode);

			const kitListSortedByDate = !!kitList.length
				? kitList.sort((a, b) =>
						new Date(a.date) < new Date(b.date) ? -1 : new Date(a.date) > new Date(b.date) ? 1 : 0,
				  )
				: [];

			yield put(kitsFetched(kitListSortedByDate));
		} catch (e) {
			yield put(kitsFetchError());
		}
	}
}
