/// @flow
import React, { PureComponent } from 'react';
import Link from 'next/link';

import { dynamicClassName } from 'utils/dynamicClassName';

type DefaultProps = {
	className?: string,
	url?: string,
	title?: string,
	isDisabled?: boolean,
	isFull?: boolean,
	isSecondary?: boolean,
	onClick?: Function,
	iconBefore?: string,
	iconAfter?: string,
};

type Props = {
	label: string,
} & DefaultProps;

class Button extends PureComponent<Props> {
	static defaultProps: DefaultProps = {
		className: '',
		title: '',
		url: '',
		isFull: false,
		isDisabled: false,
		isSecondary: false,
		onClick: () => {},
	};

	render(): React$Element<any> | React$Element<'button'> {
		const { iconAfter, iconBefore, isDisabled, isFull, isSecondary, onClick, url, label, title, className } =
			this.props;

		const classNames = dynamicClassName('btn');
		!isSecondary && classNames.add('btn--primary');
		isDisabled && classNames.add('is-disabled');
		isFull && classNames.add('btn--full');
		className && classNames.add(className);

		if (url) {
			return (
				<Link href={url}>
					<a title={title} onClick={onClick} className={classNames.build()}>
						{iconBefore && <i className={`icon icon--before ${iconBefore}`} />}
						{label}
						{iconAfter && <i className={`icon icon--after ${iconAfter}`} />}
					</a>
				</Link>
			);
		}
		return (
			<button type="button" title={title} onClick={onClick} className={classNames.build()}>
				{iconBefore && <i className={`icon icon--before ${iconBefore}`} />}
				{label}
				{iconAfter && <i className={`icon icon--after ${iconAfter}`} />}
			</button>
		);
	}
}

export default Button;
