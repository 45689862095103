// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
	FETCH_CONTACT_FORM_START,
	POST_CONTACT_FORM_START,
	fetchContactFormSuccess,
	fetchContactFormFailure,
} from 'actions/contactFormActions';
import { displayModal } from 'actions/modalActions';
import ContactFormApi from 'api/contactForm/ContactFormApi';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function saga(apiExecutor: ApiExecutorType): () => GeneratorType {
	const contactFormApi = new ContactFormApi(apiExecutor);

	return function* settingsSaga(): GeneratorType {
		yield takeLatest(FETCH_CONTACT_FORM_START, fetchContactForm);
		yield takeLatest(POST_CONTACT_FORM_START, postContactForm);
	};

	function* fetchContactForm(action): Saga<void> {
		try {
			const settings = yield call(contactFormApi.fetchContactForm);
			yield put(fetchContactFormSuccess(settings));
		} catch (e) {
			yield put(fetchContactFormFailure());
		}
	}

	function* postContactForm(action): Saga<void> {
		const success = {
			title: 'Message envoyé',
			text: 'Votre demande a été prise en compte !',
		};
		const failure = {
			title: 'Nous rencontrons un problème',
			text: "Votre demande n'a pas pu être transmise, veuillez nous recontacter ultérieurement",
		};
		try {
			const { formData, id } = action.payload;
			const form = new FormData();
			const inputs = Object.keys(formData);
			inputs.forEach((input) => {
				// $FlowFixMe
				if (Object.prototype.hasOwnProperty.call(formData, input)) {
					form.append(input, formData[input]);
				}
			});

			const settings = yield call(contactFormApi.postContactForm, form, id);
			yield put(fetchContactFormSuccess(settings));
			yield put(displayModal('SUCCESS', success));
		} catch (e) {
			yield put(displayModal('RE_ASK_MAGIC_LINK', failure));
			yield put(fetchContactFormFailure());
		}
	}
}
