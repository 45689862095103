// @flow
import {
	DELETE_KIT_VARIATION,
	DELETE_KIT_VARIATION_SUCCESS,
	DELETE_KIT_VARIATION_FAILURE,
	RESET_DELETE_KIT_VARIATIONS_STATUS,
	FETCH_KITS_VARIATIONS_LIST_START,
	FETCH_KITS_VARIATIONS_LIST_SUCCESS,
	FETCH_KITS_VARIATIONS_LIST_FAILURE,
	FETCH_KITS_BY_STRUCTURE_START,
	FETCH_KITS_BY_STRUCTURE_SUCCESS,
	FETCH_KITS_BY_STRUCTURE_FAILURE,
	RESET_KITS_BY_STRUCTURE,
	FETCH_SINGLE_KIT_VARIATION_START,
	FETCH_SINGLE_KIT_VARIATION_SUCCESS,
	FETCH_SINGLE_KIT_VARIATION_FAILURE,
	RESET_SINGLE_KIT_VARIATION,
	POST_KIT_VARIATION,
	POST_KIT_VARIATION_SUCCESS,
	POST_KIT_VARIATION_FAILURE,
	RESET_POST_KIT_VARIATIONS_STATUS,
} from 'actions/kitsVariationsActions';
import { FORGOT_MAGIC_LINK } from 'actions/magicLinkActions';
import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import { nullSingleMyKit } from 'types/Kits';
import type { SingleMyKit } from 'types/Kits';
import type { Status } from 'types/Status';
import { getPublicAssets } from 'utils/assetsUtils';
import { nullSingleKit } from '../types/Kits';

export type State = {
	kitsByStructure: SingleMyKit[],
	kitsByStructureStatus: Status,
	myKitList: SingleMyKit[],
	myKitListStatus: Status,
	singleMyKit: SingleMyKit,
	singleMyKitStatus: Status,
	postKitVariationStatus: Status,
	deleteKitVariationStatus: Status,
};

const initialState: State = {
	kitsByStructure: [],
	kitsByStructureStatus: STATUS_DEFAULT,
	myKitList: [],
	myKitListStatus: STATUS_DEFAULT,
	singleMyKit: nullSingleMyKit,
	singleMyKitStatus: STATUS_DEFAULT,
	postKitVariationStatus: STATUS_DEFAULT,
	deleteKitVariationStatus: STATUS_DEFAULT,
};

export default function reducer(state: State = initialState, action: ActionType): State {
	switch (action.type) {
		// DELETE ACTIONS
		case DELETE_KIT_VARIATION:
			return {
				...state,
				deleteKitVariationStatus: STATUS_LOADING,
			};
		case DELETE_KIT_VARIATION_SUCCESS:
			return {
				...state,
				deleteKitVariationStatus: STATUS_SUCCESS,
			};
		case DELETE_KIT_VARIATION_FAILURE:
			return {
				...state,
				deleteKitVariationStatus: STATUS_FAILURE,
			};
		case RESET_DELETE_KIT_VARIATIONS_STATUS:
			return {
				...state,
				deleteKitVariationStatus: STATUS_DEFAULT,
			};

		// GET KIT LIST ACTIONS
		case FETCH_KITS_VARIATIONS_LIST_START:
			return {
				...state,
				myKitListStatus: STATUS_LOADING,
			};
		case FETCH_KITS_VARIATIONS_LIST_SUCCESS:
			return {
				...state,
				myKitList: action.payload.myKitList,
				myKitListStatus: STATUS_SUCCESS,
			};
		case FETCH_KITS_VARIATIONS_LIST_FAILURE:
			return {
				...state,
				myKitListStatus: STATUS_FAILURE,
			};

		// GET KIT BY STRUCTURE ACTIONS
		case FETCH_KITS_BY_STRUCTURE_START:
			return {
				...state,
				kitsByStructureStatus: STATUS_LOADING,
			};
		case FETCH_KITS_BY_STRUCTURE_SUCCESS:
			return {
				...state,
				kitsByStructure: action.payload.kitsByStructure,
				kitsByStructureStatus: STATUS_SUCCESS,
			};
		case FETCH_KITS_BY_STRUCTURE_FAILURE:
			return {
				...state,
				kitsByStructureStatus: STATUS_FAILURE,
			};
		case RESET_KITS_BY_STRUCTURE:
			return {
				...state,
				kitsByStructure: [],
				kitsByStructureStatus: STATUS_DEFAULT,
			};

		// GET A SINGLE KIT ACTIONS
		case FETCH_SINGLE_KIT_VARIATION_START:
			return {
				...state,
				singleMyKitStatus: STATUS_LOADING,
				singleMyKit: {
					...state.singleMyKit,
					kitorig: action.payload.kitorig.toString(),
				},
			};
		case FETCH_SINGLE_KIT_VARIATION_SUCCESS:
			return {
				...state,
				singleMyKit: {
					...action.payload.singleMyKit,
					zipLink: 'https://kitscom.apsulis.ninja/wp-content/uploads/2021/10/ete-du-rugby.zip', // ! @todo : à supprimer quand ziplink ok
				},
				singleMyKitStatus: STATUS_SUCCESS,
			};
		case FETCH_SINGLE_KIT_VARIATION_FAILURE:
			return {
				...state,
				singleMyKitStatus: STATUS_FAILURE,
			};
		case RESET_SINGLE_KIT_VARIATION: {
			return {
				...state,
				singleMyKitStatus: STATUS_DEFAULT,
				singleMyKit: {
					...nullSingleMyKit,
				},
			};
		}

		// POST ACTIONS
		case POST_KIT_VARIATION:
			return {
				...state,
				postKitVariationStatus: STATUS_LOADING,
			};
		case POST_KIT_VARIATION_SUCCESS:
			return {
				...state,
				postKitVariationStatus: STATUS_SUCCESS,
				singleMyKit: {
					...action.payload.kitVariation,
				},
			};
		case POST_KIT_VARIATION_FAILURE:
			return {
				...state,
				postKitVariationStatus: STATUS_FAILURE,
				singleMyKit: {
					...nullSingleMyKit,
				},
			};
		case RESET_POST_KIT_VARIATIONS_STATUS:
			return {
				...state,
				postKitVariationStatus: STATUS_DEFAULT,
			};

		// MAGICLINK ACTIONS
		case FORGOT_MAGIC_LINK:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
