// @flow
import { connect } from 'react-redux';

import ReAskMagicLink from 'components/kits/reAskMagicLink/ReAskMagicLink';
import type { DispatchProps, StateProps } from 'components/kits/reAskMagicLink/ReAskMagicLink';

import { askMagicLink, forgotMagicLink } from 'actions/magicLinkActions';

import type { StateType, DispatchType } from 'types/Actions';

export default (connect(null, (dispatch: DispatchType): $Exact<DispatchProps> => ({
	askMagicLink: (email: string) => dispatch(askMagicLink(email)),
	forgotMagicLink: () => dispatch(forgotMagicLink()),
}))(ReAskMagicLink): any);
