// @flow

import defaultImgTeam from 'public/assets/img/logo/placeholder-club.png';
import { setWindowApp, getWindowApp } from 'utils/windowUtils';

export function getPublicAssets(link: string): string {
	return `${process.env.PUBLIC_URL || ''}${link}`;
}

export function getPlaceholder(): string {
	let host = window.location.hostname.split('.')[0] || undefined;
	if (['dev', 'pp', 'localhost'].indexOf(host) > -1) {
		host = 'ffr';
	}
	const filename = (ligue) => `placeholder-${ligue}.jpg`;
	const path = (filename) => `/assets/img/placeholder/${filename}`;
	const defaultSource = getPublicAssets(path(filename('ffr')));
	const targetSource = getPublicAssets(path(filename(host || '')));
	setWindowApp('placeholder', defaultSource);

	const image = new Image();
	image.onload = () => setWindowApp('placeholder', targetSource);
	image.src = targetSource;

	return getWindowApp('placeholder') || defaultSource;
}

export const shouldUsePlaceholder = (competition: Object, isLeftScore: boolean): any | string => {
	const { embleme_france: emblemeFrance, embleme_adversaire: emblemeAdversaire, reception } = competition;
	const francePlaysAtHome = reception && reception !== 0;

	if (!isLeftScore) {
		if (francePlaysAtHome) {
			return emblemeAdversaire || defaultImgTeam;
		} else {
			return emblemeFrance || defaultImgTeam;
		}
	} else if (isLeftScore) {
		if (francePlaysAtHome) {
			return emblemeFrance || defaultImgTeam;
		} else {
			return emblemeAdversaire || defaultImgTeam;
		}
	}
	return defaultImgTeam;
};
