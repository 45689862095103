// @flow
import type { SingleKit } from 'types/Kits';

export const FETCH_KITS_START = 'FETCH_KITS_START';
export const FETCH_KITS_SUCCESS = 'FETCH_KITS_SUCCESS';
export const FETCH_KITS_FAILURE = 'FETCH_KITS_FAILURE';

export type FETCH_KITS_START_ACTION = {
	type: 'FETCH_KITS_START',
	payload: {
		structureOvaleCode: string,
	},
};

export type FETCH_KITS_SUCCESS_ACTION = {
	type: 'FETCH_KITS_SUCCESS',
	payload: {
		kitList: SingleKit[],
	},
};

export type FETCH_KITS_FAILURE_ACTION = {
	type: 'FETCH_KITS_FAILURE',
};

export type Action = FETCH_KITS_START_ACTION | FETCH_KITS_FAILURE_ACTION | FETCH_KITS_SUCCESS_ACTION;

export function fetchKits(structureOvaleCode: string): FETCH_KITS_START_ACTION {
	return {
		type: FETCH_KITS_START,
		payload: {
			structureOvaleCode,
		},
	};
}

export function kitsFetched(kitList: SingleKit[]): FETCH_KITS_SUCCESS_ACTION {
	return {
		type: FETCH_KITS_SUCCESS,
		payload: { kitList },
	};
}

export function kitsFetchError(): FETCH_KITS_FAILURE_ACTION {
	return {
		type: FETCH_KITS_FAILURE,
	};
}
