// @flow
import { FETCH_KIT_ADMIN_START, FETCH_KIT_ADMIN_SUCCESS, FETCH_KIT_ADMIN_FAILURE } from 'actions/kitAdminActions';
import { FORGOT_MAGIC_LINK } from 'actions/magicLinkActions';
import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import { nullKitAdmin } from 'types/KitAdmin';
import type { KitAdminType } from 'types/KitAdmin';
import type { Status } from 'types/Status';

export type State = {
	kitAdmin: KitAdminType,
	kitAdminStatus: Status,
};

const initialState: State = {
	kitAdmin: nullKitAdmin,
	kitAdminStatus: STATUS_DEFAULT,
};

export default function reducer(state: State = initialState, action: ActionType): State {
	switch (action.type) {
		case FETCH_KIT_ADMIN_START:
			return {
				...state,
				kitAdminStatus: STATUS_LOADING,
			};
		case FETCH_KIT_ADMIN_SUCCESS:
			return {
				...state,
				kitAdminStatus: STATUS_SUCCESS,
				kitAdmin: action.payload.kitAdmin,
			};
		case FETCH_KIT_ADMIN_FAILURE:
			return {
				...state,
				kitAdminStatus: STATUS_FAILURE,
				kitAdmin: nullKitAdmin,
			};
		case FORGOT_MAGIC_LINK:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
