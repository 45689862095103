// @flow
import Link from 'next/link';
import { withRouter } from 'next/router';
import React, { PureComponent } from 'react';

import Label from 'components/fragments/text/Label';
import { nullMenuElement } from 'types/MenuElement';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import type { Status } from 'types/Status';
import { dynamicClassName } from 'utils/dynamicClassName';

import TopBarDropdown from './TopBarDropdown';

export type StateProps = {
	tickerElements: {
		link: string,
		title: string,
		label: string,
	},
	topBarElements: MenuElementType[],
};

type Props = {
	name: string,
	picture: string | React$Element<*>,
} & StateProps &
	RouterProps;

type State = {
	isAccordionOpen: boolean,
	isOpen: boolean,
};

class TopBar extends PureComponent<Props, State> {
	_DropdownCommitteesMenu: ?HTMLElement;

	static defaultProps = {
		topBarElements: [],
	};

	state: State = {
		isAccordionOpen: false,
		isOpen: false,
	};

	handleAccordionOpen = () => {
		this.setState((prevState) => ({ isAccordionOpen: !prevState.isAccordionOpen }));
	};

	toggleIsOpen = (e: MouseEvent) => {
		e.preventDefault();
		if (!this.state.isOpen) {
			document.addEventListener('mousedown', this.handleClickOutside, { once: true });
		}
		this.setState({ isOpen: !this.state.isOpen });
	};

	handleClickOutside = (event: MouseEvent) => {
		if (this._DropdownCommitteesMenu && !this._DropdownCommitteesMenu.contains((event.target: any))) {
			this.toggleIsOpen(event);
		}
	};

	closeDropDown = () => {
		this.setState({
			isOpen: false,
		});
	};

	renderTopBarElements = () => {
		const { topBarElements } = this.props;
		const menus = topBarElements.filter((item) => item.parent === 0);

		return menus.map(({ items, title, url }, index) => {
			if (items && items.length > 0) {
				return <TopBarDropdown key={index} subMenus={items} title={title} />;
			}

			return (
				<li key={index}>
					<Link href={url}>
						<a
							title={`Se rendre sur le site officiel "${title}" de la Fédération Française de Rugby (nouvel onglet)`}
							target="_blank"
							rel="noopener noreferrer">
							{title}
						</a>
					</Link>
				</li>
			);
		});
	};

	render() {
		const { tickerElements } = this.props;

		return (
			<nav className="topbar">
				<ul>
					{this.renderTopBarElements()}
					{tickerElements && tickerElements.link && (
						<li className="topbar__news">
							<a href={`https://www.ffr.fr` + tickerElements.link} title={tickerElements.title}>
								<Label isSmall isBlueAlt label={tickerElements.label ? tickerElements.label : 'La fédération'} />
								<span>{tickerElements.title}</span>
							</a>
						</li>
					)}
				</ul>
			</nav>
		);
	}
}

export default (withRouter(TopBar): any);
