/* eslint-disable @next/next/no-img-element */
// @flow
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import Button from 'components/fragments/button/Button';
import Loading from 'components/fragments/Loading';
import SingleFormatCard from 'components/kits/formatCard/SingleFormatCard';
import { FORMAT_LIST } from 'constants/kitConstants';
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import type { Status } from 'types/Status';
import TagManager from 'utils/tagManagerUtils';

export type DispatchProps = {
	deleteKitVariation(structureid: number, kitorig: number): void,
	resetDeleteKitVariationStatus: () => void,
};

export type StateProps = {
	deleteKitVariationStatus: Status,
	structureid: number,
};

type Props = DispatchProps &
	StateProps & {
		content: any,
		hideModal: () => void,
	};

function DeleteKit({
	content,
	deleteKitVariation,
	deleteKitVariationStatus,
	hideModal,
	resetDeleteKitVariationStatus,
	structureid,
}: Props): React$Element<React$FragmentType> {
	const router = useRouter();

	useEffect(() => {
		if (deleteKitVariationStatus === STATUS_SUCCESS) {
			resetDeleteKitVariationStatus();
			hideModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteKitVariationStatus]);

	const handleDeleteKit = () => {
		deleteKitVariation(structureid, parseInt(content.kitorig, 10));

		handleTracking('kit_deleted');
	};

	const handleTracking = (eventName: string) => {
		const kitPurpose = content.formats_selected
			.reduce((acc, selectedFormat) => {
				const currentFormat = FORMAT_LIST.find((format) => format.id === selectedFormat.name);
				const currentFormatType = !!currentFormat && !!currentFormat.type ? currentFormat.type : '';

				if (!!currentFormatType && acc.every((item) => item !== currentFormatType)) {
					return acc.concat(currentFormatType);
				}

				return acc;
			}, [])
			.join('|');

		const kitFormats = FORMAT_LIST.map((format) => {
			if (content.formats_selected.some((selectedFormat) => selectedFormat.name === format.id)) {
				return format.tagName;
			}
		})
			.filter((format) => !!format)
			.join('|');

		TagManager.handleTagEvent({
			event: eventName,
			kit_type: content.type,
			kit_purpose: kitPurpose ?? '',
			kit_theme: content.tag.join('|') !== '' ? content.tag.join('|') : 'null',
			kit_name: content.title,
			kit_format: kitFormats,
			kit_color: !!content.kitorig_color ? content.kitorig_color : 'null',
			page_path: router.asPath,
			page_title: document.title,
		});
	};

	return (
		<>
			{content && content.medium && (
				<div className="modal--kit__preview">
					<img src={content.medium} alt={content.title} />
				</div>
			)}

			<div className="modal--kit__content">
				{deleteKitVariationStatus === STATUS_LOADING ? (
					<Loading
						isWhite
						style={{ width: '380px', height: '236px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					/>
				) : (
					<>
						<div className="modal__body modal__body--medium narrow-2">
							<h3 className="mb-2 mt-2 ft-h2">Supprimer le kit</h3>
							<p className="mb-1">
								Supprimer un kit l’effacera définitivement de votre tableau de bord, sans possibilité de le retrouver.
								Êtes-vous sûr de vouloir supprimer ce kit ?
							</p>
						</div>

						<div>
							<Button label="Oui, supprimer le kit" onClick={handleDeleteKit} className="mb-2 mt-1 mt-md-2 btn--red" />
							<Button label="Non, annuler l'opération" onClick={hideModal} className="mb-2 mt-1 mt-md-2 btn--white" />
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default DeleteKit;
