// @flow
import Link from 'next/link';
import React from 'react';

import DeleteKit from 'containers/kits/kitCard/DeleteKitContainer';

type Props = {
	content: any,
	hideModal: () => void,
};

function DeleteKitModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box modal--kit">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<div className="modal__wrapper modal__wrapper--delete">
				<DeleteKit content={content} hideModal={hideModal} />
			</div>
		</div>
	);
}

export default DeleteKitModalContent;
