// @flow
import React from 'react';

function IncompatibleBrowserWarning(): React$Element<'section'> {
	return (
		<section className="warning--kits">
			<div className="warning__wrapper warning__wrapper--kits">
				<div />
				<p>
					Votre navigateur n’est pas compatible avec notre service d’édition des kits. Mettez le à jour{' '}
					<a href="https://www.microsoft.com/fr-fr/edge" target="_blank" rel="noopener noreferrer">
						ici
					</a>{' '}
					pour profiter de l’expérience complète du service kits de communication FFR.
				</p>
			</div>
		</section>
	);
}

export default IncompatibleBrowserWarning;
