// @flow

// MY KITS
export type SingleKitFormats = {
	name: string,
	link: string,
	exemple: string,
	miniature: string,
};

export type RawSingleMyKit = {
	id: number,
	title: string,
	date: string,
	configuration: any[],
	structureId: string,
	kitorig: string,
	kitorig_color: string,
	title_orig: string,
	formats: string[],
	formats_orig: SingleKitFormats[],
	couleurs_orig: string[],
	formats_selected: SingleKitFormats[],
	tag: string[],
	thumbnail: string,
	medium: string,
	type: 'personnalisable' | 'pret-emploi',
	zipLink: string,
	zipContainedFormats: string[],
	exemple_defaut: string,
};

export type SingleMyKit = {
	id: number,
	title: string,
	date: string,
	configuration: any[],
	structureId: string,
	kitorig: string,
	kitorig_color: string,
	title_orig: string,
	formats: string[],
	formats_orig: SingleKitFormats[],
	couleurs_orig: string[],
	formats_selected: SingleKitFormats[],
	tag: string[],
	thumbnail: string,
	medium: string,
	type: 'personnalisable' | 'pret-emploi',
	zipLink: string,
	defaultExemple: string,
};

export const nullSingleMyKit = {
	id: 0,
	title: '',
	date: '',
	configuration: ([]: Array<empty>),
	structureId: '',
	kitorig: '',
	kitorig_color: '',
	title_orig: '',
	formats: [''],
	formats_orig: [
		{
			name: '',
			link: '',
			exemple: '',
			miniature: '',
		},
	],
	couleurs_orig: [''],
	formats_selected: [
		{
			name: '',
			link: '',
			exemple: '',
			miniature: '',
		},
	],
	tag: [''],
	thumbnail: '',
	medium: '',
	type: 'personnalisable',
	zipLink: '',
	defaultExemple: '',
};

// CUSTOMIZED KITS
type ImageModificationType = {
	fileUrl: string,
	height: number,
	opacity: number,
	position: {
		x: number,
		y: number,
	},
	width: number,
};

type ShapeModificationType = {
	color: string,
	height: number,
	opacity: number,
	position: {
		x: number,
		y: number,
	},
	shapeRoundness: number,
	width: number,
};

type TextModificationType = {
	police: string,
	policeFile: string,
	fontWeight: string,
	fontSize: number,
	color: string,
	style: string,
	horizontalAlign: string,
	verticalAlign: string,
	textTypo: string, // uppercase, lowercase, firstLetter
};

export type SingleCustomizedKit = SingleMyKit & {
	configuration: {
		image: ImageModificationType[],
		shape: ShapeModificationType[],
		text: TextModificationType[],
	},
};

export const nullSingleCustomizedKit = {
	...nullSingleMyKit,
	configuration: ([]: Array<empty>),
};

// KITS
export type SingleKit = {
	id: number,
	formats: SingleKitFormats[],
	title: string,
	thumbnail: string,
	zipLink: string,
	medium: string,
	date: string,
	tag: string[],
	type: 'personnalisable' | 'pret-emploi',
	defaultExemple: string,
	kit_color: string,
	zipContainedFormats: string[],
};

export type SingleFormatType = {
	id: string,
	name: string,
	placeholder_width: number,
	placeholder_height: number,
	size: string,
	type: string,
};

export type RawSingleKitType = {
	id: number,
	date: string,
	formats: SingleKitFormats[],
	medium: string,
	slug: string,
	tag: string[],
	thumbnail: string,
	title: {
		rendered: string,
	},
	type_kit: string,
	zipfile: string,
	zip_contained_formats: string[],
	couleurs: string[],
	exemple_defaut: string,
	kit_color: string,
};

const nullSingleKitFormat = {
	name: '',
	link: '',
	exemple: '',
};

export const nullSingleKit = {
	id: 0,
	formats: [nullSingleKitFormat],
	title: '',
	thumbnail: '',
	zipLink: '',
	zipContainedFormats: [''],
	medium: '',
	date: '',
	tag: [''],
	type: 'pret-emploi',
	couleurs: [''],
	defaultExemple: '',
	kit_color: '',
	zipContainedFormats: [''],
};
