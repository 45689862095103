// @flow
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import type { DispatchProps, StateProps } from 'components/editor/KitCreationInProgress';
import KitCreationInProgress from 'components/editor/KitCreationInProgress';
import type { DispatchType, StateType } from 'types/Actions';

// ! @todo : le moment venu ajouter le statut nécessaire pour définir la fermeture de la modal
export default (connect(
	null,
	// (state: StateType): $Exact<StateProps> => ({}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		displayModal: (modalObject: string) => dispatch(displayModal(modalObject)),
	}),
)(KitCreationInProgress): any);
