// @flow
import React from 'react';

import CreationRequestForm from 'containers/kits/initialStep/CreationRequestFormContainer';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
	content: any,
	hideModal: () => void,
};

function CreationRequestModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box newsletter-input ">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal} />
			<div
				className="modal__header"
				style={{ backgroundImage: `url(${getPublicAssets('/assets/img/banner/banner-modal-site-club.jpg')})` }}>
				<h3 className="ft-h2 mb-2">
					Accéder aux kits
					<br /> de communication
				</h3>
			</div>
			<div className="modal__wrapper">
				<CreationRequestForm />
			</div>
			<div className="block-infos-generales">
				<div ref={(ref) => (window.snack = ref)} className="form-action-success" style={{ backgroundColor: '#cc2e3b' }}>
					<i className="icon icon-shield" />
					<p className="ft-500 mb-0">Votre adresse e-mail ne peut être vérifiée.</p>
				</div>
			</div>
		</div>
	);
}

export default CreationRequestModalContent;
