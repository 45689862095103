// @flow
import { nullKitAdmin } from 'types/KitAdmin';
import type { KitAdminType } from 'types/KitAdmin';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertKitAdminFromApi(rawKitAdmin: KitAdminType): KitAdminType {
	if (!rawKitAdmin) {
		return nullKitAdmin;
	}

	return mergeIntoDefault(nullKitAdmin, { ...rawKitAdmin });
}
