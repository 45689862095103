// @flow
import React from 'react';
import { useState } from 'react';

import Button from 'components/fragments/button/Button';
import TextInput from 'components/fragments/input/TextInput';

export type DispatchProps = {
	postKitVariation(structureid: number, kitorig: number, change: Object): void,
};

export type StateProps = {
	structureid: number,
};

type Props = DispatchProps &
	StateProps & {
		content: any,
		hideModal: () => void,
	};

function RenameMyKit({ content, hideModal, postKitVariation, structureid }: Props): React$Element<'div'> {
	const [kitTitle, setKitTitle] = useState(content.kitTitle);

	const handleConfirmation = (event: Event) => {
		event.preventDefault();

		content.setKitTitle(kitTitle, true);
		postKitVariation(structureid, content.kitorig, { title: kitTitle });
		hideModal();
	};

	return (
		<div className="modal__body modal__body--medium narrow-2">
			<h3 className="mb-3 mt-2 ft-h2">Renommer mon kit</h3>
			<form onSubmit={handleConfirmation}>
				<TextInput customClasses="mb-3" onChange={setKitTitle} defaultValue={kitTitle} />
				<Button label="Confirmer" onClick={handleConfirmation} className="mb-2 mt-1 mt-md-2" />
			</form>
		</div>
	);
}

export default RenameMyKit;
