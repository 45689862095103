// @flow
import Cookie from 'components/footer/Cookie';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/footer/Cookie';
import { setShowCookieInfo } from 'actions/appActions';

export default (connect(
	(state: StateType): $Exact<StateProps> => ({
		cookies: state.settingsState.cookies,
		showCookieInfo: state.appState.showCookieInfo,
		title: state.settingsState.title,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		setShowCookieInfo: (value: boolean, accept?: boolean) => dispatch(setShowCookieInfo(value, accept)),
	}),
)(Cookie): any);
