// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertKitsFromApi } from 'services/Kits';

function KitsApi(executor: ApiExecutorType): { fetchKits: (structureOvaleCode: string) => empty, ... } {
	return {
		fetchKits,
	};

	function fetchKits(structureOvaleCode: string) {
		return executor.get(`ffr/v1/kit?per_page=500&structureOvaleCode=${structureOvaleCode}&time=${new Date().getTime()}`).then((response) => convertKitsFromApi(response));
	}
}

export default KitsApi;
