// @flow
import CookieCutter from 'cookie-cutter';
import { withRouter } from 'next/router';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { fetchSettings, setShowCookieInfo } from 'actions/appActions';
import { fetchClub } from 'actions/clubActions';
import { stockMagicLink, stockStructureID } from 'actions/magicLinkActions';
import { fetchMenu } from 'actions/menuActions';
import { displayModal } from 'actions/modalActions';
import MainHtmlHead from 'components/app/Head';
import withScreenType from 'components/fragments/withScreenType';
import type { HocStateProps } from 'components/fragments/withScreenType';
import Footer from 'components/footer';
import Header from 'components/header';
import IncompatibleBrowserWarning from 'components/incompatibleBrowserWarning';
import {
	headerBlacklistWithSlugPaths,
	footerBlacklistWithSlugPaths,
	footerBlacklistPaths,
} from 'constants/menuConstants';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import ProtectedRoute from 'containers/app/ProtectedRouteContainer';
import ModalContainer from 'containers/fragments/ModalContainer';
import CookieContainer from 'containers/footer/CookieContainer';
import wrapper from '../store';
import Numberly from 'tracking/numberly';
import type { StateType, DispatchType } from 'types/Actions';
import type { ClubType } from 'types/Club';
import type { RouterProps } from 'types/Router';
import type { Status } from 'types/Status';
import type { FaviconType } from 'types/Settings';
import { parseCookies } from 'utils/cookiesUtils';
import HostnameContext from 'utils/hostnameUtils';
import { getMagicLinkCookie } from 'utils/magicLinkUtils';
import { numberlyPageView } from 'utils/numberlyUtils';
import { isBrowser } from 'utils/nextUtils';
import TagManagerInit from 'utils/tagManagerInit';
import TagManager from 'utils/tagManagerUtils';

import 'public/assets/css/style.css';
import 'public/assets/css/style_surcharge.css';
import 'components/editor/editor.css';

type DispatchProps = {
	displayModal(modalObject: string, content: any): void,
	fetchClub(structureID: number, magicLink: string): void,
	fetchMenu(): void,
	fetchSettings(): void,
	setShowCookieInfo(showCookieInfo: boolean, acceptCookie?: boolean): void,
	stockStructureID(structureID: number): void,
	stockMagicLink(magicLink: string): void,
};

type OwnProps = {
	Component: any,
	cookies: string,
	pageProps: any,
};

type StateProps = {
	acceptCookie?: boolean,
	acceptCookieDate: Date | null,
	club: ClubType,
	structureID: number,
	clubStatus: Status,
	favicons: FaviconType,
	magicLink: string,
	metaTitleHome: string,
	metaDescriptionHome: string,
	placeholder: string,
};

type Props = DispatchProps & HocStateProps & OwnProps & RouterProps & StateProps;

type State = {
	incompatibleBrowser: boolean,
};

class App extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
	}

	state: State = {
		incompatibleBrowser: false,
	};

	//==============================================================
	// LIFE CYCLES
	//==============================================================
	componentDidMount() {
		const { acceptCookie, fetchMenu, fetchSettings, structureID } = this.props;

		fetchSettings();
		fetchMenu();

		// gérer la modal LogoutSuccess
		this.handleLogoutSuccessModal();

		// gérer le structureID, le magicLink et le cookie
		const id = parseInt(structureID, 10);

		if (!isNaN(id) || CookieCutter.get('magic') === '') {
			this.handleUserAuthentification();
		}

		// gérer les navigateurs non-compatibles (hors mobile)
		this.handleIncompatibleBrowser();

		// Google Tag Manager
		// we only initialize Tracking when keycloak is initialized since we need keycloak data
		if (typeof window !== undefined && acceptCookie) {
			this.initializeTracking();
		}

		// on initialise l'accord sur les cookies
		this.initAcceptedCookies();
	}

	handleUserAuthentification() {
		const { fetchClub, magicLink, stockStructureID, stockMagicLink, structureID } = this.props;

		const magicLinkCookieData = CookieCutter.get('magic');

		// je  n'ai pas de cookie ou il est vide
		if (!magicLinkCookieData || magicLinkCookieData === '') {
			//* Définition de l'id de la structure et du magicLink
			// tous les navigateurs - l'id et le magic link viennent du state
			let id = parseInt(structureID, 10);
			let magic = magicLink;

			// safari - on perd le state - on récupère les infos si on est sur le lien magique
			if (isBrowser() && navigator.userAgent.includes('Safari') && window.location.href.includes('magic')) {
				const params = new URLSearchParams(window.location.search);
				// on récupère les valeurs du lien et de la structure dans le lien magique
				params.forEach((value, key) => {
					if (key === 'magic') {
						magic = value;
					} else if (key === 'structureID') {
						id = parseInt(value, 10);
					}
				});

				// on stocke les données dans le state
				this.stockUserAuthentificationData(id, magic);
			}

			//* on stocke les données dans le cookie si elles sont valides
			if (id > 0 && magic !== '') {
				this.setUserAuthentificationCookie(id, magic);
			}

			//* récupération des données de la structure
			fetchClub(id, magic);
		} else {
			const { structureIDCookie, magicLinkCookie } = getMagicLinkCookie();

			this.stockUserAuthentificationData(structureIDCookie, magicLinkCookie);

			const id = parseInt(structureIDCookie, 10);

			fetchClub(id, magicLinkCookie);
		}
	}

	setUserAuthentificationCookie(structureId, magicLink) {
		const now = new Date();
		const expirationDate = now.setDate(now.getDate() + 1);
		CookieCutter.set('magic', `${structureId}---${magicLink}`, { expires: expirationDate });
	}

	stockUserAuthentificationData(structureId, magicLink) {
		const { stockStructureID, stockMagicLink } = this.props;

		stockStructureID(structureId);
		stockMagicLink(magicLink);
	}

	componentDidUpdate(prevProps: Props) {
		const {
			acceptCookie,
			acceptCookieDate,
			club,
			club: {
				configuration: { step },
			},
			clubStatus,
			router,
			structureID,
		} = this.props;

		// gérer la modal LogoutSuccess
		this.handleLogoutSuccessModal();

		const id = parseInt(structureID, 10);
		if (structureID !== prevProps.structureID && (!isNaN(id) || CookieCutter.get('magic') === '')) {
			this.handleUserAuthentification();
		}

		// scroll au changement de page
		if (router.pathname !== prevProps.router.pathname) {
			window.scrollTo(0, 0);
		}

		// gestion des cookies
		if (acceptCookieDate !== prevProps.acceptCookieDate) {
			CookieCutter.set('cookiesAccepted', !!acceptCookie);
			CookieCutter.set('cookiesAcceptedDate', acceptCookieDate);
		}

		// gérer le structureID, le magicLink et le cookie
		if (clubStatus === STATUS_FAILURE) {
			if (CookieCutter.get('magic')) {
				CookieCutter.set('magic', ``, { expires: new Date(0) });
			}
		}

		// GTM
		// we only initialize Tracking when keycloak is initialized since we need keycloak data
		if (typeof window !== undefined && acceptCookie !== prevProps.acceptCookie && !!acceptCookie) {
			this.initializeTracking();

			this.props.router.events.on('routeChangeComplete', this.handleTrackingOnChange);
		}
	}

	componentWillUnmount() {
		this.props.router.events.off('routeChangeComplete', this.handleTrackingOnChange);
	}

	initAcceptedCookies = () => {
		const { setShowCookieInfo } = this.props;
		const currentCookiesAccepted = CookieCutter.get('cookiesAccepted');
		const currentCookiesAcceptedDate = CookieCutter.get('cookiesAcceptedDate');
		const cookiesAccepted = currentCookiesAccepted === 'true' ? true : false;

		if (currentCookiesAccepted && currentCookiesAcceptedDate) {
			setShowCookieInfo(false, cookiesAccepted);
		}
	};

	initializeTracking = () => {
		// GTM
		// on init le datalayer avec des string vide car on n'a pas l'info des user et pas la possibilité de les avoir
		const data = {
			user_id: '',
			user_type: '',
		};
		TagManagerInit.initializeDatalayer(data);

		// on load le script gtm
		TagManagerInit.loadGtmScript();

		// NUMBERLY
		Numberly.init('6583261-9c0f69fbbce6d22c75fd70fb5f3b5d42');

		// traquer la 1ère page
		this.handleTrackingOnChange();
	};

	handleTrackingOnChange = () => {
		const { router } = this.props;
		if (typeof window === 'undefined') {
			return;
		}

		TagManager.handleTagEvent({
			event: 'page_view',
			page_path: this.props.router.pathname,
			page_title: document.title,
		});
	};

	handleLogoutSuccessModal() {
		const { displayModal } = this.props;

		const logoutSuccessModalCookie = CookieCutter.get('logoutSuccessModalCookie');

		if (!!logoutSuccessModalCookie && logoutSuccessModalCookie !== '') {
			const logoutContent = {
				title: 'Vous êtes bien déconnecté.',
				text: `Votre création a bien été enregistrée. 
				Revenez sur cette page pour continuer plus tard la configuration de vos kits de communication.`,
				emailUser: logoutSuccessModalCookie,
			};
			displayModal('LOGOUT_SUCCESS', logoutContent);
		}
	}

	handleIncompatibleBrowser = () => {
		const browser = isBrowser() ? navigator.userAgent : '';
		const incompatibleBrowser = browser.includes('explorer');

		if (!!incompatibleBrowser) {
			this.setState({ incompatibleBrowser });
		}
	};

	render() {
		const {
			acceptCookie,
			Component,
			pageProps,
			router: { pathname },
		} = this.props;
		const { incompatibleBrowser } = this.state;

		const isEditorRoute = pathname.includes('editeur');

		return (
			<>
				<ModalContainer />

				<ProtectedRoute acceptCookie={acceptCookie}>
					<MainHtmlHead {...this.props} />

					{incompatibleBrowser && <IncompatibleBrowserWarning />}

					{/* HEADERS */}
					{!isEditorRoute && !headerBlacklistWithSlugPaths.includes(pathname.split('/')[1]) && <Header />}

					<Component {...pageProps} />

					{!isEditorRoute &&
						!footerBlacklistPaths.includes(pathname) &&
						!footerBlacklistWithSlugPaths.includes(pathname.split('/')[1]) && <Footer />}

					<CookieContainer />
				</ProtectedRoute>
			</>
		);
	}
}

// On récupère les données du store de redux
const ConnectedApp = connect(
	(state: StateType): $Exact<StateProps> => ({
		acceptCookie: state.appState.acceptCookie,
		acceptCookieDate: state.appState.acceptCookieDate,
		club: state.clubState.club,
		structureID: state.magicLinkState.structureID,
		clubStatus: state.clubState.status,
		favicons: state.settingsState.favicons,
		magicLink: state.magicLinkState.magicLink,
		metaTitleHome: state.settingsState.meta_title_home,
		metaDescriptionHome: state.settingsState.meta_description_home,
		placeholder: state.settingsState.placeholder,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		displayModal: (modalObject: string, content: any) => dispatch(displayModal(modalObject, content)),
		fetchClub: (structureID: number, magicLink: string) => dispatch(fetchClub(structureID, magicLink)),
		fetchMenu: () => dispatch(fetchMenu()),
		fetchSettings: () => dispatch(fetchSettings()),
		setShowCookieInfo: (showCookieInfo: boolean, acceptCookie?: boolean) =>
			dispatch(setShowCookieInfo(showCookieInfo, acceptCookie)),
		stockStructureID: (structureID: number) => dispatch(stockStructureID(structureID)),
		stockMagicLink: (magicLink: string) => dispatch(stockMagicLink(magicLink)),
	}),
)(withRouter(withScreenType(App)));

// On rend notre Application
// - dans le contexte de HostnameContext (on peut donc utiliser les props directement dans n'importe quel composant quel que soit le niveau)
// - en ayant ajouté des données provenant du store de redux
const KitsCommApp = ({ Component, cookies, pageProps, host, href, pathname }) => {
	return (
		<HostnameContext.Provider value={{ host, href, pathname }}>
			<ConnectedApp Component={Component} pageProps={pageProps} host={host} cookies={cookies} />
		</HostnameContext.Provider>
	);
};

// on init les props correspondantes au context HostnameContext
KitsCommApp.getInitialProps = async ({ ctx }) => {
	if (typeof window !== 'undefined') {
		return {
			host: window.location.host,
			href: window.location.href,
			pathname: window.location.pathname,
			cookies: parseCookies(ctx?.req),
		};
	}

	return {
		host: ctx.req.headers.host,
		href: `https://${ctx.req.headers.host}${ctx.req.url}`,
		pathname: ctx.req.url,
		cookies: parseCookies(ctx?.req),
	};
};

export default (wrapper.withRedux(KitsCommApp): any);
