// @flow

export type ConfigType = {
	step: null | number,
};

export type ClubType = {
	code: string,
	configuration: ConfigType,
	email: string,
	emailOfficiel: string,
	embleme: string,
	nom: string,
	is_admin: boolean | null,
};

export const nullClub: ClubType = {
	code: '',
	configuration: {
		step: null,
	},
	email: '',
	emailOfficiel: '',
	embleme: '',
	nom: '',
	is_admin: null,
};
