// specific to this project
export const kitsCommNav = [
	{
		id: 1,
		parent: 0,
		order: 1,
		type: 'post_type',
		type_name: 'custom',
		type_slug: '',
		object_id: 1,
		object_slug: 'kits/tableau-de-bord',
		slug_complet: '',
		label: 'Lien personnalisé',
		url: '/kits/tableau-de-bord',
		title: 'Tableau de bord',
		target: '',
		description: '',
		items: [],
		classes: 'kits-comm-link',
	},
	{
		id: 2,
		parent: 0,
		order: 1,
		type: 'post_type',
		type_name: 'custom',
		type_slug: '',
		object_id: 2,
		object_slug: 'kits/mes-kits',
		slug_complet: '',
		label: 'Mes kits',
		url: '/kits/mes-kits',
		title: 'Mes kits',
		target: '',
		description: '',
		items: [],
		classes: 'kits-comm-link',
	},
	{
		id: 2,
		parent: 0,
		order: 1,
		type: 'post_type',
		type_name: 'custom',
		type_slug: '',
		object_id: 2,
		object_slug: 'kits/modeles',
		slug_complet: '',
		label: 'Modèles de kit',
		url: '/kits/modeles',
		title: 'Modèles de kit',
		target: '',
		description: '',
		items: [],
		classes: 'kits-comm-link',
	},
	{
		id: 3,
		parent: 0,
		order: 1,
		type: 'post_type',
		type_name: 'custom',
		type_slug: '',
		object_id: 2,
		object_slug: 'kits/centre-d-aide',
		slug_complet: '',
		label: "Centre d'aide",
		url: '/kits/centre-d-aide',
		title: "Centre d'aide",
		target: '',
		description: '',
		items: [],
		classes: 'kits-comm-link',
	},
];

export const kitsCommNavForAdmin = [
	{
		id: 4,
		parent: 0,
		order: 4,
		type: 'post_type',
		type_name: 'custom',
		type_slug: '',
		object_id: 4,
		object_slug: 'kits/administration',
		slug_complet: '',
		label: 'Administration',
		url: '/kits/administration',
		title: 'Administration',
		target: '',
		description: '',
		items: [],
		classes: 'kits-comm-link',
	},
];

// other projects
export const baseMenuItemWidth = 45;
export const baseHeaderCategorySearchWidth = 58;
export const letterWidth = 5;
export const letterWidthSearch = 9;
export const logoWidth = 100 + 120;
export const footerBlacklistPaths = [
	'/trouver-un-club-de-rugby/resultats-de-recherche',
	'/trouver-un-club-de-rugby/resultats-de-recherche/',
	'/ffr-tv-player/',
];
export const headerBlacklistWithSlugPaths = ['ffr-tv-player'];
export const footerBlacklistWithSlugPaths = ['ffr-tv-player'];
export const headerFilterClubWithlistPaths = [
	'/trouver-un-club-de-rugby/resultats-de-recherche',
	'/trouver-un-club-de-rugby/resultats-de-recherche/',
];
export const headerFilterCentenaireWithlistPaths = [
	'/histoires-de-rugby/resultats-de-recherche',
	'/histoires-de-rugby/resultats-de-recherche/',
	'/histoires-de-rugby/carte',
	'/histoires-de-rugby/carte/',
];
export const topBarMenuApiName = 'top-nav';
export const headerMenuApiName = 'header';
export const footerMenuApiName = 'footer';

export const TYPE_NORMAL = 'normal';
export const TYPE_MOBILE = 'mobile';
