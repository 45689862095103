// @flow
import { connect } from 'react-redux';

import { forgotMagicLink, getModalForNewLink } from 'actions/magicLinkActions';
import { hideModal } from 'actions/modalActions';
import Modal from 'components/fragments/modal/Modal';
import type { DispatchProps, StateProps } from 'components/fragments/modal/Modal';
import type { DispatchType, StateType } from 'types/Actions';

const mapStateToProps = (state: StateType): $Exact<StateProps> => ({
	modalObject: state.modalState.modalObject,
	force: state.modalState.force,
	content: state.modalState.content,
	emailUser: state.clubState.club.email,
});

const mapDispatchToProps = (dispatch: DispatchType): $Exact<DispatchProps> => ({
	forgotMagicLink: () => dispatch(forgotMagicLink()),
	getModalForNewLink: () => dispatch(getModalForNewLink()),
	hideModal: () => dispatch(hideModal()),
});

export default (connect(mapStateToProps, mapDispatchToProps)(Modal): any);
