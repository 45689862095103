// @flow
import { connect } from 'react-redux';

import { postKitVariation } from 'actions/kitsVariationsActions';
import type { StateProps, DispatchProps } from 'components/editor/RenameMyKit';
import ChooseKitFormats from 'components/editor/RenameMyKit';
import type { StateType, DispatchType } from 'types/Actions';

export default (connect(
	(state: StateType): $Exact<StateProps> => ({
		structureid: state.magicLinkState.structureID,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		postKitVariation: (structureid: number, kitorig: number, change: Object) =>
			dispatch(postKitVariation(structureid, kitorig, change)),
	}),
)(ChooseKitFormats): any);
