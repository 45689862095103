// @flow
import { kitsCommNav } from 'constants/menuConstants';
import { RTGENV, UNTRACKED_PATH } from 'constants/numberlyConstants';
import * as path from 'path';
import Numberly from 'tracking/numberly';

// cas des kits comm => isConnected à la plateforme et non au SSO
export const numberlyPageView = (isConnected: boolean, pathname: string) => {
	// on ne traque pas le lien magic ou l'éditeur
	if (pathname.includes('magic') || pathname.includes('editeur')) {
		return;
	}
	// on ne veut pas traquer les pages ayant des tags spécifiques indiquées dans UNTRACKED_PATH
	else if (!UNTRACKED_PATH.includes(pathname)) {
		const numberlyEvent = {
			rtgpg: 'generic',
			rtgenv: RTGENV,
			rtglogged: !!isConnected ? '1' : '0',
			rtglanguage: 'fr',
		};

		Numberly.tag(numberlyEvent);
	} else {
		// Ici on traque spécifiquement la home
		if (pathname === '/') {
			const event = {
				rtgpg: 'hp',
				rtgenv: RTGENV,
				rtglogged: !!isConnected ? '1' : '0',
				rtglanguage: 'fr',
			};

			Numberly.tag(event);
		}
		// ici on traque les autres pages
		else {
			const currentNavItem = kitsCommNav.find((item) => item.url === pathname);
			if (!!currentNavItem) {
				const catName = currentNavItem.title;
				const event = {
					rtgpg: 'cat',
					rtgenv: RTGENV,
					rtglogged: !!isConnected ? '1' : '0',
					rtglanguage: 'fr',
					rtgcat: catName,
				};

				Numberly.tag(event);
			}
		}
	}
};
