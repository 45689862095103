/* eslint-disable @next/next/no-img-element */
// @flow
import Link from 'next/link';
import { withRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Button from 'components/fragments/button/Button';
import SingleFormatCard from 'components/kits/formatCard/SingleFormatCard';
import { FORMAT_LIST } from 'constants/kitConstants';
import { RTGENV } from 'constants/numberlyConstants';
import Numberly from 'tracking/numberly';
import type { RouterProps } from 'types/Router';
import TagManager from 'utils/tagManagerUtils';
import { capitalizeFirstLetter } from 'utils/textUtils';

export type DispatchProps = {
	postKitVariation(structureid: number, kitorig: number, change?: any): void,
};

export type StateProps = {
	structureid: number,
};

type Props = DispatchProps &
	RouterProps &
	StateProps & {
		content: any,
		hideModal(): void,
	};

type State = {
	selectedFormats: string[],
};

function ChooseKitFormats({ content, hideModal, postKitVariation, router, structureid }: Props) {
	const [formats, setFormats] = useState();
	const [selectedFormats, setSelectedFormats] = useState([]);
	const [prevSelectedFormat, setPrevSelectedFormat] = useState([]);
	const [errorMessage, setErrorMessage] = useState();
	const isEditorRoute = router.pathname.includes('editeur');

	useEffect(() => {
		getSelectedFormats();
		getAvailableFormats();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//==========================================================================
	// Variables
	//==========================================================================
	const currentKitFormats = formats
		? formats
				.filter((format) => !!format.link)
				.map((format) => {
					const newCurrentKitFormat = FORMAT_LIST.find((item) => item.id === format.name);

					return newCurrentKitFormat;
				})
		: FORMAT_LIST;
	const webFormats = currentKitFormats.filter((format) => format.type === 'web');
	const printFormats = currentKitFormats.filter((format) => format.type === 'print');

	//==========================================================================
	// Fonctions spécifiques
	//==========================================================================
	const getAvailableFormats = () => {
		const formats = isEditorRoute
			? content.formats_orig.filter((formatOrig) => !content.formats.includes(formatOrig.name))
			: content.formats;

		setFormats(formats);
	};

	const getSelectedFormats = () => {
		let alreadySelectedFormats;
		if (isEditorRoute) {
			alreadySelectedFormats = content.formats_selected.map((format) => format.name);
		} else {
			alreadySelectedFormats = content.formats.filter((format) => !!format.link).map((format) => format.name);

			alreadySelectedFormats = [alreadySelectedFormats[0]];
		}

		setSelectedFormats(alreadySelectedFormats);
		setPrevSelectedFormat(alreadySelectedFormats);
	};

	const handleErrorMessage = (newSelectedFormats) => {
		const prevSelectedFormatsNumber = prevSelectedFormat.length;

		if (isEditorRoute) {
			const newSelectedFormatsNumber = newSelectedFormats.length;

			if (newSelectedFormatsNumber <= prevSelectedFormatsNumber) {
				setErrorMessage('Merci de sélectionner au minimum un format.');
			} else {
				setErrorMessage();
			}
		} else {
			if (!newSelectedFormats.length) {
				setErrorMessage('Merci de sélectionner au minimum un format.');
			} else {
				setErrorMessage();
			}
		}
	};

	const handlePostFormats = () => {
		const kitid = isEditorRoute ? content.kitorig : content.id;

		if (selectedFormats.length > 0) {
			postKitVariation(structureid, kitid, { formats: selectedFormats });
			hideModal();
		} else {
			setErrorMessage('Merci de sélectionner au minimum un format.');
		}

		if (!isEditorRoute) {
			const kitPurpose = selectedFormats
				.reduce((acc, selectedFormat) => {
					const currentFormat = FORMAT_LIST.find((format) => format.id === selectedFormat);
					const currentFormatType = !!currentFormat && !!currentFormat.type ? currentFormat.type : '';

					if (!!currentFormatType && acc.every((item) => item !== currentFormatType)) {
						return acc.concat(currentFormatType);
					}

					return acc;
				}, [])
				.join('|');

			const kitFormats = FORMAT_LIST.map((format) => {
				if (selectedFormats.includes(format.id)) {
					return format.name;
				}
			})
				.filter((format) => !!format)
				.join('|');

			TagManager.handleTagEvent({
				event: 'kit_customization_initiated',
				kit_type: content.type,
				kit_purpose: kitPurpose ?? '',
				kit_theme: content.tag.join('|') !== '' ? content.tag.join('|') : 'null',
				kit_name: content.title,
				kit_format: kitFormats,
				kit_color: !!content.kit_color ? content.kit_color : 'null',
				page_path: router.asPath,
				page_title: document.title,
			});

			Numberly.tag({
				rtgpg: 'PersonalizeKit',
				rtgenv: RTGENV,
				rtglogged: '1', // car ici on est forcément connecté
				rgtconversion: '1',
				rtglanguage: 'fr',
			});
		}
	};

	const onSingleFormatCardClick = (formatId: string) => {
		let newSelectedFormats = selectedFormats;

		if (!selectedFormats.includes(formatId)) {
			newSelectedFormats = selectedFormats.concat(formatId);
		} else {
			newSelectedFormats = selectedFormats.filter((format) => format !== formatId);
		}

		setSelectedFormats(newSelectedFormats);

		handleErrorMessage(newSelectedFormats);
	};

	//==========================================================================
	// Render
	//==========================================================================
	return (
		<>
			{content && content.medium && (
				<div className="modal--kit__preview">
					<div>
						<img src={content.medium} alt={content.title} />
						<p className="mt-2">
							{!!content.tag.length ? `${capitalizeFirstLetter(content.tag[0])} -` : ''} {content.title}
						</p>
					</div>
				</div>
			)}
			<div className="modal--kit__content">
				<div className="modal__body modal__body--medium narrow-2">
					<h3 className="mb-1 mt-2 ft-h2">{isEditorRoute ? 'Ajout de format' : 'Choix des formats à personnaliser'}</h3>
					{formats && !!formats.length && (
						<p className="mb-1">{`Choisissez les formats que vous souhaitez ${
							isEditorRoute ? 'ajouter à' : 'modifier pour'
						} votre kit personnalisé.`}</p>
					)}

					{/* Plus de format disponible */}
					{(!formats || !formats.length) && <p className="ft-h2 mt-3 mb-1">Aucun format disponible</p>}
				</div>

				{/* Formats disponibles */}
				{!!webFormats.length && (
					<div>
						<h4 className="ft-h6 ft-up mb-1">Formats web</h4>
						<div className="format-list">
							{webFormats.map((format) => (
								<SingleFormatCard
									key={format.id}
									format={format}
									onSingleFormatCardClick={onSingleFormatCardClick}
									isSelected={selectedFormats.includes(format.id)}
								/>
							))}
						</div>
					</div>
				)}

				{!!printFormats.length && (
					<div>
						<h4 className="ft-h6 ft-up mb-1">Formats print</h4>
						<div className="format-list">
							{printFormats.map((format) => (
								<SingleFormatCard
									key={format.id}
									format={format}
									onSingleFormatCardClick={onSingleFormatCardClick}
									isSelected={selectedFormats.includes(format.id)}
								/>
							))}
						</div>
					</div>
				)}

				<div>
					{errorMessage ? (
						<div className="mb-2 mt-1 mt-md-2 btn btn--white error">{errorMessage}</div>
					) : !isEditorRoute ? (
						<Link href="/editeur">
							<a className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={handlePostFormats}>
								Commencer la personnalisation
							</a>
						</Link>
					) : formats && formats.length ? (
						<button className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={handlePostFormats}>
							Ajouter les formats
						</button>
					) : (
						<button className="mb-2 mt-1 mt-md-2 btn btn--primary" onClick={hideModal}>
							Fermer
						</button>
					)}
				</div>
			</div>
		</>
	);
}

export default (withRouter(ChooseKitFormats): any);
