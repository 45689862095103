// @flow
import { connect } from 'react-redux';

import { deleteKitVariation } from 'actions/kitsVariationsActions';
import type { StateProps, DispatchProps } from 'components/fragments/modal/modalContents/ChooseKitFormatsModalContent';
import ChooseKitFormats from 'components/fragments/modal/modalContents/ChooseKitFormatsModalContent';
import type { StateType, DispatchType } from 'types/Actions';

export default (connect(
	(state: StateType): $Exact<StateProps> => ({
		myKitList: state.kitsVariationsState.myKitList,
		structureid: state.magicLinkState.structureID,
	}),
	(dispatch: DispatchType): $Exact<DispatchProps> => ({
		deleteKitVariation: (structureid: number, kitorig: number) => dispatch(deleteKitVariation(structureid, kitorig)),
	}),
)(ChooseKitFormats): any);
