// @flow

export type SingleStructureType = {
	structure_name: string,
	kit_number: number,
	structure_id: number,
};

export type KitAdminType = {
	kit_by_structure: SingleStructureType[],
};

export const nullKitAdmin = {
	kit_by_structure: ([]: Array<empty>),
};
