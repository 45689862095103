// @flow

import React, { Component } from 'react';

import type { SingleFormatType } from 'types/Kits';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
	format: SingleFormatType,
	isSelected: boolean,
	onSingleFormatCardClick(formatId: string): void,
};

class SingleFormatCard extends Component<Props> {
	render(): React$Element<'div'> {
		const { format, isSelected, onSingleFormatCardClick } = this.props;

		return (
			<div
				className={`card-single-format ${isSelected ? 'is-selected' : ''}`}
				onClick={() => onSingleFormatCardClick(format.id)}>
				<div className="card-single-format__placeholder">
					<div style={{ width: `${format.placeholder_width}px`, height: `${format.placeholder_height}px` }}></div>
				</div>
				<div>
					<p>{format.name}</p>
					<span>{format.size}</span>
				</div>
			</div>
		);
	}
}

export default SingleFormatCard;
