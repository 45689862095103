// @flow
import React from 'react';
import { withRouter } from 'next/router';

import Button from 'components/fragments/button/Button';
import type { RouterProps } from 'types/router';

type Props = {
	content: any,
	hideModal: () => void,
} & RouterProps;

function ScreenTooSmallModalContent({ content, hideModal, router }: Props): React$Element<'div'> {
	const handleClick = () => {
		const { redirect } = content;
		if (redirect) {
			router.push('/');
		} else {
			hideModal();
		}
	};

	return (
		<div className="modal-content box newsletter-valid ">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={handleClick}></i>
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<div className="circle-icon circle-icon--blue"></div>
					<h3 className="mb-1 mt-2 ft-h2 ft-center">
						Service disponible sur ordinateurs et tablettes <br />
						<small>(ayant une résolution minimum de 1080px)</small>
					</h3>
					<p className="mb-1 ft-center">
						Malheureusement, le service kits de communication FFR n&apos;est pas disponible sur votre équipement,
						celui-ci ayant une résolution inférieure à 1080px. Vous pouvez tout de même choisir le gestionnaire des kits
						en avance depuis celui-ci, si ce n&apos;est déjà fait.
					</p>
					{content.isPortrait && content.windowHeightIsEnough && (
						<p className="mb-1 ft-center">
							Cependant, si vous êtes sur tablette, la hauteur de votre équipement permet d&apos;afficher
							l&apos;application, tournez votre écran en mode paysage pour utiliser meskitscom.
						</p>
					)}
					<Button label={content.label} onClick={handleClick} className="mb-2 mt-1 mt-md-2" isFull />
				</div>
			</div>
		</div>
	);
}

export default (withRouter(ScreenTooSmallModalContent): any);
