// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function MagicLinkApi(executor: ApiExecutorType): {
	addAdminDelegation: (email: string, magicLink: string, structureid: number) => empty,
	askMagicLink: (email: string) => Promise<any>,
	...
} {
	return { askMagicLink, addAdminDelegation };

	function askMagicLink(email: string): Promise<any> {
		return executor.post('ffr/v1/onboarding_magic', { email });
	}

	function addAdminDelegation(email: string, magicLink: string, structureid: number) {
		const action = 'add_delegation';
		return executor.post(
			'ffr/v1/onboarding_magic',
			{ email, action, structureid },
			getOnboardingMagicHeader(magicLink),
		);
	}
}

export default MagicLinkApi;
