// @flow
import React, { PureComponent } from 'react';
import Link from 'next/link';

import type { MenuElementType } from 'types/MenuElement';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
	subMenus: Array<MenuElementType>,
	title: string,
	comiteName?: string,
	user_id?: number,
};

type ComponentState = {
	isOpen: boolean,
};

class TopBarDropdown extends PureComponent<Props, ComponentState> {
	_DropdownMenu: ?HTMLElement;
	state: ComponentState = {
		isOpen: false,
	};

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside: (event: MouseEvent) => void = (event: MouseEvent) => {
		if (this._DropdownMenu && !this._DropdownMenu.contains((event.target: any))) {
			this.toggleIsOpen(event);
		}
	};

	toggleIsOpen: (e: MouseEvent) => void = (e: MouseEvent) => {
		e.preventDefault();
		if (!this.state.isOpen) {
			document.addEventListener('mousedown', this.handleClickOutside, { once: true });
		}
		this.setState({ isOpen: !this.state.isOpen });
	};

	renderDropDownElements: () => Array<React$Element<'li'>> = () => {
		const { subMenus, comiteName, user_id } = this.props;

		return subMenus.map((subMenu, index) => {
			return (
				<li key={index}>
					<Link href={subMenu.url}>
						<a
							title={`Se rendre sur le site ${subMenu.title} (nouvel onglet)`}
							target="_blank"
							rel="noopener noreferrer"
							onClick={this.closeDropDown}>
							{subMenu.title}
						</a>
					</Link>
				</li>
			);
		});
	};

	closeDropDown: () => void = () => {
		this.setState({
			isOpen: false,
		});
	};

	render(): React$Element<'li'> {
		const { isOpen } = this.state;
		const { title } = this.props;
		const classNamesTopBarWrapper = dynamicClassName('topbar__sub accordion-panel');
		!isOpen && classNamesTopBarWrapper.add('is-hidden');

		return (
			<li ref={(r) => (this._DropdownMenu = r)}>
				<a // eslint-disable-line
					role="button"
					tabIndex={0}
					className="accordion-trigger"
					onClick={this.toggleIsOpen}
					title="Afficher le menu">
					{title}
				</a>
				<ul className={classNamesTopBarWrapper.build()} id="accordion-ligues">
					{this.renderDropDownElements()}
				</ul>
			</li>
		);
	}
}

export default TopBarDropdown;
