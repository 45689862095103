// @flow

import type { ContactFormType, FormDataType } from 'types/ContactForm';

export const FETCH_CONTACT_FORM_START = 'FETCH_CONTACT_FORM_START';
export const FETCH_CONTACT_FORM_SUCCESS = 'FETCH_CONTACT_FORM_SUCCESS';
export const FETCH_CONTACT_FORM_FAILURE = 'FETCH_CONTACT_FORM_FAILURE';

export const POST_CONTACT_FORM_START = 'POST_CONTACT_FORM_START';
export const POST_CONTACT_FORM_SUCCESS = 'POST_CONTACT_FORM_SUCCESS';
export const POST_CONTACT_FORM_FAILURE = 'POST_CONTACT_FORM_FAILURE';

export type FETCH_CONTACT_FORM_START_ACTION = {
  type: 'FETCH_CONTACT_FORM_START',
};

export type FETCH_CONTACT_FORM_SUCCESS_ACTION = {
  type: 'FETCH_CONTACT_FORM_SUCCESS',
  payload: {
    contactForm: ContactFormType[],
  },
};

export type FETCH_CONTACT_FORM_FAILURE_ACTION = {
  type: 'FETCH_CONTACT_FORM_FAILURE'
};

export type POST_CONTACT_FORM_START_ACTION = {
  type: 'POST_CONTACT_FORM_START',
  payload: {
    formData: FormDataType,
    id: number
  },
};

export type POST_CONTACT_FORM_SUCCESS_ACTION = {
  type: 'POST_CONTACT_FORM_SUCCESS',
};

export type POST_CONTACT_FORM_FAILURE_ACTION = {
  type: 'POST_CONTACT_FORM_FAILURE',
};

export type Action =
  | FETCH_CONTACT_FORM_START_ACTION
  | FETCH_CONTACT_FORM_SUCCESS_ACTION
  | FETCH_CONTACT_FORM_FAILURE_ACTION
  | POST_CONTACT_FORM_START_ACTION
  | POST_CONTACT_FORM_SUCCESS_ACTION
  | POST_CONTACT_FORM_FAILURE_ACTION;

export const fetchContactForm = (): FETCH_CONTACT_FORM_START_ACTION => ({
  type: FETCH_CONTACT_FORM_START,
});

export const fetchContactFormSuccess = (contactForm: ContactFormType[]): FETCH_CONTACT_FORM_SUCCESS_ACTION => ({
  type: FETCH_CONTACT_FORM_SUCCESS,
  payload: { contactForm },
});

export const fetchContactFormFailure = (): FETCH_CONTACT_FORM_FAILURE_ACTION => ({
  type: FETCH_CONTACT_FORM_FAILURE,
});

export const postContactForm = (formData: FormDataType, id: number): POST_CONTACT_FORM_START_ACTION => ({
  type: POST_CONTACT_FORM_START,
  payload: {
    formData,
    id,
  },
});

export const postContactFormSuccess = (): POST_CONTACT_FORM_SUCCESS_ACTION => ({
  type: POST_CONTACT_FORM_SUCCESS,
});

export const postContactFormFailure = (): POST_CONTACT_FORM_FAILURE_ACTION => ({
  type: POST_CONTACT_FORM_FAILURE,
});
