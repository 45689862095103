// @flow
import type { RawMenuResponse, MenuElementType } from 'types/MenuElement';
import { nullMenuElement } from 'types/MenuElement';
import { topBarMenuApiName, headerMenuApiName, footerMenuApiName } from 'constants/menuConstants';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertMenusFromApi(response: RawMenuResponse): {
	topBar: Array<MenuElementType>,
	header: Array<MenuElementType>,
	footer: Array<MenuElementType>,
} {
	const topBar = response.find((element) => element.slug === topBarMenuApiName);
	const header = response.find((element) => element.slug === headerMenuApiName);
	const footer = response.find((element) => element.slug === footerMenuApiName);

	const arrayTopBar = topBar ? topBar.items.map((item) => mergeIntoDefault(nullMenuElement, item)) : [];
	const arrayHeader = header ? header.items.map((item) => mergeIntoDefault(nullMenuElement, item)) : [];
	const arrayFooter = footer ? footer.items.map((item) => mergeIntoDefault(nullMenuElement, item)) : [];

	return {
		topBar: arrayTopBar,
		header: arrayHeader,
		footer: arrayFooter,
	};
}
