// @flow
import type { SingleMyKit } from 'types/Kits';

// DELETE ACTIONS
export const DELETE_KIT_VARIATION = 'DELETE_KIT_VARIATION';
export const DELETE_KIT_VARIATION_SUCCESS = 'DELETE_KIT_VARIATION_SUCCESS';
export const DELETE_KIT_VARIATION_FAILURE = 'DELETE_KIT_VARIATION_FAILURE';
export const RESET_DELETE_KIT_VARIATIONS_STATUS = 'RESET_DELETE_KIT_VARIATIONS_STATUS';
// GET KIT LIST ACTIONS
export const FETCH_KITS_VARIATIONS_LIST_START = 'FETCH_KITS_VARIATIONS_LIST_START';
export const FETCH_KITS_VARIATIONS_LIST_SUCCESS = 'FETCH_KITS_VARIATIONS_LIST_SUCCESS';
export const FETCH_KITS_VARIATIONS_LIST_FAILURE = 'FETCH_KITS_VARIATIONS_LIST_FAILURE';
// GET KITS BY STRUCTURE ACTIONS
export const FETCH_KITS_BY_STRUCTURE_START = 'FETCH_KITS_BY_STRUCTURE_START';
export const FETCH_KITS_BY_STRUCTURE_SUCCESS = 'FETCH_KITS_BY_STRUCTURE_SUCCESS';
export const FETCH_KITS_BY_STRUCTURE_FAILURE = 'FETCH_KITS_BY_STRUCTURE_FAILURE';
export const RESET_KITS_BY_STRUCTURE = 'RESET_KITS_BY_STRUCTURE';
// GET A SINGLE KIT ACTIONS
export const FETCH_SINGLE_KIT_VARIATION_START = 'FETCH_SINGLE_KIT_VARIATION_START';
export const FETCH_SINGLE_KIT_VARIATION_SUCCESS = 'FETCH_SINGLE_KIT_VARIATION_SUCCESS';
export const FETCH_SINGLE_KIT_VARIATION_FAILURE = 'FETCH_SINGLE_KIT_VARIATION_FAILURE';
export const RESET_SINGLE_KIT_VARIATION = 'RESET_SINGLE_KIT_VARIATION';
// POST ACTIONS
export const POST_KIT_VARIATION = 'POST_KIT_VARIATION';
export const POST_KIT_VARIATION_SUCCESS = 'POST_KIT_VARIATION_SUCCESS';
export const POST_KIT_VARIATION_FAILURE = 'POST_KIT_VARIATION_FAILURE';
export const RESET_POST_KIT_VARIATIONS_STATUS = 'RESET_POST_KIT_VARIATIONS_STATUS';

// DELETE ACTIONS
export type DELETE_KIT_VARIATION_ACTION = {
	type: 'DELETE_KIT_VARIATION',
	payload: {
		structureid: number,
		kitorig: number,
	},
};

export type DELETE_KIT_VARIATION_FAILURE_ACTION = {
	type: 'DELETE_KIT_VARIATION_FAILURE',
};

export type DELETE_KIT_VARIATION_SUCCESS_ACTION = {
	type: 'DELETE_KIT_VARIATION_SUCCESS',
};

export type RESET_DELETE_KIT_VARIATION_STATUS_ACTION = {
	type: 'RESET_DELETE_KIT_VARIATIONS_STATUS',
};

// GET KIT LIST ACTIONS
export type FETCH_KITS_VARIATIONS_LIST_START_ACTION = {
	type: 'FETCH_KITS_VARIATIONS_LIST_START',
	payload: {
		structureid: number,
	},
};

export type FETCH_KITS_VARIATIONS_LIST_SUCCESS_ACTION = {
	type: 'FETCH_KITS_VARIATIONS_LIST_SUCCESS',
	payload: {
		myKitList: SingleMyKit[],
	},
};

export type FETCH_KITS_VARIATIONS_LIST_FAILURE_ACTION = {
	type: 'FETCH_KITS_VARIATIONS_LIST_FAILURE',
};

export type RESET_KITS_BY_STRUCTURE_ACTION = {
	type: 'RESET_KITS_BY_STRUCTURE',
};

// GET KITS BY STRUCTURE ACTIONS
export type FETCH_KITS_BY_STRUCTURE_START_ACTION = {
	type: 'FETCH_KITS_BY_STRUCTURE_START',
	payload: {
		structureid: number,
		kitadmin?: boolean,
		userstructureid?: number,
	},
};

export type FETCH_KITS_BY_STRUCTURE_SUCCESS_ACTION = {
	type: 'FETCH_KITS_BY_STRUCTURE_SUCCESS',
	payload: {
		kitsByStructure: SingleMyKit[],
	},
};

export type FETCH_KITS_BY_STRUCTURE_FAILURE_ACTION = {
	type: 'FETCH_KITS_BY_STRUCTURE_FAILURE',
};

// GET A SINGLE KIT ACTIONS
export type FETCH_SINGLE_KIT_VARIATION_START_ACTION = {
	type: 'FETCH_SINGLE_KIT_VARIATION_START',
	payload: {
		structureid: number,
		kitorig: number,
	},
};

export type FETCH_SINGLE_KIT_VARIATION_SUCCESS_ACTION = {
	type: 'FETCH_SINGLE_KIT_VARIATION_SUCCESS',
	payload: {
		singleMyKit: SingleMyKit,
	},
};

export type FETCH_SINGLE_KIT_VARIATION_FAILURE_ACTION = {
	type: 'FETCH_SINGLE_KIT_VARIATION_FAILURE',
};

export type RESET_SINGLE_KIT_VARIATION_ACTION = {
	type: 'RESET_SINGLE_KIT_VARIATION',
};

// POST ACTIONS
export type POST_KIT_VARIATION_ACTION = {
	type: 'POST_KIT_VARIATION',
	payload: {
		structureid: number,
		kitorig: number,
		change?: any,
	},
};

export type POST_KIT_VARIATION_FAILURE_ACTION = {
	type: 'POST_KIT_VARIATION_FAILURE',
};

export type POST_KIT_VARIATION_SUCCESS_ACTION = {
	type: 'POST_KIT_VARIATION_SUCCESS',
	payload: {
		kitVariation: any,
	},
};

export type RESET_POST_KIT_VARIATION_STATUS_ACTION = {
	type: 'RESET_POST_KIT_VARIATIONS_STATUS',
};

// ACTIONS' TYPES
export type Action =
	| DELETE_KIT_VARIATION_ACTION
	| DELETE_KIT_VARIATION_FAILURE_ACTION
	| DELETE_KIT_VARIATION_SUCCESS_ACTION
	| RESET_DELETE_KIT_VARIATION_STATUS_ACTION
	| FETCH_KITS_VARIATIONS_LIST_START_ACTION
	| FETCH_KITS_VARIATIONS_LIST_SUCCESS_ACTION
	| FETCH_KITS_VARIATIONS_LIST_FAILURE_ACTION
	| FETCH_KITS_BY_STRUCTURE_START_ACTION
	| FETCH_KITS_BY_STRUCTURE_SUCCESS_ACTION
	| FETCH_KITS_BY_STRUCTURE_FAILURE_ACTION
	| RESET_KITS_BY_STRUCTURE_ACTION
	| FETCH_SINGLE_KIT_VARIATION_START_ACTION
	| FETCH_SINGLE_KIT_VARIATION_SUCCESS_ACTION
	| FETCH_SINGLE_KIT_VARIATION_FAILURE_ACTION
	| RESET_SINGLE_KIT_VARIATION_ACTION
	| POST_KIT_VARIATION_ACTION
	| POST_KIT_VARIATION_FAILURE_ACTION
	| POST_KIT_VARIATION_SUCCESS_ACTION
	| RESET_POST_KIT_VARIATION_STATUS_ACTION;

// DELETE ACTIONS
export function deleteKitVariation(structureid: number, kitorig: number): DELETE_KIT_VARIATION_ACTION {
	return {
		type: DELETE_KIT_VARIATION,
		payload: {
			structureid,
			kitorig,
		},
	};
}

export function deleteKitVariationSuccess(): DELETE_KIT_VARIATION_SUCCESS_ACTION {
	return {
		type: DELETE_KIT_VARIATION_SUCCESS,
	};
}

export function deleteKitVariationError(error: string): DELETE_KIT_VARIATION_FAILURE_ACTION {
	return {
		type: DELETE_KIT_VARIATION_FAILURE,
		payload: { error },
	};
}

export function resetDeleteKitVariationStatus(): RESET_DELETE_KIT_VARIATION_STATUS_ACTION {
	return {
		type: RESET_DELETE_KIT_VARIATIONS_STATUS,
	};
}

// GET KIT LIST ACTIONS
export function fetchKitsVariations(
	structureid: number,
	kitadmin?: boolean,
	userstructureid?: number,
): FETCH_KITS_VARIATIONS_LIST_START_ACTION {
	return {
		type: FETCH_KITS_VARIATIONS_LIST_START,
		payload: { structureid, kitadmin, userstructureid },
	};
}

export function kitsVariationsFetched(myKitList: SingleMyKit[]): FETCH_KITS_VARIATIONS_LIST_SUCCESS_ACTION {
	return {
		type: FETCH_KITS_VARIATIONS_LIST_SUCCESS,
		payload: { myKitList },
	};
}

export function kitsVariationsFetchError(error: string): FETCH_KITS_VARIATIONS_LIST_FAILURE_ACTION {
	return {
		type: FETCH_KITS_VARIATIONS_LIST_FAILURE,
		payload: { error },
	};
}

// GET KITS BY STRUCTURE ACTIONS
export function fetchKitsByStructure(
	structureid: number,
	kitadmin: boolean,
	userstructureid: number,
): FETCH_KITS_BY_STRUCTURE_START_ACTION {
	return {
		type: FETCH_KITS_BY_STRUCTURE_START,
		payload: { structureid, kitadmin, userstructureid },
	};
}

export function kitsByStructureFetched(kitsByStructure: SingleMyKit[]): FETCH_KITS_BY_STRUCTURE_SUCCESS_ACTION {
	return {
		type: FETCH_KITS_BY_STRUCTURE_SUCCESS,
		payload: { kitsByStructure },
	};
}

export function kitsByStructureFetchError(error: string): FETCH_KITS_BY_STRUCTURE_FAILURE_ACTION {
	return {
		type: FETCH_KITS_BY_STRUCTURE_FAILURE,
		payload: { error },
	};
}

export function resetKitsByStructure(): RESET_KITS_BY_STRUCTURE_ACTION {
	return {
		type: RESET_KITS_BY_STRUCTURE,
	};
}

// GET A SINGLE KIT ACTIONS
export function fetchSingleKitVariation(structureid: number, kitorig: number): FETCH_SINGLE_KIT_VARIATION_START_ACTION {
	return {
		type: FETCH_SINGLE_KIT_VARIATION_START,
		payload: { structureid, kitorig },
	};
}

export function singleKitVariationFetched(singleMyKit: SingleMyKit): FETCH_SINGLE_KIT_VARIATION_SUCCESS_ACTION {
	return {
		type: FETCH_SINGLE_KIT_VARIATION_SUCCESS,
		payload: { singleMyKit },
	};
}

export function singleKitVariationFetchError(error: string): FETCH_SINGLE_KIT_VARIATION_FAILURE_ACTION {
	return {
		type: FETCH_SINGLE_KIT_VARIATION_FAILURE,
		payload: { error },
	};
}

export function resetSingleKitVariation(): RESET_SINGLE_KIT_VARIATION_ACTION {
	return {
		type: RESET_SINGLE_KIT_VARIATION,
	};
}

// POST ACTIONS
export function postKitVariation(structureid: number, kitorig: number, change?: any): POST_KIT_VARIATION_ACTION {
	return {
		type: POST_KIT_VARIATION,
		payload: {
			structureid,
			kitorig,
			change,
		},
	};
}

export function postKitVariationSuccess(kitVariation: any): POST_KIT_VARIATION_SUCCESS_ACTION {
	return {
		type: POST_KIT_VARIATION_SUCCESS,
		payload: { kitVariation },
	};
}

export function postKitVariationError(error: string): POST_KIT_VARIATION_FAILURE_ACTION {
	return {
		type: POST_KIT_VARIATION_FAILURE,
		payload: { error },
	};
}

export function resetPostKitVariationStatus(): RESET_POST_KIT_VARIATION_STATUS_ACTION {
	return {
		type: RESET_POST_KIT_VARIATIONS_STATUS,
	};
}
