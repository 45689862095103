// @flow

import { nullSingleCustomizedKit } from 'types/Kits';
import type { RawSingleMyKit, SingleMyKit } from 'types/Kits';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertMyKitListFromApi(rawMyKitList: RawSingleMyKit[]): Array<SingleMyKit> {
	if (!rawMyKitList) {
		return [];
	}

	const convertedMyKitList = rawMyKitList.map((kit) => {
		return convertSingleKitVariationFromApi(kit);
	});

	return convertedMyKitList;
}

export function convertSingleKitVariationFromApi(rawSingleKit: RawSingleMyKit): SingleMyKit {
	if (!rawSingleKit) {
		return nullSingleCustomizedKit;
	}

	return mergeIntoDefault(nullSingleCustomizedKit, {
		...rawSingleKit,
		defaultExemple: rawSingleKit.exemple_defaut
	});
}
