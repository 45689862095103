// @flow
export type NewsletterSubscriptionType = {
  field: string,
  key: string,
  options: Array<string>
};

export const nullNewsletterSubscription: NewsletterSubscriptionType = {
  field: 'field',
  key: 'key',
  options: []
};

export type RawNewsletterSubscriptionType = {
  FieldName: string,
  Key: string,
  DataType: string,
  FieldOptions: Array<string>,
  VisibleInPreferenceCenter: boolean
};

export type NewsletterSubscriptionEntryType = {
  EmailAddress: null | string,
  Name: string,
  CustomFields: Array<{
    Key: string,
    Value: null | string
  }>,
  ConsentToTrack: string,
  action: string
};

export type NewsletterOptinModalContent = {
  campaignMonitorKey: string,
  email: string,
  profil: string,
}