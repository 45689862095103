// @flow
import React from 'react';

import KitCreationInProgress from 'containers/editor/KitCreationInProgressContainer';

type Props = {
	content: any,
	hideModal: () => void,
};

function KitCreationInProgressModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box kit-creation-in-progress">
			<div className="modal__wrapper">
				<KitCreationInProgress content={content} hideModal={hideModal} />
			</div>
		</div>
	);
}

export default KitCreationInProgressModalContent;
