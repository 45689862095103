// @flow
import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';

import HeaderMobile from 'containers/header/HeaderMobileContainer';
import MenuContainer from 'containers/header/MenuContainer';
import TopBar from 'containers/header/TopBarContainer';
import type { RouterProps } from 'types/Router';
import { setBodyStyle, resetBodyStyle } from 'utils/bodyUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = RouterProps;

type ComponentState = {
	isMobileMenuOpen: boolean,
	isMobileMenuSticky: boolean,
	lastScrollPosition: number,
};

class Header extends PureComponent<Props, ComponentState> {
	state: ComponentState = {
		isMobileMenuOpen: false,
		isMobileMenuSticky: false,
		lastScrollPosition: 0,
	};

	onScrollPosition = () => {
		const { lastScrollPosition, isMobileMenuSticky } = this.state;
		const screenWidth = window.innerWidth;
		const scrollPosition = window.scrollY;

		if (screenWidth < 1080) {
			if (lastScrollPosition > scrollPosition && scrollPosition > 250) {
				if (!isMobileMenuSticky) {
					this.setState({ isMobileMenuSticky: true });
				}
			} else {
				if (isMobileMenuSticky) {
					this.setState({ isMobileMenuSticky: false });
				}
			}
		} else {
			if (screenWidth >= 1080 && isMobileMenuSticky) {
				this.setState({ isMobileMenuSticky: false });
			}
		}

		this.setState({ lastScrollPosition: scrollPosition });
	};

	componentDidMount() {
		window.addEventListener('scroll', this.onScrollPosition);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScrollPosition);
	}

	onToggleMenu = () => {
		const { isMobileMenuOpen } = this.state;

		if (isMobileMenuOpen) this.onCloseMenu();
		else this.onOpenMenu();
	};

	onOpenMenu = () => {
		setBodyStyle({
			position: 'fixed',
			width: '100%',
			top: 0,
		});
		this.setState({ isMobileMenuOpen: true });
	};

	onCloseMenu = () => {
		resetBodyStyle();
		this.setState({ isMobileMenuOpen: false });
	};

	render() {
		const { router } = this.props;
		const isFromKitsRoute = router.pathname.includes('kits');
		const { isMobileMenuOpen, isMobileMenuSticky } = this.state;
		const classNamesHeaderWrapper = dynamicClassName('header__wrapper');
		isMobileMenuOpen && classNamesHeaderWrapper.add('is-visible');
		const classNamesHeader = dynamicClassName('header');
		const header_over =
			!router.pathname.match('actualites/.*/.*') &&
			!router.pathname.match('resultats-de-recherche/') &&
			!router.pathname.match('clubs/') &&
			!router.pathname.match(/evenements\/[a-z]/g);
		header_over && classNamesHeader.add('header--over');
		!isMobileMenuSticky && header_over && classNamesHeader.add('header--transparent');
		isFromKitsRoute && classNamesHeader.add('menu--background-color');
		isMobileMenuSticky && classNamesHeader.add('header--fixed');

		return (
			<>
				<header className={classNamesHeader.build()} style={!isFromKitsRoute ? { borderBottom: 'unset' } : {}}>
					<div className={classNamesHeaderWrapper.build()}>
						<TopBar name="Name Firstname very long" />
						<MenuContainer onCloseMenu={this.onCloseMenu} />
					</div>
					<HeaderMobile
						isMenuOpen={isMobileMenuOpen}
						headerOver={header_over && !isMobileMenuSticky}
						onToggleMenu={this.onToggleMenu}
						onCloseMenu={this.onCloseMenu}
					/>
				</header>
			</>
		);
	}
}

export default (withRouter(Header): any);
