// @flow

// Pages
type PageSeoConstantType = {
	asPath: string,
	title: string,
};

export function getCurrentPageSeoConstant(currentPath: string): boolean | PageSeoConstantType {
	const seoConstant = PAGE_SEO_CONSTANTS.find((object) => object.asPath === currentPath);

	if (!!seoConstant) {
		return seoConstant;
	}

	return false;
}

const PAGE_SEO_CONSTANTS = [
	{
		asPath: '/',
		title: 'Mes kits com - Homepage',
	},
	{
		asPath: '/initialisation',
		title: 'Mes kits com - Gestion des kits',
	},
	{
		asPath: '/initialisation/deleguer',
		title: 'Mes kits com - Délégation de la gestion des kits',
	},
	{
		asPath: '/kits/tableau-de-bord',
		title: 'Mes kits com - Tableau de bord',
	},
	{
		asPath: '/kits/mes-kits',
		title: 'Mes kits com - Mes kits',
	},
	{
		asPath: '/kits/modeles',
		title: 'Mes kits com - Modèles de kit',
	},
	{
		asPath: '/kits/centre-d-aide',
		title: "Mes kits com - Centre d'aide",
	},
	{
		asPath: '/kits/administration',
		title: 'Mes kits com - Administration',
	},
	{
		asPath: '/editeur',
		title: 'Mes kits com - Editeur',
	},
];

// Pop-in
type PopInSeoConstantType = {
	asPath: string,
	modalObject: string,
	title: string,
};

export function getCurrentPopInSeoConstant(asPath: string, modalObject: string): boolean | PopInSeoConstantType {
	const seoConstant = POP_IN_SEO_CONSTANTS.find(
		(object) => object.modalObject === modalObject && (object.asPath === asPath || !object.asPath),
	);

	if (!!seoConstant) {
		return seoConstant;
	}

	return false;
}

const POP_IN_SEO_CONSTANTS = [
	{
		asPath: '/editeur',
		modalObject: 'CHOOSE_KIT_FORMATS',
		title: 'Ajout de format', // ok
	},
	{
		asPath: '/kits/tableau-de-bord',
		modalObject: 'CHOOSE_KIT_FORMATS',
		title: 'Choix des formats à personnaliser', // ok
	},
	{
		asPath: '',
		modalObject: 'CREATION_REQUEST',
		title: 'Accéder aux kits de communication', // ok
	},
	{
		asPath: '',
		modalObject: 'DELETE_ALL_ATTACHMENTS_CONFIRMATION',
		title: 'Suppression de tous les calques', // ok
	},
	{
		asPath: '',
		modalObject: 'DELETE_ATTACHMENT_CONFIRMATION',
		title: "Suppression d'un calque", // ok
	},
	{
		asPath: '',
		modalObject: 'DELETE_KIT',
		title: "Suppression d'un kit", // ok
	},
	{
		asPath: '',
		modalObject: 'DISPLAY_PREVIEW',
		title: "Visualisation d'un exemple", // la fonction de visualisation de l'exemple a été supprimée
	},
	{
		asPath: '',
		modalObject: 'DOWNLOAD_KIT_SUCCESS',
		title: 'Kit téléchargé', // pas utilisée
	},
	{
		asPath: '',
		modalObject: 'FAILURE',
		title: 'Erreur', // récupérer content.title si existe
	},
	{
		asPath: '',
		modalObject: 'HANDLE_DASHBOARD_REDIRECTION_FROM_EDITOR',
		title: 'Redirection vers le tableau de bord', // récupérer content.title si existe
		// ok
	},
	{
		asPath: '',
		modalObject: 'KIT_CREATION_IN_PROGRESS',
		title: 'Kit en cours de création...', // récupérer content.title si existe -
		// ok pour chargement kit, pour dl dans l'éditeur, pour dl depuis une carte
	},
	{
		asPath: '',
		modalObject: 'LOGOUT_ALERT',
		title: 'Déconnexion alerte', // ok
	},
	{
		asPath: '',
		modalObject: 'LOGOUT_SUCCESS',
		title: 'Déconnexion succès', // récupérer content.title si existe
	},
	{
		asPath: '',
		modalObject: 'NEWSLETTER_MISSING_OPT',
		title: 'Newsletter - Accord conditions FFR manquant', // ok
	},
	{
		asPath: '',
		modalObject: 'RE_ASK_MAGIC_LINK',
		title: 'Lien magique', // récupérer content.title si existe
	},
	{
		asPath: '',
		modalObject: 'REMOVE_KIT_FORMAT',
		title: "Suppression d'un format", // ok
	},
	{
		asPath: '',
		modalObject: 'RENAME_MY_KIT',
		title: 'Renommer mon kit', // ok
	},
	{
		asPath: '',
		modalObject: 'SCREEN_TOO_SMALL',
		title: 'Service disponible sur ordinateurs et tablettes', // ok
	},
	{
		asPath: '',
		modalObject: 'SUCCESS',
		title: 'Succès', // récupérer content.title si existe
	},
];
