/* eslint-disable import/no-anonymous-default-export */
// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import NewsletterApi from 'api/newsletter/NewsletterApi';
import { convertObjectToUrlEncoded } from 'utils/objectUtils';

import {
	FETCH_NEWSLETTER_OPTIONS_START,
	newsletterOptionsFetched,
	newsletterOptionsFetchError,
	SUBSCRIBE_NEWSLETTER_START,
	newsletterSubscribed,
	newsletterSubscribeError,
} from 'actions/newsletterSubscriptionActions';
import { displayModal } from 'actions/modalActions';
import type {
	FETCH_NEWSLETTER_OPTIONS_START_ACTION,
	SUBSCRIBE_NEWSLETTER_START_ACTION,
} from 'actions/newsletterSubscriptionActions';

export default function (apiExecutor: ApiExecutorType): () => GeneratorType {
	const newsletterSubscriptionApi = new NewsletterApi(apiExecutor);

	return function* newsletterSaga(): GeneratorType {
		yield takeLatest(FETCH_NEWSLETTER_OPTIONS_START, fetchOptions);
		yield takeLatest(SUBSCRIBE_NEWSLETTER_START, subscribeToNewsletter);
	};

	function* fetchOptions(action: FETCH_NEWSLETTER_OPTIONS_START_ACTION): Saga<void> {
		try {
			const { campaignMonitorKey } = action.payload;
			const options = yield call(newsletterSubscriptionApi.fetchOptions, campaignMonitorKey);
			yield put(newsletterOptionsFetched(options));
		} catch (e) {
			yield put(newsletterOptionsFetchError());
		}
	}

	function* subscribeToNewsletter(action: SUBSCRIBE_NEWSLETTER_START_ACTION): Saga<void> {
		const failureWording = {
			title: "Nous avons rencontré un problème lors de l'inscription",
			text: 'Merci de vérifier les informations envoyées !',
		};

		try {
			const { subscription, campaignMonitorKey, typeNewsletter } = action.payload;
			const { Value: analyticsData } = action.payload.subscription.CustomFields[0];

			const request = yield call(
				newsletterSubscriptionApi.subscribeToNewsletter,
				convertObjectToUrlEncoded(subscription),
				campaignMonitorKey,
				typeNewsletter,
			);

			if (request.error) {
				// Code 208 -> Email Address exists in unconfirmed list. Subscriber is not added.
				if (request.Code === 208) {
					return yield put(displayModal('FAILURE', { title: 'Newsletter erreur' }, true));
				}

				return yield put(
					displayModal(
						'RE_ASK_MAGIC_LINK',
						{
							...failureWording,
						},
						true,
					),
				);
			}

			yield put(newsletterSubscribed());
			return yield put(
				displayModal(
					'SUCCESS',
					{
						analyticsData,
						title: 'Inscription validée',
						text: 'Nous avons bien pris en compte votre inscription à la newsletter de la Fédération.',
						profil: subscription.CustomFields?.find((field) => field.Key === 'Jesuis')?.Value || '',
					},
					true,
				),
			);
		} catch (e) {
			yield put(
				displayModal(
					'RE_ASK_MAGIC_LINK',
					{
						...failureWording,
					},
					true,
				),
			);
			return yield put(newsletterSubscribeError());
		}
	}
}
