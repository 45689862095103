/* eslint-disable @next/next/no-img-element */
// @flow
import React, { PureComponent } from 'react';
import Link from 'next/link';

import { dynamicClassName } from 'utils/dynamicClassName';

export type StateProps = {
	logo: string,
	logoOver: string,
};

type Props = {
	onToggleMenu: Function,
	isMenuOpen: boolean,
	headerOver: boolean,
	onCloseMenu: Function,
} & StateProps;

type ComponentState = {
	hasOverlay: boolean,
};

class HeaderMobile extends PureComponent<Props, ComponentState> {
	state: ComponentState = {
		hasOverlay: false,
	};

	setOverlay: (hasOverlay: boolean) => void = (hasOverlay: boolean) => {
		this.setState({ hasOverlay });
	};

	render(): React$Element<React$FragmentType> {
		const { onCloseMenu, isMenuOpen, headerOver, logo, logoOver, onToggleMenu } = this.props;
		const { hasOverlay } = this.state;

		const classNamesIconShowMenu = dynamicClassName('icon icon-menu js-showMenu');
		const classNamesOverlay = dynamicClassName('overlay');
		isMenuOpen && classNamesIconShowMenu.add('is-open');
		hasOverlay && classNamesOverlay.add('is-visible');

		return (
			<>
				<div className="header__mobile">
					<i
						className={classNamesIconShowMenu.build()}
						role="button"
						tabIndex={0}
						onClick={onToggleMenu}
						aria-label="Afficher le menu"
					/>
					<Link href="/">
						<a title="Se rendre sur la page d'accueil" onClick={onCloseMenu}>
							<img src={logo} alt="logo" />
						</a>
					</Link>
				</div>
				<div
					className={classNamesOverlay.build()}
					onClick={onCloseMenu}
					role="button"
					tabIndex={0}
					aria-label="Fermer ce menu"
				/>
			</>
		);
	}
}

export default HeaderMobile;
