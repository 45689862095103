// @flow
import React from 'react';

type Props = {
	content: any,
	hideModal: () => void,
};

function DisplayPreviewModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box newsletter-valid kit-exemple">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<h3 className="mb-1 mt-2 mb-2 ft-h2">Visualisation d&apos;un exemple</h3>
					{/* eslint-disable-next-line @next/next/no-img-element */}
					<img src={content.currentExemple} alt='Visualisation exemple' />
				</div>
			</div>
		</div>
	);
}

export default DisplayPreviewModalContent;
