// @flow
import CookieCutter from 'cookie-cutter';

export const getUserData = (query: any): { structureID: number, magicLink: string } => {
	let structureID = 0;
	let magicLink = '';
	let destination = '/';

	// dans le cas où on est sur le lien magique, on y récupère les infos
	if (query) {
		if (query.magic) {
			magicLink = query.magic;
		}
		if (query.structureID) {
			structureID = parseInt(query.structureID, 10);
		}
	}

	return { structureID, magicLink };
};

export const getMagicLinkCookie = (): { structureIDCookie: number, magicLinkCookie: string, ... } => {
	let structureIDCookie = 0;
	let magicLinkCookie = '';
	const cookies = document.cookie.split(';');
	let magicLinkCookieData = cookies.find((cookie) => cookie.includes('magic'));
	if (typeof magicLinkCookieData !== 'undefined') {
		magicLinkCookieData.split('=')[1];
		structureIDCookie = magicLinkCookieData.split('---')[0]
			? parseInt(magicLinkCookieData.split('---')[0].split('=')[1])
			: 0;
		magicLinkCookie = magicLinkCookieData.split('---')[1] ?? '';
	}

	return { structureIDCookie, magicLinkCookie };
};

/**
 * Deal with cookies on logout
 */
export function handleLogout(emailUser: string) {
	// remove cookie
	CookieCutter.set('magic', '', { expires: new Date(0) });

	// add cookie for success modal
	const now = new Date();
	const expirationDate = now.setDate(now.getSeconds() + 60 * 5);
	CookieCutter.set('logoutSuccessModalCookie', emailUser, { expires: expirationDate });
}
