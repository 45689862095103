// @flow

import { FETCH_MENU_START, FETCH_MENU_SUCCESS, FETCH_MENU_FAILURE } from 'actions/menuActions';
import { kitsCommNav, kitsCommNavForAdmin } from 'constants/menuConstants';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import type { MenuElementType } from 'types/MenuElement';
import type { ParticipateType } from 'types/Participate';
import type { Status } from 'types/Status';

export type State = {
	topBar: Array<MenuElementType>,
	header: Array<MenuElementType>,
	headerForAdmin: Array<MenuElementType>,
	footer: Array<MenuElementType>,
	participate: Array<ParticipateType>,
	status: Status,
};

const initialState: State = {
	topBar: [],
	header: kitsCommNav,
	headerForAdmin: kitsCommNavForAdmin,
	footer: [],
	participate: [],
	status: STATUS_LOADING,
};

function menuReducer(state: State = initialState, action: ActionType): State {
	switch (action.type) {
		case FETCH_MENU_START:
			return {
				...state,
				status: STATUS_LOADING,
			};
		case FETCH_MENU_SUCCESS:
			return {
				...state,
				topBar: action.payload.topBar,
				footer: action.payload.footer,
				participate: action.payload.participate,
				status: STATUS_SUCCESS,
			};
		case FETCH_MENU_FAILURE:
			return {
				...state,
				status: STATUS_FAILURE,
			};
		default:
			return state;
	}
}

export default menuReducer;
