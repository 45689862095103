// @flow
import CookieCutter from 'cookie-cutter';

import {
	ASK_MAGIC_LINK,
	ASK_MAGIC_LINK_SUCCESS,
	ASK_MAGIC_LINK_FAILURE,
	STOCK_MAGIC_LINK,
	FORGOT_MAGIC_LINK,
	STOCK_STRUCTURE_ID,
	GET_MODAL_FOR_NEW_LINK,
} from 'actions/magicLinkActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import type { Status } from 'types/Status';

export type State = {
	magicLink: string,
	structureID: number,
	email: string,
	status: Status,
};

const initialState: State = {
	magicLink: '',
	structureID: 0,
	email: '',
	status: STATUS_LOADING,
};

const magicLinkReducer = (state: State = initialState, action: ActionType): State => {
	switch (action.type) {
		case ASK_MAGIC_LINK:
			return {
				...state,
				status: STATUS_LOADING,
			};
		case ASK_MAGIC_LINK_SUCCESS:
			return {
				...state,
				structureID: parseInt(action.payload.structureID, 10),
				email: action.payload.email,
				status: STATUS_SUCCESS,
			};
		case ASK_MAGIC_LINK_FAILURE:
			return {
				...state,
				status: STATUS_FAILURE,
			};
		case STOCK_MAGIC_LINK:
			return {
				...state,
				magicLink: action.payload.magicLink,
			};
		case FORGOT_MAGIC_LINK:
			return {
				...state,
				magicLink: initialState.magicLink,
				structureID: initialState.structureID,
			};
		case STOCK_STRUCTURE_ID:
			return {
				...state,
				structureID: parseInt(action.payload.structureID, 10),
			};
		case GET_MODAL_FOR_NEW_LINK:
			return {
				...state,
				status: STATUS_LOADING,
			};
		default:
			return state;
	}
};

export default magicLinkReducer;
