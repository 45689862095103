// @flow

import CookieCutter from 'cookie-cutter';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ClubApi from 'api/club/ClubApi';
import { displayModal } from 'actions/modalActions';
import {
	FETCH_CLUB_START,
	POST_CONFIG_START,
	POST_ACTION_START,
	clubFetched,
	clubFetchError,
	postClubConfigSuccess,
	postClubConfigError,
	postClubActionSuccess,
	postClubActionError,
} from 'actions/clubActions';
import { forgotMagicLink } from 'actions/magicLinkActions';
import type { FETCH_CLUB_START_ACTION, POST_CONFIG_START_ACTION, POST_ACTION_START_ACTION } from 'actions/clubActions';

const content = {
	title: 'Votre code a expiré.',
};

export default function saga(onBoardingApiExecutor: ApiExecutorType): () => GeneratorType {
	const clubApi = new ClubApi(onBoardingApiExecutor);
	const getMagicLinkInState = (state) => state.magicLinkState;

	return function* clubSaga(): GeneratorType {
		yield takeLatest(FETCH_CLUB_START, fetchClub);
		yield takeLatest(POST_CONFIG_START, postClubConfig);
		yield takeLatest(POST_ACTION_START, postClubAction);
	};

	function* fetchClub(action: FETCH_CLUB_START_ACTION): Saga<void> {
		const { magicLink } = action.payload;

		try {
			const { id } = action.payload;
			if (parseInt(id, 10) > 0) {
				const {
					code,
					embleme,
					nom,
					configuration,
					email,
					emailOfficiel,
					string_blacklist,
					domaines_blacklist,
					is_admin,
				} = yield call(clubApi.getClub, id, magicLink);
				yield put(
					clubFetched({
						code,
						embleme,
						nom,
						configuration,
						email,
						emailOfficiel,
						string_blacklist,
						domaines_blacklist,
						is_admin,
					}),
				);
			} else {
				CookieCutter.set('magic', '', { expires: new Date(0) });
				yield put(forgotMagicLink());
				yield put(clubFetchError());
			}
		} catch (e) {
			if (e.response.data.code === 'rest_invalid_magic_code') {
				yield put(displayModal('RE_ASK_MAGIC_LINK', content));
			}

			CookieCutter.set('magic', '', { expires: new Date(0) });
			yield put(forgotMagicLink());
			yield put(clubFetchError());
		}
	}

	function* postClubConfig(action: POST_CONFIG_START_ACTION): Saga<void> {
		const { magicLink } = yield select(getMagicLinkInState);

		try {
			const { id, config } = action.payload;
			const {
				code,
				embleme,
				nom,
				configuration,
				email,
				emailOfficiel,
				string_blacklist,
				domaines_blacklist,
				is_admin,
			} = yield call(clubApi.postClubConfig, id, config, magicLink);
			yield put(
				postClubConfigSuccess({
					code,
					embleme,
					nom,
					configuration,
					email,
					emailOfficiel,
					string_blacklist,
					domaines_blacklist,
					is_admin,
				}),
			);
		} catch (e) {
			if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
				yield put(displayModal('RE_ASK_MAGIC_LINK', content));
			}

			// CookieCutter.set('magic', '', { expires: new Date(0) });
			// yield put(forgotMagicLink());
			yield put(postClubConfigError());
		}
	}

	function* postClubAction(action: POST_ACTION_START_ACTION): Saga<void> {
		const { magicLink } = yield select(getMagicLinkInState);

		try {
			const { id, action: actionToSend, email: emailToSend, config } = action.payload;
			const {
				code,
				embleme,
				nom,
				configuration,
				email,
				emailOfficiel,
				string_blacklist,
				domaines_blacklist,
				is_admin,
			} = yield call(clubApi.postClubAction, id, config, actionToSend, emailToSend, magicLink);
			yield put(
				postClubActionSuccess({
					code,
					embleme,
					nom,
					configuration,
					email,
					emailOfficiel,
					string_blacklist,
					domaines_blacklist,
					is_admin,
				}),
			);
		} catch (e) {
			if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
				yield put(displayModal('RE_ASK_MAGIC_LINK', content));
			}

			CookieCutter.set('magic', '', { expires: new Date(0) });
			yield put(forgotMagicLink());
			yield put(postClubActionError(e.message));
		}
	}
}
