// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import MenuApi from 'api/menu/MenuApi';
import { FETCH_MENU_START, menuFetched, menuFetchError } from 'actions/menuActions';

export default function (apiExecutor: ApiExecutorType): () => GeneratorType {
	const menuApi = new MenuApi(apiExecutor);

	return function* menuSaga(): GeneratorType {
		yield takeLatest(FETCH_MENU_START, fetchMenu);
	};

	function* fetchMenu(action): Saga<void> {
		try {
			const { topBar, footer, header, participate } = yield call(menuApi.fetchMenu);

			yield put(menuFetched(topBar, header, footer, participate));
		} catch (e) {
			yield put(menuFetchError());
		}
	}
}
