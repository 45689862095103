// @flow

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { META } from 'constants/settings';
import type { ActionType } from 'types/Actions';
import type { FaviconType, liensRsType } from 'types/Settings';

export type State = {
	actualite_topbar: {
		link: string,
		title: string,
		label: string,
	},
	contacts: {
		adresse: string,
		adresse_map: string,
		telephone: string,
		email: string,
		horaires: string,
	},
	cookies: number,
	description: string,
	domaine_multisite: string,
	favicons: FaviconType,
	google_verification: string,
	liens_rs: liensRsType,
	ligue: string,
	logo: string,
	logo_footer: string,
	logo_transparent: string,
	meta_title_home: string,
	meta_description_home: string,
	newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
	placeholder: string,
	pratiques_clubs: Array<string>,
	title: string,
	url: string,
	url_cgus: string,
	url_mentions_legales: string,
};

const initialState: State = {
	actualite_topbar: {
		link: '',
		title: '',
		label: '',
	},
	contacts: {
		adresse: '',
		adresse_map: '',
		telephone: '',
		email: '',
		horaires: '',
	},
	cookies: 0,
	description: '',
	domaine_multisite: '',
	favicons: {
		'android-192': '',
		'android-256': '',
		'apple-touch-icon-152': '',
		'apple-touch-icon-180': '',
		'favicon-16': '',
		'favicon-32': '',
		'mstile-150': '',
	},
	google_verification: '',
	liens_rs: {
		fb_lien: '',
		instagram_lien: '',
		twitter_lien: '',
		youtube_lien: '',
	},
	ligue: '',
	logo: '',
	logo_footer: '',
	logo_transparent: '',
	meta_title_home: META.TITLE,
	meta_description_home: META.DESCRIPTION,
	newsletter: { type: false },
	placeholder: '',
	pratiques_clubs: [],
	title: '',
	url: '',
	url_cgus: '',
	url_mentions_legales: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: ActionType): State {
	switch (action.type) {
		case FETCH_SETTINGS_SUCCESS:
			return {
				...state,
				actualite_topbar: action.payload.settings.actualite_topbar,
				contacts: action.payload.settings.contacts,
				cookies: action.payload.settings.cookies,
				description: action.payload.settings.description,
				domaine_multisite: action.payload.settings.domaine_multisite,
				favicons: action.payload.settings.favicons,
				liens_rs: action.payload.settings.liens_rs,
				ligue: action.payload.settings.ligue,
				logo: action.payload.settings.logo,
				logo_footer: action.payload.settings.logo_footer,
				logo_transparent: action.payload.settings.logo_transparent,
				meta_title_home: action.payload.settings.meta_title_home,
				meta_description_home: action.payload.settings.meta_description_home,
				newsletter: action.payload.settings.newsletter,
				placeholder: action.payload.settings.placeholder,
				title: action.payload.settings.title,
				url: action.payload.settings.url,
				url_cgus: action.payload.settings.url_cgus,
				url_mentions_legales: action.payload.settings.url_mentions_legales,
			};
		default:
			return state;
	}
}
