// @flow
import CookieCutter from 'cookie-cutter';
import React from 'react';

import Button from 'components/fragments/button/Button';

type Props = {
	content: any,
	hideModal: () => void,
};

function HandleDashboardRedirectionFromEditorModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box newsletter-valid ">
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<div className="circle-icon circle-icon--alert"></div>
					<h3 className="mb-1 mt-2 ft-h2">{content ? content.title : ''}</h3>
					<p className="mb-1 ft-center">{content ? content.text : ''}</p>
					<Button
						label="Sauvegarder"
						onClick={content ? content.handleForceSaving : ''}
						className="mb-2 mt-1 mt-md-2"
						isFull
					/>
				</div>
			</div>
		</div>
	);
}

export default HandleDashboardRedirectionFromEditorModalContent;
