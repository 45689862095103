// @flow
import { withRouter } from 'next/router';
import React from 'react';

import LazyLoad from 'components/fragments/LazyLoad';
import FooterTopContainer from 'containers/footer/FooterTopContainer';
import FooterBottomWithoutSiteMapContainer from 'containers/footer/FooterBottomWithoutSiteMapContainer';

const Footer = () => (
	<footer className="footer">
		<LazyLoad isDarkBlue>
			<FooterTopContainer />
			<FooterBottomWithoutSiteMapContainer />
		</LazyLoad>
	</footer>
);

export default (withRouter(Footer): any);
