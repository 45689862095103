// @flow

export function mergeIntoDefault(defaultParams: Object = {}, params: Object = {}): Object {
	return Object.keys(defaultParams).reduce((result, value) => {
		if (params[value] !== null && params[value] !== undefined && typeof params[value] === typeof defaultParams[value]) {
			result[value] = params[value];
		} else {
			result[value] = defaultParams[value];
		}

		return result;
	}, {});
}

export function promisify(fn: any): any {
	return new Promise((resolve, reject) => {
		try {
			resolve(fn);
		} catch (e) {
			reject(e);
		}
	});
}

export function convertToValidDateFormat(str: string): string {
	return str.replace(/-/g, '/');
}

/**
 * Convertir une date reçue par l'API en une date lisible par Date en javascript
 */
export function convertToJsDate(str: string): string {
	return str.replace(/\//g, '-').replace(' ', 'T') + 'Z';
}

export function convertObjectToUrlEncoded(element: any, key: any, list: any): any {
	var liste = list || [];
	if (typeof element === 'object') {
		for (var idx in element) convertObjectToUrlEncoded(element[idx], key ? key + '[' + idx + ']' : idx, liste);
	} else {
		liste.push(key + '=' + encodeURIComponent(element));
	}
	return liste.join('&');
}

export function areArrayEquals(arr1: Array<any>, arr2: Array<any>): boolean {
	if (!arr1 || !arr2) return false;
	const cleanArray1 = Array.isArray(arr1) ? arr1 : [arr1];
	const cleanArray2 = Array.isArray(arr2) ? arr2 : [arr2];
	if (cleanArray1.length === 0 && cleanArray2.length === 0) return true;
	if (!cleanArray1.every((el) => cleanArray2.includes(el))) return false;
	if (!cleanArray2.every((el) => cleanArray1.includes(el))) return false;

	return true;
}
