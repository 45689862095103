// @flow
import React from 'react';

type Props = {
	content: any,
	hideModal: () => void,
};

function NewsletterMissingOptModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<div className="modal__wrapper">
				<h3 className="mb-2">
					Pour vous inscrire, merci d&apos;accepter les conditions de la Fédération Française de Rugby
				</h3>
				<div className="modal__body modal__body--medium narrow-2 mb-3">
					<div className="circle-icon circle-icon--alert"></div>
					<p></p>
				</div>
			</div>
		</div>
	);
}

export default NewsletterMissingOptModalContent;
