// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { ClubType, ConfigType } from 'types/Club';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function ClubApi(executor: ApiExecutorType): {
	getClub: (id: number, magicLink: string) => Promise<ClubType>,
	postClubAction: (
		structureid: number,
		configuration: ConfigType,
		action: string,
		email: string,
		magicLink: string,
	) => Promise<ClubType>,
	postClubConfig: (structureid: number, configuration: ConfigType, magicLink: string) => Promise<ClubType>,
	...
} {
	return { getClub, postClubConfig, postClubAction };

	function getClub(id: number, magicLink: string): Promise<ClubType> {
		return executor.get(`ffr/v1/onboarding_structure?structureid=${id}`, getOnboardingMagicHeader(magicLink));
	}

	function postClubConfig(structureid: number, configuration: ConfigType, magicLink: string): Promise<ClubType> {
		return executor.post(
			'ffr/v1/onboarding_structure',
			{ structureid, configuration },
			getOnboardingMagicHeader(magicLink),
		);
	}

	function postClubAction(
		structureid: number,
		configuration: ConfigType,
		action: string,
		email: string,
		magicLink: string,
	): Promise<ClubType> {
		return executor.post(
			'ffr/v1/onboarding_structure',
			{
				structureid,
				configuration,
				action,
				email,
			},
			getOnboardingMagicHeader(magicLink),
		);
	}
}

export default ClubApi;
