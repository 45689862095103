// @flow
import React, { Component } from 'react';
import { withRouter } from 'next/router';

import { getCurrentPopInSeoConstant } from 'constants/seoConstants';
import type { RouterProps } from 'types/Router';
import TagManager from 'utils/tagManagerUtils';

import Components from './modalContents/ModalContentList';

export type StateProps = {
	emailUser?: string,
	modalObject?: string,
	content?: Object,
	force?: boolean,
};

export type DispatchProps = {
	forgotMagicLink(): void,
	getModalForNewLink(): void,
	hideModal(): void,
};

type Props = RouterProps & StateProps & DispatchProps;

type State = {
	currentModal: string,
	isOpen: boolean,
	redirect: boolean,
};

class Modal extends Component<Props, State> {
	state: State = {
		currentModal: 'NO_MODAL',
		isOpen: false,
		redirect: false,
	};

	static defaultProps: { content: { ... }, modalObject: string, props: boolean, ... } = {
		modalObject: 'NO_MODAL',
		content: {},
		props: false,
	};

	componentDidMount() {
		this.setState({ currentModal: this.props.modalObject });

		this.handleTracking();
	}

	/**
	 * Avant l'update, vérifier que l'on puisse faire cet update
	 * Une modale ouverte ne peut être remplacée, sauf par la modale 0 ou si on force le changement
	 */
	shouldComponentUpdate(nextProps: Props): boolean {
		const { modalObject, force } = nextProps;
		const { isOpen } = this.state;
		if (isOpen && modalObject !== 'NO_MODAL' && !force) {
			return false;
		}
		return true;
	}

	/**
	 * Au changement de numéro de modal,
	 * si le numéro est à 0, on indique qu'elle est fermée, sinon ouverte
	 */
	componentDidUpdate(prevProps: Props) {
		if (prevProps.modalObject !== this.props.modalObject) {
			if (this.props.modalObject === 'NO_MODAL') {
				this.setState({ currentModal: this.props.modalObject, isOpen: false });
			} else {
				this.setState({ currentModal: this.props.modalObject, isOpen: true }, this.handleTracking());
			}
		}
	}

	handleTracking: () => void = () => {
		const { content, modalObject, router } = this.props;

		if (!modalObject) {
			return;
		}

		const seoConstant = getCurrentPopInSeoConstant(router.asPath, modalObject);

		if (!seoConstant) {
			return;
		}

		const title =
			seoConstant.asPath === router.asPath
				? seoConstant.title
				: content && content.title
				? content.title
				: seoConstant.title;

		TagManager.handleTagEvent({
			event: 'page_view',
			page_path: router.asPath,
			page_title: `Popin - ${title}`,
		});
	};

	render(): null | React$Element<'section'> {
		const { content, emailUser, forgotMagicLink, getModalForNewLink, hideModal } = this.props;
		const { currentModal } = this.state;
		// https://stackoverflow.com/a/48269720
		const ComponentToRender = Components[currentModal];

		if (!ComponentToRender) {
			return null;
		}

		let modalContent = content;
		if (currentModal === 'LOGOUT_ALERT') {
			modalContent = {
				...content,
				forgotMagicLink,
				emailUser,
			};
		} else if (currentModal === 'RE_ASK_MAGIC_LINK') {
			modalContent = {
				...content,
				forgotMagicLink,
				emailUser,
			};
		}

		return (
			<section className="modal visible">
				<div className="container">
					<div className="modal__overlay"></div>
					<ComponentToRender hideModal={hideModal} content={modalContent} />
				</div>
			</section>
		);
	}
}

export default (withRouter(Modal): any);
