/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type DefaultProps = {
	placeholder?: string,
	options?: Array<{ label: string, value: string }>,
	className?: string,
	isDark?: boolean,
	isLight?: boolean,
	onChange?: (value: string) => void,
	hasError?: boolean,
	errorMessage?: string,
	defaultValue?: string | null,
};

type Props = DefaultProps;

type State = {
	isTouched: boolean,
};

class SelectInput extends PureComponent<Props, State> {
	static defaultProps: DefaultProps = {
		placeholder: 'placeholder',
		options: [
			{
				value: 'option1',
				label: 'option1',
			},
		],
		className: '',
		defaultValue: 'default',
		isDark: false,
		isLight: false,
		onChange: () => {},
		hasError: false,
		errorMessage: undefined,
	};

	state: State = {
		isTouched: false,
	};

	handleValueChanged: (e: any) => void = (e: any) => {
		const { isTouched } = this.state;
		const { onChange } = this.props;

		const { value } = e.target;

		if (!isTouched) this.setState({ isTouched: true });
		if (onChange) onChange(value);
	};

	render(): React$Element<React$FragmentType> {
		const { isDark, hasError, className, isLight, defaultValue, options, placeholder, errorMessage } = this.props;
		const { isTouched } = this.state;

		const classNames = dynamicClassName('select');
		className && classNames.add(className);

		isDark && classNames.add('is-dark');
		isLight && classNames.add('select--light');
		hasError && classNames.add('has-error');

		const defaultVal = defaultValue || 'default';
		(isTouched || defaultVal !== 'default') && classNames.add('is-selected');

		return (
			<>
				<div className={classNames.build()}>
					<select defaultValue={defaultVal} onChange={this.handleValueChanged}>
						<option disabled value="default">
							{placeholder}
						</option>
						{this.renderOptions(options)}
					</select>
				</div>
				{hasError && errorMessage && <ErrorMessage forceDisplay message={errorMessage} />}
			</>
		);
	}

	renderOptions: (options: any) => any = (options: any) => {
		return options.map((option) => (
			<option key={`${option.value}-${option.label}`} value={option.value}>
				{option.label}
			</option>
		));
	};
}

export default SelectInput;
