/// @flow
import React, { PureComponent } from 'react';
import Link from 'next/link';

import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
	label?: string,
	icon?: string,
	counter?: number,
	isRounded?: boolean,
	isKhaki?: boolean,
	isGrey?: boolean,
	isBlue?: boolean,
	isBlueAlt?: boolean,
	isAzure?: boolean,
	isRed?: boolean,
	isSmall?: boolean,
	link?: string,
	noIcon?: boolean,
};

class Label extends PureComponent<Props> {
	props: Props;

	render(): React$Element<any> | React$Element<'span'> {
		const { isRounded, isKhaki, isGrey, isBlue, isBlueAlt, isAzure, isRed, icon, isSmall, counter, link, noIcon } =
			this.props;
		const classNamesLabel = dynamicClassName('label');
		const classNamesIcon = dynamicClassName('icon');
		let titleSEO;

		!noIcon && icon && !this.props.label && classNamesLabel.add('label--icon');
		isRounded && classNamesLabel.add('label--rounded');
		isKhaki && classNamesLabel.add('label--khaki');
		isGrey && classNamesLabel.add('label--grey');
		isBlue && classNamesLabel.add('label--blue');
		isBlueAlt && classNamesLabel.add('label--blue-alt');
		isSmall && classNamesLabel.add('label--small');
		isAzure && classNamesLabel.add('label--azure');
		isRed && classNamesLabel.add('label--red');
		icon && classNamesIcon.add(icon);

		if (!link) {
			return (
				<span className={classNamesLabel.build()}>
					{this.props.label && this.props.label}
					{icon && <i className={classNamesIcon.build()} />}
					{counter && counter}
				</span>
			);
		}

		if (this.props.label) {
			titleSEO = `Voir tous les contenus du label ${this.props.label}`;
		} else {
			titleSEO = 'Voir tous les contenus du label';
		}

		return (
			<Link href={link}>
				<a title={titleSEO} className={classNamesLabel.build()}>
					{this.props.label && this.props.label}
					{icon && <i className={classNamesIcon.build()} />}
					{counter && counter}
				</a>
			</Link>
		);
	}
}

export default Label;
