// @flow
import { FETCH_KITS_START, FETCH_KITS_SUCCESS, FETCH_KITS_FAILURE } from 'actions/kitsActions';
import { FORGOT_MAGIC_LINK } from 'actions/magicLinkActions';
import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import type { SingleKit } from 'types/Kits';
import type { Status } from 'types/Status';
import { getPublicAssets } from 'utils/assetsUtils';

export type State = {
	kitList: SingleKit[],
	kitListStatus: Status,
};

const initialState: State = {
	kitList: [],
	kitListStatus: STATUS_DEFAULT,
};

export default function reducer(state: State = initialState, action: ActionType): State {
	switch (action.type) {
		case FETCH_KITS_START:
			return {
				...state,
				kitListStatus: STATUS_LOADING,
			};
		case FETCH_KITS_SUCCESS:
			return {
				...state,
				kitListStatus: STATUS_SUCCESS,
				kitList: action.payload.kitList,
			};
		case FETCH_KITS_FAILURE:
			return {
				...state,
				kitListStatus: STATUS_FAILURE,
				kitList: [],
			};
		case FORGOT_MAGIC_LINK:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
