import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'reducers';
import rootSaga from 'sagas/root';

import { getOnboardingBaseUrl } from 'constants/api';
import { parseCookies } from 'utils/cookiesUtils';

/**
 * add redux-devtools-extension as middleware only on dev env
 */
function bindMiddleware(middleware) {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}

	return applyMiddleware(...middleware);
}

const reducer = (reqCookies) => (state, action) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state,
			...action.payload,
		};

		// client-side
		if (typeof window !== 'undefined') {
			// gestion des cookies
			const cookies = document.cookie.split(';');
			const acceptCookie = cookies.find((cookie) => cookie.includes('cookiesAccepted'));
			const acceptCookieDateString = cookies.find((cookie) => cookie.includes('cookiesAcceptedDate'));
			const showAlertInfoCookie = cookies.find((cookie) => cookie.includes('showAlertInfo'));
			if (acceptCookieDateString) {
				nextState.appState.acceptCookieDate = new Date(acceptCookieDateString);
				nextState.appState.showCookieInfo = false;
				nextState.appState.acceptCookie = !!acceptCookie && acceptCookie.includes('true');
			} else {
				nextState.appState.showCookieInfo = true;
			}

			// magicLink
			let magicLinkCookie = cookies.find((cookie) => cookie.includes('magic'));
			if (typeof magicLinkCookie !== 'undefined') {
				magicLinkCookie.split('=')[1];
				const structureID = magicLinkCookie.split('---')[0]
					? parseInt(magicLinkCookie.split('---')[0].split('=')[1])
					: 0;
				const magicLink = magicLinkCookie.split('---')[1] ?? '';
				nextState.magicLinkState.structureID = structureID;
				nextState.magicLinkState.magicLink = magicLink;
			}
		}
		// server-side
		else if (reqCookies) {
			nextState.appState.acceptCookieDate = new Date(reqCookies.cookiesAcceptedDate);
			nextState.appState.showCookieInfo = !reqCookies.cookiesAcceptedDate;
			nextState.appState.acceptCookie = !!reqCookies.cookiesAccepted;

			// magicLink
			// let magicLinkCookie = reqCookies.magic;
			// if (typeof magicLinkCookie !== 'undefined') {
			// 	magicLinkCookie.split('=')[1];
			// 	const structureID = magicLinkCookie.split('---')[0] ? parseInt(magicLinkCookie.split('---')[0].split('=')[1]) : 0;
			// 	const magicLink = magicLinkCookie.split('---')[1] ?? '';
			// 	nextState.magicLinkState.structureID = structureID;
			// 	nextState.magicLinkState.magicLink = magicLink;
			// }
		}

		return nextState;
	} else {
		return rootReducer(state, action);
	}
};

/**
 * to wait some defined actions and then keep on with the code when they end
 */
function createWaiterFeature() {
	const waiters = [];

	return {
		initStoreWithWaiterFeature: (store) => {
			store.once = (type) => {
				return new Promise((resolve, reject) => {
					const waiter = (currentType) => {
						if (typeof type === 'string' ? currentType === type : type.includes(currentType)) {
							const waiterIndex = waiters.findIndex((e) => e === waiter);

							waiters.splice(waiterIndex, 1);

							resolve();
						}
					};

					waiters.push(waiter);
				});
			};
		},
		waiterMiddleware: () => (next) => (action) => {
			next(action);

			if (waiters.length) {
				waiters.forEach((waiter) => {
					waiter(action.type);
				});
			}
		},
	};
}

/**
 * create the store
 */
function makeStore(ctx) {
	let host;
	if (typeof window !== 'undefined') {
		host = window.location.host;
	} else {
		host = ctx && ctx.req && ctx.req.headers.host;
	}

	const sagaMiddleware = createSagaMiddleware();
	const { initStoreWithWaiterFeature, waiterMiddleware } = createWaiterFeature();

	const store = createStore(
		reducer(ctx?.req && parseCookies(ctx?.req)),
		bindMiddleware([sagaMiddleware, waiterMiddleware]),
	);

	initStoreWithWaiterFeature(store);

	store.sagaTask = sagaMiddleware.run(() => rootSaga(getOnboardingBaseUrl(host)));

	return store;
}

/**
 * wrapper we add to every single page to get store
 */
const wrapper = createWrapper(makeStore, { debug: false });

export default wrapper;
