// @flow
import CookieCutter from 'cookie-cutter';
import React, { useEffect } from 'react';

import Button from 'components/fragments/button/Button';
import TagManager from 'utils/tagManagerUtils';

type Props = {
	content: any,
	hideModal: () => void,
};

function SuccessModalContent({ content, hideModal }: Props): React$Element<'div'> {
	useEffect(() => {
		if (content.title === 'Inscription validée') {
			TagManager.handleTagEvent({
				event: 'newsletter_subscription',
				newsletter_option: content.profil ?? '',
			});
		}
	}, [content.title, content.profil]);

	const handleClick = () => {
		// Logout modal
		if (content.title === 'Vous êtes bien déconnecté.') {
			this.handleLogout();
		}
		// other modals
		else {
			hideModal();
		}
	};

	const handleLogout = () => {
		CookieCutter.set('logoutSuccessModalCookie', ``, { expires: new Date(0) });

		setTimeout(() => {
			hideModal();
		}, 1000);
	};

	return (
		<div className="modal-content box newsletter-valid ">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={handleClick}></i>
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<div className="circle-icon circle-icon--blue"></div>
					<h3 className="mb-1 mt-2 ft-h2">{content ? content.title : ''}</h3>
					<p className="mb-1 ft-center">{content ? content.text : ''}</p>
					<Button label="J'ai compris" onClick={handleClick} className="mb-2 mt-1 mt-md-2" isFull />
				</div>
			</div>
		</div>
	);
}

export default SuccessModalContent;
