// @flow
import React from 'react';

import RenameMyKit from 'containers/editor/RenameMyKitContainer';

type Props = {
	content: any,
	hideModal: () => void,
};

function RenameMyKitModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box rename-my-kit">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<div className="modal__wrapper">
				<RenameMyKit content={content} hideModal={hideModal} />
			</div>
		</div>
	);
}

export default RenameMyKitModalContent;
