// @flow
import React from 'react';

import RemoveFormat from 'containers/editor/RemoveKitFormatContainer';

type Props = {
	content: any,
	hideModal: () => void,
};

function RemoveKitFormatModalContent({ content, hideModal }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box modal--kit">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<RemoveFormat content={content} hideModal={hideModal} />
		</div>
	);
}

export default RemoveKitFormatModalContent;
