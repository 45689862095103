// @flow
export const dynamicClassName = (e: string): Object => {
	let classNames = [...e.split(' ')] || e.split(' ');

	return {
		add: (cn: string): void => {
			classNames.push(cn);
		},
		remove: (cn: string) => {
			classNames = classNames.filter((className): boolean => className !== cn);
		},
		build: (): string => classNames.join(' '),
	};
};
