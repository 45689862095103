// @flow
import FooterBottomWithoutSiteMap from 'components/footer/FooterBottomWithoutSiteMap';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';

export default (connect((state: StateType) => ({
	footerElements: state.menuState.footer,
	logo_footer: state.settingsState.logo_footer,
	title: state.settingsState.title,
	url_cgus: state.settingsState.url_cgus,
	url_mentions_legales: state.settingsState.url_mentions_legales,
}))(FooterBottomWithoutSiteMap): any);
