// @flow

import axios from 'axios';

import { TIMEOUT } from 'constants/api';
import ApiExecutor from 'infrastructure/http/ApiExecutor';
import type { ApiExecutorType } from 'types/ApiExecutorType';

function apiInstance(baseURL: string, options: Object = {}): Object {
	const instance = axios.create({
		baseURL,
		timeout: TIMEOUT,
		...options,
	});

	return instance;
}

export default function api(baseUrl: string): ApiExecutorType {
	return new ApiExecutor(apiInstance(baseUrl));
}
