// @flow
import React from 'react';
import { useRouter } from 'next/router';

import ChooseKitFormats from 'containers/kits/ChooseKitFormatsContainer';
import type { SingleMyKit } from 'types/Kits';

export type DispatchProps = {
	deleteKitVariation(structureid: number, kitorig: number): void,
};

export type StateProps = {
	myKitList: SingleMyKit[],
	structureid: number,
};

type Props = DispatchProps &
	StateProps & {
		content: any,
		hideModal: () => void,
	};

function ChooseNewKitFormatsModalContent({
	content,
	deleteKitVariation,
	hideModal,
	myKitList,
	structureid,
}: Props): React$Element<'div'> {
	const router = useRouter();

	const handleCloseModal = () => {
		const isEditorRoute = router.asPath.includes('editeur');
		const kitAlreadyExistsInMyKit = myKitList.find((kit) => parseInt(kit.kitorig, 10) === parseInt(content.id, 10));

		if (!isEditorRoute && !kitAlreadyExistsInMyKit) {
			deleteKitVariation(structureid, content.id);
		}

		hideModal();
	};

	return (
		<div className="modal-content box modal--kit">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={handleCloseModal}></i>

			<div className="modal__wrapper">
				<ChooseKitFormats content={content} hideModal={hideModal} />
			</div>
		</div>
	);
}

export default ChooseNewKitFormatsModalContent;
