// @flow
import { combineReducers } from 'redux';
import appReducer from 'reducers/appReducer';
import clubReducer from 'reducers/clubReducer';
import contactFormReducer from 'reducers/contactFormReducer';
import kitAdminReducer from 'reducers/kitAdminReducer';
import kitsReducer from 'reducers/kitsReducer';
import kitsVariationsReducer from 'reducers/kitsVariationsReducer';
import magicLinkReducer from 'reducers/magicLinkReducer';
import menuReducer from 'reducers/menuReducer';
import modalReducer from 'reducers/modalReducer';
import newsletterSubscriptionReducer from 'reducers/newsletterSubscriptionReducer';
import settingsReducer from 'reducers/settingsReducer';

export default (combineReducers({
	appState: appReducer,
	clubState: clubReducer,
	contactFormState: contactFormReducer,
	kitAdminState: kitAdminReducer,
	kitsState: kitsReducer,
	kitsVariationsState: kitsVariationsReducer,
	magicLinkState: magicLinkReducer,
	menuState: menuReducer,
	modalState: modalReducer,
	newsletterSubscriptionState: newsletterSubscriptionReducer,
	settingsState: settingsReducer,
}): any);
