// @flow

import { APP_SET_SHOW_COOKIE_INFO } from 'actions/appActions';

import type { ActionType } from 'types/Actions';

export type State = {
	acceptCookie: boolean,
	acceptCookieDate: Date | null,
	showCookieInfo: boolean,
};

const initialState: State = {
	acceptCookie: false,
	acceptCookieDate: null,
	showCookieInfo: false,
};

export default function reducer(state: State = initialState, action: ActionType): State {
	switch (action.type) {
		case APP_SET_SHOW_COOKIE_INFO:
			const newState = {
				...state,
				showCookieInfo: action.payload.showCookieInfo,
			};

			if (action.payload.acceptCookie !== null && action.payload.acceptCookie !== undefined) {
				newState.acceptCookie =
					action.payload.acceptCookie === 'true'
						? true
						: action.payload.acceptCookie === 'false'
						? false
						: action.payload.acceptCookie;
				newState.acceptCookieDate = new Date();
			}

			return newState;
		default:
			return state;
	}
}
