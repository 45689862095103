/// @flow
import React, { PureComponent } from 'react';

import { dynamicClassName } from '../../utils/dynamicClassName';

type DefaultProps = {
	message?: string,
	forceDisplay?: boolean,
};
type Props = DefaultProps;

class ErrorMessage extends PureComponent<Props> {
	static defaultProps: DefaultProps = {
		message: 'Adresse email invalide',
		forceDisplay: false,
	};

	render(): React$Element<'span'> {
		const { forceDisplay, message } = this.props;
		const classNames = dynamicClassName('input__error');

		return (
			<span style={forceDisplay ? { display: 'block' } : {}} className={classNames.build()}>
				{message}
			</span>
		);
	}
}

export default ErrorMessage;
