// @flow
import { nullSingleKit } from 'types/Kits';
import type { RawSingleKitType, SingleKit } from 'types/Kits';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertKitsFromApi(rawKitList: RawSingleKitType[]): SingleKit[] {
	if (!rawKitList) {
		return [];
	}

	const convertedKitList = rawKitList.map((kit) => {
		return mergeIntoDefault(nullSingleKit, {
			...kit,
			title: kit.title.rendered,
			type: kit.type_kit,
			zipLink: kit.zipfile,
			zipContainedFormats: kit.zip_contained_formats,
			defaultExemple: kit.exemple_defaut
		});
	});

	return convertedKitList;
}
