// @flow
import { connect } from 'react-redux';

import FooterTop from 'components/footer/FooterTop';
import type { StateProps } from 'components/footer/FooterTop';
import type { StateType } from 'types/Actions';

export default (connect((state: StateType): $Exact<StateProps> => ({
	titleLigue: state.settingsState.title,
	liens_rs: state.settingsState.liens_rs,
}))(FooterTop): any);
