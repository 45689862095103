// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertKitAdminFromApi } from 'services/KitAdmin';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function KitAdminApi(executor: ApiExecutorType): {
	fetchKitAdmin: (structureId: number, magicLink: string) => empty,
	...
} {
	return {
		fetchKitAdmin,
	};

	function fetchKitAdmin(structureId: number, magicLink: string) {
		return executor
			.get(`ffr/v1/kit_admin?structureid=${structureId}`, getOnboardingMagicHeader(magicLink))
			.then((response) => convertKitAdminFromApi(response));
	}
}

export default KitAdminApi;
