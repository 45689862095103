// @flow

import { fetchMenu } from 'actions/menuActions';
import { fetchSettings } from 'actions/appActions';

// GENERICS
/**
 * check if you are on the client (browser) or server
 */
export const isBrowser = (): boolean => typeof window !== 'undefined';

// PROJECT SPECIFICS
export const getInitialData = (store: any): void => {
	store.dispatch(fetchSettings());
	store.dispatch(fetchMenu());
};
