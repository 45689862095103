// @flow
import { connect } from 'react-redux';

import HeaderMobile from 'components/header/HeaderMobile';
import type { StateProps } from 'components/header/HeaderMobile';
import type { StateType } from 'types/Actions';

export default (connect((state: StateType): $Exact<StateProps> => ({
	logo: state.settingsState.logo,
	logoOver: state.settingsState.logo_transparent,
}))(HeaderMobile): any);
