/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type DefaultProps = {
	id?: string,
	placeholder?: string,
	label?: string,
	errorMessage?: string,
	hasError?: boolean,
	isDark?: boolean,
	isLight?: boolean,
	isSocial?: boolean,
	socialIcon?: string,
	defaultValue?: string,
	customClasses?: string,
	hasUrlSuffix?: boolean,
	onChange?: (value: string) => void,
	onBlur?: () => void,
	onFocus?: () => void,
};

type Props = DefaultProps;

class TextInput extends PureComponent<Props> {
	static defaultProps: DefaultProps = {
		id: 'TextInput',
		placeholder: '',
		label: '',
		isSocial: false,
		socialIcon: '',
		defaultValue: '',
		errorMessage: undefined,
		hasError: false,
		isDark: false,
		isLight: false,
		customClasses: undefined,
		hasUrlSuffix: false,
		onChange: () => {},
		onFocus: () => {},
		onBlur: () => {},
	};

	handleInputChanged: (e: any) => void = (e: any) => {
		const { onChange } = this.props;

		if (onChange) onChange(e.target.value);
	};

	render(): React$Element<React$FragmentType> {
		const {
			isDark,
			isLight,
			hasError,
			id,
			placeholder,
			onBlur,
			onFocus,
			isSocial,
			socialIcon,
			defaultValue,
			customClasses,
			label,
			errorMessage,
			hasUrlSuffix,
		} = this.props;

		const classNames = dynamicClassName('input');
		const iconStyle = hasUrlSuffix ? { right: '50px' } : {};
		customClasses && classNames.add(customClasses);
		isDark && classNames.add('is-dark');
		isLight && classNames.add('input--light');
		hasError && classNames.add('has-error');
		isSocial && classNames.add('input-social input-social--site-club');

		return (
			<>
				{label && <label htmlFor={id}>{label}</label>}
				<div className={classNames.build()}>
					{isSocial && socialIcon && <i className={`icon ${socialIcon}`} />}
					<input
						type="text"
						id={id}
						placeholder={placeholder}
						onBlur={() => onBlur && onBlur()}
						onFocus={() => onFocus && onFocus()}
						onChange={this.handleInputChanged}
						defaultValue={defaultValue && defaultValue}
					/>
					{hasError && <i className="icon icon-info" style={iconStyle} />}
				</div>
				{hasError && errorMessage && <ErrorMessage message={errorMessage} />}
			</>
		);
	}
}

export default TextInput;
