// @flow

export const FORMAT_LIST = [
	{
		id: 'web_carre', // 9
		name: 'Carré', // 5
		tagName: 'Carré', // 5
		placeholder_width: 32,
		placeholder_height: 32,
		size: '1200 x 1200',
		type: 'web',
	},
	{
		id: 'web_banniere_reseau', // 20
		name: 'Bannière Réseaux', // 16
		tagName: 'Réseaux', // 7
		placeholder_width: 32,
		placeholder_height: 16,
		size: '1500 x 500',
		type: 'web',
	},
	{
		id: 'web_banniere_facebook', // 21
		name: 'Bannière Facebook', // 17
		tagName: 'Facebook', // 8
		placeholder_width: 32,
		placeholder_height: 12,
		size: '851 x 315',
		type: 'web',
	},
	{
		id: 'web_publication_paysage', // 23
		name: 'Publication Paysage', // 19
		tagName: 'Paysage', // 7
		placeholder_width: 32,
		placeholder_height: 24,
		size: '1920 x 1080',
		type: 'web',
	},
	{
		id: 'web_publication_portrait', // 24
		name: 'Publication Portrait', // 20
		tagName: 'Portrait', // 8
		placeholder_width: 24,
		placeholder_height: 32,
		size: '1080 x 1350',
		type: 'web',
	},
	{
		id: 'web_story', // 9
		name: 'Story', // 5
		tagName: 'Story', // 5
		placeholder_width: 16,
		placeholder_height: 32,
		size: '1080 x 1920',
		type: 'web',
	},
	{
		id: 'print_affiche', // 13
		name: 'Affiche', // 7
		tagName: 'Affiche', // 7
		placeholder_width: 24,
		placeholder_height: 32,
		size: 'A3',
		type: 'print',
	},
	{
		id: 'print_affiche_tdc', // 17
		name: 'Affiche (traits de coupe)', // 25
		tagName: 'Affiche (coupe)', // 15
		placeholder_width: 24,
		placeholder_height: 32,
		size: 'A3',
		type: 'print',
	},
	{
		id: 'print_flyer', // 11
		name: 'Flyer', // 5
		tagName: 'Flyer', // 5
		placeholder_width: 18,
		placeholder_height: 24,
		size: 'A5',
		type: 'print',
	},
	{
		id: 'print_flyer_tdc', // 15
		name: 'Flyer (traits de coupe)', // 23
		tagName: 'Flyer (coupe)', // 13
		placeholder_width: 18,
		placeholder_height: 24,
		size: 'A5',
		type: 'print',
	},
];

// tagName caractère max = 9 pipes + 80 caractères => 89

export const COLORS_PALETTE = [
	'#FF6900',
	'#FCB900',
	'#7BDCB5',
	'#00D084',
	'#8ED1FC',
	'#0693E3',
	'#ABB8C3',
	'#EB144C',
	'#F78DA7',
	'#9900EF',
];
