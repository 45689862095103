// @flow
import { withRouter } from 'next/router';
import React from 'react';

import Button from 'components/fragments/button/Button';
import type { RouterProps } from 'types/Router';

type Props = RouterProps & {
	content: any,
	hideModal: () => void,
};

function DownloadKitSuccessModalContent({ content, hideModal, router }: Props): React$Element<'div'> {
	return (
		<div className="modal-content box newsletter-valid">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<div className="circle-icon circle-icon--blue"></div>
					<h3 className="mb-1 mt-2 ft-h2">Kit téléchargé</h3>
					<p className="mb-1 ft-center">
						Votre kit a bien été téléchargé et est disponible sur votre appareil. Il vous a également été envoyé par
						mail pour faciliter son partage avec vos équipes.
					</p>
					<Button
						label="Revenir au tableau de bord"
						url="/kits/tableau-de-bord"
						onClick={hideModal}
						className="mt-1 mt-md-2"
						isFull
					/>
					<Button label="Continuer l’édition" onClick={hideModal} className="mb-2 textIsBlue" isSecondary isFull />
				</div>
			</div>
		</div>
	);
}

export default DownloadKitSuccessModalContent;
