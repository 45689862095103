// @flow
import React, { PureComponent } from 'react';
import Link from 'next/link';

import { logoWidth } from 'constants/menuConstants';
import { dynamicClassName } from 'utils/dynamicClassName';
import { convertEncodedHTML } from 'utils/convertUtils';

type DefaultProps = {
	isActive?: boolean,
	activateMenu?: Function,
	isNavbar?: boolean,
};

type Props = {
	isMobile: boolean,
	hiddenMenuElements: any,
	menuX: number,
	text: string,
	id: number,
} & DefaultProps;

type ComponentState = {
	isOpen: boolean,
	dropdownTargetX: number,
};

class MenuElementPlus extends PureComponent<Props, ComponentState> {
	_dropdownmenu: ?HTMLElement;

	_title: ?HTMLElement;

	static defaultProps: DefaultProps = {
		isActive: false,
		activateMenu: () => {},
		isNavbar: false,
	};

	state: ComponentState = {
		isOpen: true,
		dropdownTargetX: 0,
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions: () => void = () => {
		const { isNavbar, menuX } = this.props;
		const { dropdownTargetX: dropdownTargetXState } = this.state;

		const dropdownmenu = this._dropdownmenu;
		const title = this._title;

		if (dropdownmenu && title) {
			const titleBounds = title.getBoundingClientRect();

			if (isNavbar) {
				let dropdownTargetX;

				dropdownTargetX = window.innerWidth - titleBounds.right - menuX - 17;
				if (dropdownTargetX !== dropdownTargetXState)
					this.setState({
						dropdownTargetX,
					});
			} else {
				const titleCenterX = titleBounds.left + title.offsetWidth / 2;
				const dropdownTargetX = Math.round(titleCenterX - dropdownmenu.offsetWidth / 2 + logoWidth - menuX);
				if (dropdownTargetX !== dropdownTargetXState)
					this.setState({
						dropdownTargetX,
					});
			}
		}
	};

	componentDidUpdate() {
		this.updateDimensions();
	}

	openMenu: (e: MouseEvent) => void = (e: MouseEvent) => {
		this.setState({ isOpen: true });
	};

	onTouchOnMenuElement: (e: any) => void = (e: any) => {
		e.preventDefault();
		e.currentTarget.click();
		this.setState({ isOpen: false });
	};

	onClickOnMenuElement: () => void = () => {
		const { activateMenu, id } = this.props;
		activateMenu && activateMenu(id);
	};

	renderMenuElement: () => any = () => {
		const { hiddenMenuElements } = this.props;
		return hiddenMenuElements.map((element, index) => {
			if (element) {
				return (
					<li key={`${element.props.title}-${element.props.url}`}>
						<Link href={element.props.url}>
							<a onTouchEnd={this.onTouchOnMenuElement} onClick={this.onClickOnMenuElement}>
								{convertEncodedHTML(element.props.title)}
							</a>
						</Link>
					</li>
				);
			}
			return null;
		});
	};

	render(): null | React$Element<'li'> {
		const { isMobile, hiddenMenuElements, text, isNavbar, isActive } = this.props;
		const { isOpen, dropdownTargetX } = this.state;

		const liClassName = dynamicClassName('js-menuPlus');
		const aClassName = dynamicClassName('');
		const ulClassName = dynamicClassName('');
		const submenuClassName = dynamicClassName('menu__sub__container');

		if (isNavbar) {
			liClassName.add('navbar__plus');
			ulClassName.add('navbar__sub');
		} else {
			liClassName.add('menu__plus');
			aClassName.add('accordion-trigger');
			ulClassName.add('menu__sub menu__plus__container accordion-panel menu__sub__contained');
		}

		isActive && aClassName.add('is-active');

		// "header__wrapper" allows to hide element by CSS.
		// There is no "hide" class in style.css and we must
		// keep our element in DOM node.
		!isOpen && submenuClassName.add('header__wrapper');

		if (!isMobile && hiddenMenuElements.length > 0) {
			return (
				<li className={liClassName.build()}>
					<a // eslint-disable-line
						className={aClassName.build()}
						ref={(c) => (this._title = c)}
						onTouchStart={this.openMenu}
						role="button"
						tabIndex={0}>
						{text}
					</a>
					<div className={submenuClassName.build()}>
						<ul
							ref={(c) => (this._dropdownmenu = c)}
							style={isNavbar ? { right: dropdownTargetX } : { left: dropdownTargetX }}
							className={ulClassName.build()}>
							{this.renderMenuElement()}
						</ul>
					</div>
				</li>
			);
		}

		return null;
	}
}

export default MenuElementPlus;
