// @flow
import React from 'react';

import Button from 'components/fragments/button/Button';

type Props = {
	content: any,
	hideModal: () => void,
};

function FailureModalContent({ content, hideModal }: Props): React$Element<'div'> {
	const words = content.wording || "Nous avons rencontré un problème lors de l'inscription";
	const txt = 'Merci de vérifier les informations envoyées !';

	return (
		<div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal} />
			<div className="modal__wrapper">
				<h3 className="mb-2">{content ? content.title : words}</h3>
				<div className="modal__body modal__body--medium narrow-2 mb-3">
					<div className="circle-icon circle-icon--alert"></div>
					<p>{content ? content.text : txt}</p>
					<button type="button">Envoyer</button>
				</div>
			</div>
		</div>
	);
}

export default FailureModalContent;
