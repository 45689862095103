// @flow
import type { KitAdminType } from 'types/KitAdmin';

export const FETCH_KIT_ADMIN_START = 'FETCH_KIT_ADMIN_START';
export const FETCH_KIT_ADMIN_SUCCESS = 'FETCH_KIT_ADMIN_SUCCESS';
export const FETCH_KIT_ADMIN_FAILURE = 'FETCH_KIT_ADMIN_FAILURE';

export type FETCH_KIT_ADMIN_START_ACTION = {
	type: 'FETCH_KIT_ADMIN_START',
	payload: {
		structureId: number,
		magicLink: string,
	},
};

export type FETCH_KIT_ADMIN_SUCCESS_ACTION = {
	type: 'FETCH_KIT_ADMIN_SUCCESS',
	payload: {
		kitAdmin: KitAdminType,
	},
};

export type FETCH_KIT_ADMIN_FAILURE_ACTION = {
	type: 'FETCH_KIT_ADMIN_FAILURE',
};

export type Action = FETCH_KIT_ADMIN_START_ACTION | FETCH_KIT_ADMIN_FAILURE_ACTION | FETCH_KIT_ADMIN_SUCCESS_ACTION;

export function fetchKitAdmin(structureId: number, magicLink: string): FETCH_KIT_ADMIN_START_ACTION {
	return {
		type: FETCH_KIT_ADMIN_START,
		payload: {
			structureId,
			magicLink,
		},
	};
}

export function kitAdminFetched(kitAdmin: KitAdminType): FETCH_KIT_ADMIN_SUCCESS_ACTION {
	return {
		type: FETCH_KIT_ADMIN_SUCCESS,
		payload: { kitAdmin },
	};
}

export function kitAdminFetchError(): FETCH_KIT_ADMIN_FAILURE_ACTION {
	return {
		type: FETCH_KIT_ADMIN_FAILURE,
	};
}
