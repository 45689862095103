// @flow

import { fork, all } from 'redux-saga/effects';

import { ON_BOARDING_BASE_URL } from 'constants/api';
import api from 'infrastructure/http/index';
import clubSaga from 'sagas/clubSaga';
import contactFormSaga from 'sagas/contactFormSaga';
import kitsSaga from 'sagas/kitsSaga';
import kitAdminSaga from 'sagas/kitAdminSaga';
import kitsVariationsSaga from 'sagas/kitsVariationsSaga';
import magicLinkSaga from 'sagas/magicLinkSaga';
import menuSaga from 'sagas/menuSaga';
import newsletterSubscriptionSaga from 'sagas/newsletterSubscriptionSaga';
import settingsSaga from 'sagas/settingsSaga';

export type GeneratorType = Generator<*, *, *>;

export default function* (): GeneratorType {
	yield fork(bootstrap);
}

function* bootstrap(): GeneratorType {
	const onBoardingApiExecutor = api(ON_BOARDING_BASE_URL);

	try {
		yield all([
			fork(clubSaga(onBoardingApiExecutor)),
			fork(contactFormSaga(onBoardingApiExecutor)),
			fork(kitsSaga(onBoardingApiExecutor)),
			fork(kitAdminSaga(onBoardingApiExecutor)),
			fork(kitsVariationsSaga(onBoardingApiExecutor)),
			fork(magicLinkSaga(onBoardingApiExecutor)),
			fork(menuSaga(onBoardingApiExecutor)),
			fork(newsletterSubscriptionSaga(onBoardingApiExecutor)),
			fork(settingsSaga(onBoardingApiExecutor)),
		]);
	} catch (e) {
		// @TODO
	}
}
