// @flow
import Menu from 'components/header/Menu';
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import { forgotMagicLink } from 'actions/magicLinkActions';
import type { StateType, DispatchType } from 'types/Actions';

export default (connect(
	(state: StateType, ...ownProps: any) => {
		const isAdmin = state.clubState.club.is_admin;
		let menuElements = state.menuState.header;
		if (!!isAdmin) {
			const menuElementsForAdmin = state.menuState.headerForAdmin;
			menuElements = [...menuElements, ...menuElementsForAdmin];
		}

		return {
			club: state.clubState.club,
			menuElements,
			logo: state.settingsState.logo,
			logo_transparent: state.settingsState.logo_transparent,
			titleLigue: state.settingsState.title,
			liens_rs: state.settingsState.liens_rs,
			magicLink: state.magicLinkState.magicLink,
			email: state.clubState.club.email,
		};
	},
	(dispatch: DispatchType) => ({
		displayModal: (modalObject: string, content: any) => dispatch(displayModal(modalObject, content)),
		forgotMagicLink: () => dispatch(forgotMagicLink()),
	}),
	null,
	{ pure: false },
)(Menu): any);
