// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { FORM_CONTACT_TOKEN } from 'constants/api';

function ContactFormApi(executor: ApiExecutorType): {
	fetchContactForm: () => Promise<any>,
	postContactForm: (formInputs: string, id: number) => Promise<any>,
	...
} {
	const headers = {
		Authorization: `Basic ${FORM_CONTACT_TOKEN}`,
	};
	return { fetchContactForm, postContactForm };

	function fetchContactForm(): Promise<any> {
		return executor.get('contact-form-7/v1/contact-forms', { headers });
	}

	function postContactForm(formInputs: string, id: number): Promise<any> {
		return executor.post(`/contact-form-7/v1/contact-forms/${id}/feedback`, formInputs, { headers });
	}
}

export default ContactFormApi;
