export const GTM_ID = 'GTM-MBXKD6N';

function loadGtmScript() {
	if (typeof window !== undefined) {
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', GTM_ID);
	}
}

function initializeDatalayer(data = {}) {
	if (typeof window !== undefined) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(data);
	}
}

function consentGtag() {
	if (typeof window !== undefined) {
		// Initialize the data layer for Google Tag Manager (this should mandatorily be done before the Cookie Solution is loaded)
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		// Default consent mode is "denied" for both ads and analytics, but delay for 2 seconds until the Cookie Solution is loaded
		gtag('consent', 'default', {
			ad_storage: 'denied',
			analytics_storage: 'denied',
			wait_for_update: 2000, // milliseconds
		});
		// // Improve ad click measurement quality (optional)
		// gtag('set', 'url_passthrough', true);
		// // Further redact your ads data (optional)
		// gtag("set", "ads_data_redaction", true);
	}
}

const TagManager = {
	consentGtag,
	initializeDatalayer,
	loadGtmScript,
};

export default TagManager;
