// @flow
import { connect } from 'react-redux';

import TopBar from 'components/header/TopBar';
import type { StateProps } from 'components/header/TopBar';
import type { StateType } from 'types/Actions';

export default (connect((state: StateType, ...ownProps: any): $Exact<StateProps> => ({
	tickerElements: state.settingsState.actualite_topbar,
	topBarElements: state.menuState.topBar,
}))(TopBar): any);
