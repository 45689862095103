// @flow
import React, { PureComponent } from 'react';

import CheckboxInput from 'components/fragments/input/CheckboxInput';
import SelectInput from 'components/fragments/input/SelectInput';
import TextInput from 'components/fragments/input/TextInput';
import { STATUS_DEFAULT } from 'constants/statusConstants';
import type { NewsletterSubscriptionType, NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import type { Status } from 'types/Status';

import { validateEmail } from 'utils/validatorUtils';

export type StateProps = {
	options: NewsletterSubscriptionType,
	status: Status,
	newsletterSetting: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
	titleLigue: string,
};

export type DispatchProps = {
	fetchOptions: (campaignMonitorKey: string) => void,
	subscribeNewsletter: (
		subscription: NewsletterSubscriptionEntryType,
		campaignMonitorKey: string,
		typeNewsletter: string | boolean,
	) => void,
	displayModal: (modalObject: string, content: Object, force: boolean) => void,
};

type DefaultProps = {
	mode?: string,
};

type Props = DefaultProps & StateProps & DispatchProps;

type State = {
	profil: null | string,
	email: null | string,
	emailValide: boolean,
	consentOpt1: boolean,
	consentOpt2: boolean,
};

class NewsletterSubscriber extends PureComponent<Props, State> {
	static defaultProps: DefaultProps = {
		mode: '',
	};

	props: Props;

	state: State = {
		profil: null,
		email: null,
		emailValide: false,
		consentOpt1: false,
		consentOpt2: false,
	};

	componentDidMount() {
		const { newsletterSetting, fetchOptions } = this.props;

		if (newsletterSetting.type === 'campaign_monitor') {
			fetchOptions(newsletterSetting.key || '');
		}
	}

	componentDidUpdate() {
		const {
			status,
			newsletterSetting: { type, key },
			fetchOptions,
		} = this.props;

		if (type === 'campaign_monitor' && status === STATUS_DEFAULT) fetchOptions(key || '');
	}

	setProfil: (profil: string) => void = (profil: string) => {
		this.setState({ profil });
	};

	setEmail: (email: string) => void = (email: string) => {
		if (validateEmail(email)) {
			this.setState({
				email,
				emailValide: true,
			});
		} else {
			this.setState({ emailValide: false });
		}
	};

	setConsent: (value: boolean, checkboxType: string) => void = (value: boolean, checkboxType: string) => {
		this.setState({ [checkboxType]: value });
	};

	handleSubmitClicked: (e: MouseEvent) => void = (e: MouseEvent) => {
		const { email, profil, consentOpt1, consentOpt2 } = this.state;
		const {
			subscribeNewsletter,
			newsletterSetting: { key, type },
			displayModal,
		} = this.props;
		const campaignMonitorKey = !key ? '' : key;

		e.preventDefault();
		if (profil === null || email === null) {
			this.setState({
				profil: profil === null ? '' : profil,
				email: email === null ? '' : email,
			});
		} else if (!consentOpt1) {
			displayModal('NEWSLETTER_MISSING_OPT', {}, true);
		} else if (profil !== '' && validateEmail(email)) {
			const { email, profil } = this.state;

			const options = {
				ConsentToTrack: consentOpt1 ? 'yes' : 'no',
				CustomFields: [
					{
						Key: 'Jesuis',
						Value: profil,
					},
					{
						Key: 'Optin1',
						Value: consentOpt1 ? 'yes' : 'no',
					},
					{
						Key: 'Optin2',
						Value: consentOpt2 ? 'yes' : 'no',
					},
				],
				EmailAddress: email,
				Name: '',
				action: 'subscribe',
			};
			subscribeNewsletter(options, campaignMonitorKey, type);
		}
	};

	renderLinkButton: () => React$Element<'div'> = () => {
		const {
			titleLigue,
			newsletterSetting: { lien },
		} = this.props;
		return (
			<div className="col col-lg-9">
				<h5 className="mb-1">Newsletter</h5>
				<p className="mb-2">
					Recevez par email les nouveautés de la Fédération Française de Rugby en fonction de votre profil
				</p>
				<a
					title={`S'inscrire à la newsletter de la Fédération Française de Rugby`}
					className="btn btn--primary"
					href={lien || '#'}>
					S&apos;inscrire
				</a>
			</div>
		);
	};

	// ! @todo : à vérifier une fois qu'on sera sur l'api des kits comm
	render(): null | React$Element<'div'> {
		const {
			options: { options },
			newsletterSetting: { type },
			status,
			mode,
		} = this.props;
		const { emailValide, email, profil } = this.state;
		const labels = {
			optin1: `J’autorise la FFR à utiliser mes données personnelles 
      (e-mail, adresse postale) à des fins promotionnelles 
      ou commerciales pour ses propres activités (billetterie, boutique officielle).`,
			optin2: `J’autorise la FFR à transmettre certaines de mes données personnelles 
      (nom, prénom, e-mail, adresse postale) à des tiers, et notamment ses 
      partenaires et fournisseurs officiels, à des fins commerciales, 
      associatives ou humanitaires.`,
		};

		if (type === false || (type === 'campaign_monitor' && status === STATUS_DEFAULT)) return null;
		if (type === 'lien') return this.renderLinkButton();
		else {
			if (options.length === 0 && type !== 'salesforce') {
				return null;
			}
			let jesuis = [];
			if (type === 'campaign_monitor') {
				jesuis = options;
			} else if (type === 'salesforce') {
				jesuis = ['Licencié', 'Dirigeant de club', 'Bénévole', 'Autre'];
			}
			if (mode === 'sidebar') {
				return (
					<div className="card__body">
						<h3 className="card__title">Newsletter FFR</h3>
						<p className="card__desc">
							Recevez par email les nouveautés de la Fédération Française de Rugby en fonction de votre profil
						</p>
						<form action="#">
							<SelectInput
								isDark
								placeholder="Je suis..."
								options={jesuis.map((option) => ({ label: option, value: option }))}
								onChange={this.setProfil}
								hasError={profil === ''}
							/>
							<TextInput
								id="email"
								isDark
								placeholder="Adresse mail*"
								hasError={email !== null && !validateEmail(email)}
								onChange={this.setEmail}
							/>
							<CheckboxInput
								className="checkbox"
								id="hgsdfsddf123"
								label={labels['optin1']}
								onChange={(value) => this.setConsent(value, 'consentOpt1')}
							/>
							<CheckboxInput
								className={'checkbox'}
								id="hgsdfsddf1dsds23"
								label={labels['optin2']}
								onChange={(value) => this.setConsent(value, 'consentOpt2')}
							/>
							<div></div>
							<button
								className="btn btn--primary btn--full"
								type="submit"
								onClick={this.handleSubmitClicked}
								disabled={!emailValide}>
								<i className="icon icon-valid is-inline" />
								Inscription
							</button>
						</form>
					</div>
				);
			}
			return (
				<div className="col col-lg-9">
					<h5 className="mb-1">Newsletter</h5>
					<p className="mb-2">
						Recevez par email les nouveautés de la Fédération Française de Rugby en fonction de votre profil
					</p>
					<form action="#">
						<SelectInput
							isDark
							placeholder="Je suis..."
							options={jesuis.map((option) => ({ label: option, value: option }))}
							onChange={this.setProfil}
							hasError={profil === ''}
						/>
						<TextInput
							id="email"
							isDark
							placeholder="Adresse mail*"
							hasError={email !== null && !validateEmail(email)}
							onChange={this.setEmail}
						/>
						<CheckboxInput
							className="checkbox"
							id="hgsdfsddf123"
							label={labels['optin1']}
							onChange={(value) => this.setConsent(value, 'consentOpt1')}
						/>
						<CheckboxInput
							className={'checkbox'}
							id="hgsdfsddf1dsds23"
							label={labels['optin2']}
							onChange={(value) => this.setConsent(value, 'consentOpt2')}
						/>
						<div></div>
						<button
							className="btn btn--primary"
							type="submit"
							onClick={this.handleSubmitClicked}
							disabled={!emailValide}>
							<i className="icon icon-valid is-inline" />
							Inscription
						</button>
					</form>
				</div>
			);
		}
	}
}

export default NewsletterSubscriber;
