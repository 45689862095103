// @flow
import CookieCutter from 'cookie-cutter';
import { withRouter } from 'next/router';
import React from 'react';

import ReAskMagicLink from 'containers/kits/ReAskMagicLinkContainer';
import type { RouterProps } from 'types/router';

type Props = {
	content: any,
	hideModal: () => void,
} & RouterProps;

function LogoutSuccessModalContent({ content, hideModal, router }: Props): React$Element<'div'> {
	const handleLogout = () => {
		CookieCutter.set('logoutSuccessModalCookie', ``, { expires: new Date(0) });

		setTimeout(() => {
			hideModal();
		}, 1000);
	};

	return (
		<div className="modal-content box newsletter-valid ">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={handleLogout}></i>
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<h3 className="mb-1 mt-2 ft-h2">{content ? content.title : ''}</h3>
					<p className="mb-1 ft-center">{content ? content.text : ''}</p>
					{content.emailUser && <ReAskMagicLink emailUser={content.emailUser} />}
				</div>
			</div>
		</div>
	);
}

export default (withRouter(LogoutSuccessModalContent): any);
