/* eslint-disable @next/next/no-img-element */
// @flow
import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';

import MenuDropDown from 'components/header/MenuDropDown';
import MenuElement from 'components/header/MenuElement';
import MenuElementPlus from 'components/header/MenuElementPlus';

import { logoWidth } from 'constants/menuConstants';

import TagManager from 'utils/tagManagerUtils';

import type { liensRsType } from 'types/Settings';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import type { ClubType } from 'types/Club';

type StateProps = {
	menuElements: Array<MenuElementType>,
	logo: string,
	logo_transparent: string,
	titleLigue: string,
	liens_rs: liensRsType,
	magicLink: string,
	email: string,
	club: ClubType,
};

type DispatchProps = {
	displayModal: (modalObject: string, content: any) => void,
};

type Props = {
	onCloseMenu: Function,
} & StateProps &
	DispatchProps &
	RouterProps;

type ComponentState = {
	isMobile: boolean,
	hiddenMenuItems: Array<number>,
	menuLinkX: number,
	hiddenSubMenu: boolean,
};

class Menu extends PureComponent<Props, ComponentState> {
	_menuElements: Array<?MenuElement>;

	_menu: ?HTMLElement;

	_menuRight: ?HTMLElement;

	_menuLink: ?HTMLElement;

	_frameId: string;

	state: ComponentState = {
		isMobile: false,
		hiddenMenuItems: [],
		menuLinkX: 0,
		hiddenSubMenu: false,
	};

	constructor(props: Props) {
		super(props);
		this._menuElements = [];
	}

	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
	}

	componentDidUpdate(prevProps, prevState) {
		this.updateDimensions();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	resetMenuElementRef = (c: ?MenuElement) => {
		this._menuElements = [];
		this.addMenuElementRef(c);
	};

	addMenuElementRef = (c: ?MenuElement) => {
		this._menuElements.push(c);
	};

	isDisplayed = (id: number) => {
		const { hiddenMenuItems } = this.state;

		return !hiddenMenuItems.includes(id);
	};

	hiddenTemporary = () => {
		this.setState({ hiddenSubMenu: true });

		setTimeout(() => {
			this.setState({ hiddenSubMenu: false });
		}, 500);
	};

	updateDimensions = () => {
		const { menuLinkX: menuLinkXState, isMobile, hiddenMenuItems } = this.state;
		const menu = this._menu;
		const menuRight = this._menuRight;
		const menuLink = this._menuLink;

		if (menu && menuRight && menuLink) {
			const menuLinkBounds = menuLink.getBoundingClientRect();
			const menuLinkX = menuLinkBounds.left;

			if (menuLinkX !== menuLinkXState) {
				this.setState({
					menuLinkX,
				});
			}

			const maxWidth = menu.offsetWidth;
			const width_logo = logoWidth;

			const width_menu_links =
				this._menuElements.reduce((total, item) => (item ? total + item.getSize() : total), 0) +
				this._menuElements.length * 12;

			const width_menu_right = menuRight.offsetWidth;
			const totalWidth = width_menu_links + width_menu_right + width_logo + 50;

			let currentWidth = totalWidth;
			let deleted = [];
			if (maxWidth > 1000) {
				for (let i = this._menuElements.length; i > 0; i--) {
					if (currentWidth > maxWidth - logoWidth) {
						if (this._menuElements[i]) currentWidth -= this._menuElements[i].getSize();
						deleted = [...deleted, i];
					}
				}
				if (isMobile) this.setState({ isMobile: false });
			} else {
				if (!isMobile) this.setState({ isMobile: true });
			}
			if (
				(deleted.length > 0 || hiddenMenuItems.length > 0) &&
				JSON.stringify(deleted) !== JSON.stringify(hiddenMenuItems)
			) {
				this.setState({ hiddenMenuItems: deleted });
			}
		}
	};

	renderSubMenu = (subMenus: Array<Object>): any => {
		const { hiddenTemporary } = this;

		return subMenus.map((subMenu, index) => {
			if (subMenu.type === 'custom') {
				const subMenus = subMenu.items ? subMenu.items : [];
				if (subMenu.url === '#') {
					if (subMenus.length > 0) {
						const subMenusRender = [];

						if (subMenus.length > 7) {
							let subMenusSlice = subMenus.slice(0, 6);
							subMenusRender.push(subMenusSlice);
							subMenusSlice = subMenus.slice(7, 13);
							subMenusRender.push(subMenusSlice);
						} else {
							subMenusRender.push(subMenus);
						}

						const classe = this;

						return (
							<li key={`${subMenu.slug_complet}-${subMenu.title}`}>
								<span className="menu__category">{subMenu.title}</span>
								{subMenusRender.map((subMenus, i) => (
									<ul key={`${subMenu.title}--${subMenu.slug_complet}-${subMenu.title}`}>
										<>{classe.renderSubMenu(subMenus)}</>
									</ul>
								))}
							</li>
						);
					} else {
						return (
							<li key={`${subMenu.slug_complet}-${subMenu.title}`}>
								<span className="menu__category">{subMenu.title}</span>
							</li>
						);
					}
				} else {
					return (
						<li key={`${subMenu.slug_complet}-${subMenu.title}`}>
							<Link href={`${subMenu.slug_complet}`}>
								<a target="_blank" onClick={() => hiddenTemporary()}>
									{subMenu.title}
								</a>
							</Link>
						</li>
					);
				}
			} else if (subMenu.type === 'post_type') {
				return (
					<li key={subMenu.slug_complet}>
						<Link href={`${subMenu.slug_complet}`}>
							<a target="_blank" onClick={() => hiddenTemporary()}>
								{subMenu.title}
							</a>
						</Link>
					</li>
				);
			}
			return null;
		});
	};

	renderChidrenMenuElement = (subMenus: Array<Object>) => {
		const { hiddenSubMenu } = this.state;

		if (!hiddenSubMenu) {
			return <>{this.renderSubMenu(subMenus)}</>;
		}

		return undefined;
	};

	renderMenuElement = () => {
		const {
			menuElements,
			onCloseMenu,
			router: { pathname },
		} = this.props;
		const { isMobile, menuLinkX } = this.state;
		// const menus = menuElements.filter(item => item.parent === 0);
		let indexMenuElements = 0;

		if (!menuElements) return [];

		let renderedMenu = menuElements.map((menu, index) => {
			const subMenus = menu.items ? menu.items : [];
			if (menu.type === 'post_type') {
				if (menu.classes === 'bouton-vert') {
					return null;
				}

				const url = pathname.split('/');
				const objectSlug = url.length > 1 ? `${url[1]}/${url[2]}` : url[0];

				if (subMenus.length > 0) {
					let col = false;

					subMenus.forEach((subMenu) => {
						if (subMenu.items && subMenu.items.length > 0) {
							col = true;
						}
					});

					const menuEl = (
						<MenuElement
							url={`/${menu.object_slug}`}
							urlType={menu.type}
							title={menu.title}
							titleAll={menu.title}
							isMobile={isMobile}
							ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
							id={indexMenuElements}
							isDisplayed={this.isDisplayed}
							menuX={menuLinkX}
							key={`${menu.object_slug}`}
							col={!!col}
							isActive={objectSlug === menu.object_slug}
							onCloseMenu={onCloseMenu}
							target={menu.target}>
							{this.renderChidrenMenuElement(subMenus)}
						</MenuElement>
					);

					indexMenuElements++;
					return menuEl;
				} else {
					const menuEl = (
						<MenuElement
							url={`/${menu.object_slug}`}
							urlType={menu.type}
							title={menu.title}
							titleAll={menu.title}
							isMobile={isMobile}
							ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
							id={indexMenuElements}
							isDisplayed={this.isDisplayed}
							menuX={menuLinkX}
							key={`${menu.object_slug}`}
							isActive={objectSlug === menu.object_slug}
							onCloseMenu={onCloseMenu}
							target={menu.target}
						/>
					);
					indexMenuElements++;
					return menuEl;
				}
			}
			return null;
		});

		return renderedMenu;
	};

	openCreationRequestModal = () => {
		const { displayModal, magicLink, email, router } = this.props;

		TagManager.handleTagEvent({
			event: 'cta_clicked',
			cta_position: 'haut',
			cta_wording: 'Personnaliser mes kits',
		});

		if (magicLink) {
			router.push('/initialisation');
		} else {
			email
				? displayModal('RE_ASK_MAGIC_LINK', { title: `Continuer sur la plateforme des kits pour mon club` })
				: displayModal('CREATION_REQUEST');
		}
	};

	handleLogout = (event) => {
		const { displayModal } = this.props;
		displayModal('LOGOUT_ALERT');
	};

	render() {
		const { hiddenMenuItems, isMobile, menuLinkX } = this.state;
		const {
			onCloseMenu,
			logo,
			logo_transparent,
			titleLigue,
			menuElements,
			liens_rs,
			router: { pathname },
			magicLink,
			club,
		} = this.props;

		const clubName = club.nom;

		let stepName = club.configuration.step;
		const isFromKitsRoute =
			pathname.includes('kits') && !pathname.includes('initialisation') && !pathname.includes('deleguer');
		const isFromAdminRoute = pathname.includes('initialisation') || pathname.includes('deleguer');

		const hiddenMenuElements = hiddenMenuItems.map((id) => {
			return this._menuElements[id];
		});
		let logo_used = logo;
		const header_over =
			!pathname.match('actualites/.*/.*') &&
			!pathname.match('resultats-de-recherche/') &&
			!pathname.match('clubs/') &&
			!pathname.match(/evenements\/[a-z]/g);
		if (header_over) {
			logo_used = logo;
		}
		if (hiddenMenuElements.length > 0) {
			hiddenMenuElements.shift();
			const menuPlus = menuElements.find((menu) => menu.type === 'custom' && menu.title === 'Plus');
			if (menuPlus) {
				const menuPlusChildren = menuElements.filter((item) => item.parent === menuPlus.id);
				menuPlusChildren.forEach((menu, index) => {
					hiddenMenuElements.splice(
						0,
						0,
						<MenuElement
							url={`/${menu.object_slug}`}
							urlType={menu.type}
							title={menu.title}
							titleAll={menu.title}
							isMobile={isMobile}
							id={menu.id}
							isDisplayed={this.isDisplayed}
							menuX={menuLinkX}
							key={`${menu.object_slug}`}
							onCloseMenu={onCloseMenu}
							target={menu.target}
						/>,
					);
				});
			}
		}

		return (
			<nav className="menu" ref={(c) => (this._menu = c)}>
				<Link href={'/'}>
					<a title="Retour à la page d'accueil">
						<span
							style={{
								position: 'absolute',
								top: '25%',
								width: 100,
								height: 58,
								display: 'inline-block',
							}}>
							{logo_used && <img alt="logo" className="menu__logo" src={logo_used} />}
						</span>
						{isFromKitsRoute && (
							<h1>
								Kits de
								<br />
								communication
							</h1>
						)}
					</a>
				</Link>

				<ul className="menu__link" ref={(c) => (this._menuLink = c)}>
					{isFromKitsRoute && this.renderMenuElement()}
					<MenuElementPlus
						hiddenMenuElements={hiddenMenuElements}
						isMobile={isMobile}
						id={10}
						isDisplayed={this.isDisplayed}
						menuX={menuLinkX}
						text="Plus"
					/>
				</ul>

				<div className="menu__social">
					{liens_rs.fb_lien && (
						<Link href={liens_rs.fb_lien}>
							<a
								className="link-icon"
								target="_blank"
								rel="noopener noreferrer"
								title={`Se rendre sur la page Facebook de ${titleLigue} (nouvel onglet)`}>
								<i className="icon icon-facebook" />
							</a>
						</Link>
					)}
					{liens_rs.twitter_lien && (
						<Link href={liens_rs.twitter_lien}>
							<a
								className="link-icon"
								target="_blank"
								rel="noopener noreferrer"
								title={`Se rendre sur la page Twitter de ${titleLigue} (nouvel onglet)`}>
								<i className="icon icon-twitter" />
							</a>
						</Link>
					)}
					{liens_rs.instagram_lien && (
						<Link href={liens_rs.instagram_lien}>
							<a
								className="link-icon"
								target="_blank"
								rel="noopener noreferrer"
								title={`Se rendre sur la page Instagram de ${titleLigue} (nouvel onglet)`}>
								<i className="icon icon-instagram" />
							</a>
						</Link>
					)}
					{liens_rs.youtube_lien && (
						<Link href={liens_rs.youtube_lien}>
							<a
								className="link-icon"
								target="_blank"
								rel="noopener noreferrer"
								title={`Se rendre sur la page Youtube de ${titleLigue} (nouvel onglet)`}>
								<i className="icon icon-youtube" />
							</a>
						</Link>
					)}
				</div>
				{/* BOUTON DANS LE MENU DE NAVIGATION */}
				<div className="menu__right" ref={(c) => (this._menuRight = c)}>
					{/* depuis la home sans lien magique */}
					{!isFromKitsRoute && !isFromAdminRoute && (!magicLink || magicLink === '') && (
						<button
							type="button"
							className="btn btn--primary"
							onClick={this.openCreationRequestModal}
							style={{ marginTop: '32px' }}>
							{'Personnaliser mes kits'}
						</button>
					)}
					{/* depuis la home ou l'initialisation du gestionnaire de kits avec un lien magique */}
					{!isFromKitsRoute && !isFromAdminRoute && magicLink !== '' && (
						<Link href={`/kits/tableau-de-bord`}>
							<a className={`btn btn--primary`} style={{ marginTop: '32px' }}>
								{'Accéder à mes kits'}
							</a>
						</Link>
					)}
					{/* depuis une route /kits */}
					{isFromKitsRoute && <MenuDropDown handleLogout={this.handleLogout} title={clubName} />}
				</div>
				<i
					className="icon icon-close menu__close js-showMenu"
					role="button"
					tabIndex={0}
					onClick={onCloseMenu}
					aria-label="Fermer"
				/>
			</nav>
		);
	}
}

export default (withRouter(Menu): any);
