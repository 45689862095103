// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_CONTACT_FORM_START,
  FETCH_CONTACT_FORM_SUCCESS,
  FETCH_CONTACT_FORM_FAILURE,
} from 'actions/contactFormActions';
import { type ContactFormType, nullContactForm } from 'types/ContactForm';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  contactForm: ContactFormType[],
  status: Status,
};

const initialState: State = {
  contactForm: [nullContactForm],
  status: STATUS_LOADING,
};

const contactFormReducer = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case FETCH_CONTACT_FORM_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactForm: action.payload.contactForm,
        status: STATUS_SUCCESS,
      };
    case FETCH_CONTACT_FORM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE,
      };
    default:
      return state;
  }
};

export default contactFormReducer;