/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

type DefaultProps = {
	id?: string,
	label?: string,
	isDark?: boolean,
	onChange?: (checked: boolean) => void,
	className?: string,
};

type Props = DefaultProps;

class CheckboxInput extends PureComponent<Props> {
	static defaultProps: DefaultProps = {
		id: 'checkboxinput123',
		isDark: false,
		onChange: () => {},
		className: '',
		label:
			'J’accepte les emails de la Ligue Franche comté de rugby à propos des nouveautés et promotions de la Ligue. ' +
			' Je peux me désinscrire à tout moment en utilisant le lien pour me désabonner au pied de la newsletter.',
	};

	handleCheckboxCliked: (e: any) => void = (e: any) => {
		const { onChange } = this.props;

		if (onChange) onChange(e.target.checked);
	};

	render(): React$Element<'div'> {
		const { isDark, className, id, label } = this.props;
		const classNames = dynamicClassName('checkbox col col-lg-4');
		isDark && classNames.add('is-dark');
		return (
			<div className={className || classNames.build()}>
				<input type="checkbox" id={id} onClick={this.handleCheckboxCliked} />
				<label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
			</div>
		);
	}
}

export default CheckboxInput;
