// @flow
import CookieCutter from 'cookie-cutter';
import React from 'react';

import ReAskMagicLink from 'containers/kits/ReAskMagicLinkContainer';

type Props = {
	content: any,
	hideModal: () => void,
};

function ReAskMagikLinkModalContent({ content, hideModal }: Props): React$Element<'div'> {
	const handleCloseModal = () => {
		//  Deal with cookies on logout
		// remove cookie
		CookieCutter.set('magic', '', { expires: new Date(0) });

		// add cookie for success modal
		const now = new Date();
		const expirationDate = now.setDate(now.getSeconds() + 60 * 5);
		CookieCutter.set('logoutSuccessModalCookie', content.emailUser, { expires: expirationDate });

		// forget magicLink in state --> will trigger redirection
		setTimeout(() => {
			content.forgotMagicLink();
			hideModal();
		}, 1000);
	};

	return (
		<div className="modal-content box newsletter-valid ">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={handleCloseModal}></i>
			<div className="modal__wrapper">
				<h3 className="mb-2">{content ? content.title : ''}</h3>
				<div className="modal__body modal__body--medium narrow-2">
					<ReAskMagicLink />
				</div>
			</div>
		</div>
	);
}

export default ReAskMagikLinkModalContent;
