// https://stackoverflow.com/a/48269720
let Components = {};

Components['CHOOSE_KIT_FORMATS'] =
	require('../../../../containers/fragments/modalContents/ChooseKitFormatsModalContentContainer.js').default;
Components['CREATION_REQUEST'] = require('./CreationRequestModalContent.js').default;
Components['DELETE_ALL_ATTACHMENTS_CONFIRMATION'] =
	require('./DeleteAllAttachmentsConfirmationModalContent.js').default;
Components['DELETE_ATTACHMENT_CONFIRMATION'] = require('./DeleteAttachmentConfirmationModalContent.js').default;
Components['DELETE_KIT'] = require('./DeleteKitModalContent.js').default;
Components['DISPLAY_PREVIEW'] = require('./DisplayPreviewModalContent.js').default;
Components['DOWNLOAD_KIT_SUCCESS'] = require('./DownloadKitSuccessModalContent.js').default;
Components['FAILURE'] = require('./FailureModalContent.js').default;
Components['HANDLE_DASHBOARD_REDIRECTION_FROM_EDITOR'] =
	require('./HandleDashboardRedirectionFromEditorModalContent.js').default;
Components['KIT_CREATION_IN_PROGRESS'] = require('./KitCreationInProgressModalContent.js').default;
Components['LOGOUT_ALERT'] = require('./LogoutAlertModalContent.js').default;
Components['LOGOUT_SUCCESS'] = require('./LogoutSuccessModalContent.js').default;
Components['NEWSLETTER_MISSING_OPT'] = require('./NewsletterMissingOptModalContent.js').default;
Components['RE_ASK_MAGIC_LINK'] = require('./ReAskMagicLinkModalContent.js').default;
Components['REMOVE_KIT_FORMAT'] = require('./RemoveKitFormatModalContent.js').default;
Components['RENAME_MY_KIT'] = require('./RenameMyKitModalContent.js').default;
Components['SCREEN_TOO_SMALL'] = require('./ScreenTooSmallModalContent.js').default;
Components['SUCCESS'] = require('./SuccessModalContent.js').default;

export default Components;
