// @flow

export const ASK_MAGIC_LINK = 'ASK_MAGIC_LINK';
export const ASK_MAGIC_LINK_SUCCESS = 'ASK_MAGIC_LINK_SUCCESS';
export const ASK_MAGIC_LINK_FAILURE = 'ASK_MAGIC_LINK_FAILURE';
export const ADD_ADMIN_DELEGATION = 'ADD_ADMIN_DELEGATION';
export const STOCK_MAGIC_LINK = 'STOCK_MAGIC_LINK';
export const FORGOT_MAGIC_LINK = 'FORGOT_MAGIC_LINK';
export const STOCK_STRUCTURE_ID = 'STOCK_STRUCTURE_ID';
export const GET_MODAL_FOR_NEW_LINK = 'GET_MODAL_FOR_NEW_LINK';

export type ASK_MAGIC_LINK_ACTION = {
	type: 'ASK_MAGIC_LINK',
	payload: {
		email: string,
	},
};

export type ASK_MAGIC_LINK_SUCCESS_ACTION = {
	type: 'ASK_MAGIC_LINK_SUCCESS',
	payload: {
		structureID: number,
		email: string,
	},
};

export type ASK_MAGIC_LINK_FAILURE_ACTION = {
	type: 'ASK_MAGIC_LINK_FAILURE',
};

export type STOCK_MAGIC_LINK_ACTION = {
	type: 'STOCK_MAGIC_LINK',
	payload: {
		magicLink: string,
	},
};

export type STOCK_STRUCTURE_ID_ACTION = {
	type: 'STOCK_STRUCTURE_ID',
	payload: {
		structureID: number,
	},
};

export type FORGOT_MAGIC_LINK_ACTION = {
	type: 'FORGOT_MAGIC_LINK',
};

export type ADD_ADMIN_DELEGATION_ACTION = {
	type: 'ADD_ADMIN_DELEGATION',
	payload: {
		email: string,
	},
};

export type GET_MODAL_FOR_NEW_LINK_ACTION = {
	type: 'GET_MODAL_FOR_NEW_LINK',
};

export type Action =
	| ASK_MAGIC_LINK_ACTION
	| ASK_MAGIC_LINK_SUCCESS_ACTION
	| ASK_MAGIC_LINK_FAILURE_ACTION
	| STOCK_MAGIC_LINK_ACTION
	| FORGOT_MAGIC_LINK_ACTION
	| ADD_ADMIN_DELEGATION_ACTION
	| STOCK_STRUCTURE_ID_ACTION
	| GET_MODAL_FOR_NEW_LINK_ACTION;

export const stockStructureID = (structureID: number): STOCK_STRUCTURE_ID_ACTION => ({
	type: STOCK_STRUCTURE_ID,
	payload: { structureID },
});

export const askMagicLink = (email: string): ASK_MAGIC_LINK_ACTION => ({
	type: ASK_MAGIC_LINK,
	payload: {
		email,
	},
});

export const askMagicLinkSuccess = (structureID: number, email: string): ASK_MAGIC_LINK_SUCCESS_ACTION => ({
	type: ASK_MAGIC_LINK_SUCCESS,
	payload: {
		structureID,
		email,
	},
});

export const askMagicLinkFailure = (): ASK_MAGIC_LINK_FAILURE_ACTION => ({
	type: ASK_MAGIC_LINK_FAILURE,
});

export const stockMagicLink = (magicLink: string): STOCK_MAGIC_LINK_ACTION => ({
	type: STOCK_MAGIC_LINK,
	payload: {
		magicLink,
	},
});

export const forgotMagicLink = (analytics?: boolean): FORGOT_MAGIC_LINK_ACTION => ({
	type: FORGOT_MAGIC_LINK,
});

export const addAdminDelegation = (email: string): ADD_ADMIN_DELEGATION_ACTION => ({
	type: ADD_ADMIN_DELEGATION,
	payload: {
		email,
	},
});

export const getModalForNewLink = (): GET_MODAL_FOR_NEW_LINK_ACTION => ({
	type: GET_MODAL_FOR_NEW_LINK,
});
