// @flow
import type { TagEventType } from 'types/TagManager';

const handleTagEvent = (event: TagEventType) => {
	if (!document || !window || !window.dataLayer) {
		return;
	}

	window.dataLayer.push(event);
};

const TagManagerUtils = {
	handleTagEvent,
};

export default TagManagerUtils;
