// @flow
import React from 'react';

import Button from 'components/fragments/button/Button';

type Props = {
	content: any,
	hideModal(): void,
};

function DeleteAttachmentConfirmationModalContent({ content, hideModal }: Props): React$Element<'div'> {
	const handleYesClick = () => {
		content.removeAttachment();
		hideModal();
	}

	return (
		<div className="modal-content box newsletter-valid ">
			<i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
			<div className="modal__wrapper">
				<div className="modal__body modal__body--medium narrow-2">
					<div className="circle-icon circle-icon--blue"></div>
					<h3 className="mb-1 mt-2 ft-h2">Suppression</h3>
					<p className="mb-1 ft-center">
						{content.text}
					</p>
				</div>
				<div>
					<Button label="Oui" onClick={handleYesClick} className="mb-2 mt-1 mt-md-2" isFull />
					<Button label="Non" onClick={hideModal} className="btn--white btn--full mb-1" isFull isSecondary />
				</div>
			</div>
		</div>
	);
}

export default DeleteAttachmentConfirmationModalContent;
