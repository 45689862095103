/* eslint-disable @next/next/no-img-element */
// @flow
import Link from 'next/link';
import React, { useEffect } from 'react';

import Button from 'components/fragments/button/Button';
import Loading from 'components/fragments/Loading';
import SingleFormatCard from 'components/kits/formatCard/SingleFormatCard';
import { FORMAT_LIST } from 'constants/kitConstants';
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import type { Status } from 'types/Status';

export type DispatchProps = {
	postKitVariation(structureid: number, kitorig: number, change: Object): void,
};

export type StateProps = {
	postKitVariationStatus: Status,
	structureid: number,
};

type Props = DispatchProps &
	StateProps & {
		content: any,
		hideModal: () => void,
	};

function RemoveKitFormat({
	content,
	hideModal,
	postKitVariation,
	postKitVariationStatus,
	structureid,
}: Props): React$Element<'div'> {
	useEffect(() => {
		if (postKitVariationStatus === STATUS_SUCCESS) {
			hideModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postKitVariationStatus]);

	const handleRemoveFormat = (event: Event) => {
		event.preventDefault();

		const nextContentToDisplay = content.kit.formats.find((format) => format !== content.formatName);
		content.handleRedirectionFormat(nextContentToDisplay);

		const kitNewFormats = content.kit.formats.filter((format) => format !== content.formatName);
		const kitNewConfiguration = content.kit.configuration.filter((config) => config.name !== content.formatName);

		content.setCurrentConfiguration(kitNewConfiguration);
		postKitVariation(structureid, content.kit.kitorig, { formats: kitNewFormats });
	};

	const formatName = FORMAT_LIST.find((format) => format.id === content.formatName)?.name ?? '';

	return (
		<div className="modal__wrapper modal__wrapper--delete modal__wrapper--remove-format">
			{content && content.medium && (
				<div className="modal--kit__preview">
					<img src={content.medium} alt={content.title} />
				</div>
			)}

			<div className="modal--kit__content">
				{postKitVariationStatus === STATUS_LOADING ? (
					<Loading
						isWhite
						style={{ width: '380px', height: '236px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					/>
				) : (
					<>
						<div className="modal__body modal__body--medium narrow-2">
							<h3 className="mb-2 mt-2 ft-h2">{`Supprimer le format ${formatName} du kit`}</h3>
							<p className="mb-1">
								Supprimer un format l’effacera définitivement de votre kit, sans possibilité de le retrouver. Êtes-vous
								sûr de vouloir supprimer ce format ?
							</p>
						</div>

						<div>
							<Button
								label="Oui, supprimer le format"
								onClick={handleRemoveFormat}
								className="mb-2 mt-1 mt-md-2 btn--red"
							/>
							<Button label="Non, annuler l'opération" onClick={hideModal} className="mb-2 mt-1 mt-md-2 btn--white" />
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default RemoveKitFormat;
